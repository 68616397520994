import { Box, Tooltip, Typography } from "@mui/material";
import { ModelsCodeCountLookup, ModelsLookUpDictionaries, ModelsLookUpItem, ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts } from "api";
import { UserFollowedItemButton } from "shared/components/UserFollowedItemButton";
import { DataGridStyles } from "shared/utilities/DataGridColumns/DataGridColumns";
import displayConverter from "shared/utilities/displayConverter";
import { RenderMainTitleDisplay } from "./MainTitleDisplayForEntityType";
import { GetLookupItemForCode, RowDisplayForEntityType } from "./RowDisplayForEntityType";

/*


















*/

const RowDisplayTooltipForEntityType = ({
	itemType,
	codeCount,
	lookUpItem,
	lookupDictionaries,
	showEobCounts,
	showCosts,
	useSeparater,
	first,
	last,
}: {
	itemType: CacheMonthlyTypes;
	codeCount?: ModelsCodeCountLookup;
	lookUpItem?: ModelsLookUpItem;
	lookupDictionaries?: ModelsLookUpDictionaries;
	showEobCounts?: boolean;
	showCosts?: boolean;
	useSeparater?: boolean;
	first?: boolean;
	last?: boolean;
}) => {
	if (!codeCount?.code) {
		return <p>[missing]</p>;
	}

	if (!lookUpItem && lookupDictionaries) {
		lookUpItem = GetLookupItemForCode(codeCount.code, itemType, lookupDictionaries);
	}

	let render = null;
	switch (itemType) {
		case "PATIENT":
			break;
		case "DIAGNOSIS":
			render = RenderRowDisplayTooltip.DIAGNOSIS(codeCount, lookUpItem, showEobCounts, showCosts, useSeparater, first, last);
			break;
		case "DIAGNOSIS_RANGE":
			break;
		case "PRODUCTSERVICE":
			render = RenderRowDisplayTooltip.PRODUCTSERVICE(codeCount, lookUpItem, showEobCounts, showCosts, useSeparater, first, last);
			break;
		case "MEDICATION":
			render = RenderRowDisplayTooltip.MEDICATION(codeCount, lookUpItem, showEobCounts, showCosts, useSeparater, first, last);
			break;
		case "PROVIDER":
			render = RenderRowDisplayTooltip.PROVIDER(codeCount, lookUpItem, showEobCounts, showCosts, useSeparater, first, last);
			break;
		case "ORGANIZATION":
			render = RenderRowDisplayTooltip.ORGANIZATION(codeCount, lookUpItem, showEobCounts, showCosts, useSeparater, first, last);
			break;
		default:
			render = (
				<p>
					{codeCount.code ? `[${codeCount.code}]` : ""} {codeCount.name}
				</p>
			);
			break;
	}
	return render;
};

const RenderRowDisplayTooltip = {
	PATIENT: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {},
	DIAGNOSIS: (
		cc: ModelsCodeCountLookup,
		lookUpItem?: ModelsLookUpItem,
		showEobCounts?: boolean,
		showCosts?: boolean,
		useSeparater?: boolean,
		first?: boolean,
		last?: boolean
	) => {
		return (
			<Box key={cc.code}>
				<Tooltip
					arrow
					placement="right"
					title={
						<Box>
							{cc.code ? <UserFollowedItemButton itemType="DIAGNOSIS" code={cc.code} /> : null}
							<Typography>{cc.code}</Typography>
							{showEobCounts ? <Typography>x{cc.count}</Typography> : null}
							<Typography>{cc.description}</Typography>
							{renderCostLines(cc, showCosts)}
						</Box>
					}
				>
					<Box>
						<Typography
							component="div"
							display="block"
							style={useSeparater ? DataGridStyles.wordBreakAndLineSeperator(!!first, !!last) : {}}
							variant={"body2"}
						>
							<RowDisplayForEntityType item={{ code: cc.code ?? undefined, lookUpItem: lookUpItem }} />
						</Typography>
					</Box>
				</Tooltip>
			</Box>
		);
	},
	DIAGNOSIS_RANGE: (item: ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts) => {},
	PRODUCTSERVICE: (
		cc: ModelsCodeCountLookup,
		lookUpItem?: ModelsLookUpItem,
		showEobCounts?: boolean,
		showCosts?: boolean,
		useSeparater?: boolean,
		first?: boolean,
		last?: boolean
	) => {
		return (
			<Box key={cc.code}>
				<Tooltip
					arrow
					placement="right"
					title={
						<Box>
							{cc.code ? <UserFollowedItemButton itemType="PRODUCTSERVICE" code={cc.code} /> : null}
							<Typography>{cc.code}</Typography>
							{showEobCounts ? <Typography>x{cc.count}</Typography> : null}
							<Typography>{cc.description}</Typography>
							{renderCostLines(cc, showCosts)}
						</Box>
					}
				>
					<Box>
						<Typography
							component="div"
							display="block"
							style={useSeparater ? DataGridStyles.wordBreakAndLineSeperator(!!first, !!last) : {}}
							variant={"body2"}
						>
							<RowDisplayForEntityType item={{ code: cc.code ?? undefined, lookUpItem: lookUpItem }} />
						</Typography>
					</Box>
				</Tooltip>
			</Box>
		);
	},
	MEDICATION: (
		cc: ModelsCodeCountLookup,
		lookUpItem?: ModelsLookUpItem,
		showEobCounts?: boolean,
		showCosts?: boolean,
		useSeparater?: boolean,
		first?: boolean,
		last?: boolean
	) => {
		return (
			<Box key={cc.code}>
				<Tooltip
					arrow
					placement="right"
					title={
						<Box>
							{cc.code ? <UserFollowedItemButton itemType="MEDICATION" code={cc.code} /> : null}
							<Typography>{cc.code}</Typography>
							{showEobCounts ? <Typography>x{cc.count}</Typography> : null}
							<Typography>{cc.description}</Typography>
							{renderCostLines(cc, showCosts)}
						</Box>
					}
				>
					<Box style={useSeparater ? DataGridStyles.wordBreakAndLineSeperator(!!first, !!last) : {}}>
						<RowDisplayForEntityType item={{ code: cc.code ?? undefined, lookUpItem: lookUpItem }} />
					</Box>
				</Tooltip>
			</Box>
		);
	},
	PROVIDER: (
		cc: ModelsCodeCountLookup,
		lookUpItem?: ModelsLookUpItem,
		showEobCounts?: boolean,
		showCosts?: boolean,
		useSeparater?: boolean,
		first?: boolean,
		last?: boolean
	) => {
		if (!cc.code) {
			return null;
		}
		return (
			<Box key={cc.code}>
				<Tooltip
					arrow
					placement="right"
					title={
						<Box>
							{cc.code}
							{showEobCounts ? <Typography>x{cc.count}</Typography> : null}
							{lookUpItem ? RenderMainTitleDisplay.PROVIDER(lookUpItem.provider) : null}
							{renderCostLines(cc, showCosts)}
						</Box>
					}
				>
					<Box>
						<Typography
							component="div"
							variant={"body2"}
							display="block"
							style={useSeparater ? DataGridStyles.wordBreakAndLineSeperator(!!first, !!last) : {}}
						>
							<RowDisplayForEntityType noTooltip item={{ code: cc.code ?? undefined, lookUpItem: lookUpItem }} />
						</Typography>
					</Box>
				</Tooltip>
			</Box>
		);
	},
	ORGANIZATION: (
		cc: ModelsCodeCountLookup,
		lookUpItem?: ModelsLookUpItem,
		showEobCounts?: boolean,
		showCosts?: boolean,
		useSeparater?: boolean,
		first?: boolean,
		last?: boolean
	) => {
		if (!cc.code) {
			return null;
		}
		return (
			<Box key={cc.code}>
				<Tooltip
					arrow
					placement="right"
					title={
						<Box>
							<Typography>{cc.code}</Typography>
							{showEobCounts ? <Typography>x{cc.count}</Typography> : null}
							{lookUpItem ? RenderMainTitleDisplay.ORGANIZATION(lookUpItem.organization) : null}
							{renderCostLines(cc, showCosts)}
						</Box>
					}
				>
					<Box>
						<Typography
							component="div"
							variant={"body2"}
							display="block"
							style={useSeparater ? DataGridStyles.wordBreakAndLineSeperator(!!first, !!last) : {}}
						>
							<RowDisplayForEntityType noTooltip item={{ code: cc.code ?? undefined, lookUpItem: lookUpItem }} />
						</Typography>
					</Box>
				</Tooltip>
			</Box>
		);
	},
};

function renderCostLines(cc: ModelsCodeCountLookup, showCosts: boolean = true) {
	return showCosts ? (
		<>
			{typeof cc.cost === "number" ? <Typography>{displayConverter.toUSD(cc.cost)}</Typography> : null}
			{cc.cost && cc.count && cc.count > 1 ? <Typography>{displayConverter.toUSD(cc.cost / cc.count)} (avg)</Typography> : null}
		</>
	) : null;
}

export { RowDisplayTooltipForEntityType };
