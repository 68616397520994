import { Stack, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

type LoaderSizes = "small" | "miniscule" | "large" | "huge";
const Loader = ({ sx, size, title }: { sx?: SxProps; size?: LoaderSizes; title?: ReactNode | string }) => {
	let sxProp = {
		flexBasis: 1,
		flexGrow: 1,
		padding: "2rem",
		"& > img": { width: "6rem", height: "6rem" },
	};

	if (sx) {
		sxProp = Object.assign(sxProp, sx);
	}

	if (size) {
		if (size === "huge") {
			sxProp = Object.assign(sxProp, { "& > img": { width: "8rem", height: "44rem" } });
		} else if (size === "large") {
			sxProp = Object.assign(sxProp, { "& > img": { width: "4rem", height: "12rem" } });
		} else if (size === "small") {
			sxProp = Object.assign(sxProp, { "& > img": { width: "3rem", height: "3rem" } });
		} else if (size === "miniscule") {
			sxProp = Object.assign(sxProp, { padding: "0", height: "1.4rem", "& > img": { width: "1.4rem", height: "1.4rem" } });
		}
	}

	return (
		<Stack data-componentname="Loader" justifyContent={"center"} alignItems={"center"} sx={sxProp}>
			<img alt="radius care logo" src={"/images/radius-loading-indicator.svg"} />
			{title ? <Typography style={{ maxWidth: "24em", padding: "1em" }}>{title}</Typography> : null}
		</Stack>
	);
};

export { Loader };
export type { LoaderSizes };
