import { Route, Routes, Navigate } from "react-router-dom";
import FacilitiesOverview from "./FacilitiesOverview";

function FacilitiesRoutes() {
	return (
		<Routes>
			<Route path={"*"} element={<Navigate to={`overview`} />} />
			<Route path={`overview`} element={<FacilitiesOverview />} />
		</Routes>
	);
}

export default FacilitiesRoutes;
