import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { palette } from "../../theme";
import * as CSS from "csstype";
import { HorizontalAlignmentType, VerticalAlignmentType } from "recharts/types/component/DefaultLegendContent";

type StandardStackedBarChartProps = {
	data?: Array<{
		name: string;
		[key: string]: any;
	}>;
	xKey?: string;
	yKeys: Array<{ key: string; color?: CSS.Property.Color }>;
	legendAlign?: HorizontalAlignmentType;
	legendVerticalAlign?: VerticalAlignmentType;
	layout?: "horizontal" | "vertical";
	showGrid?: boolean;
	responsiveHeight?: number;
	legendStyle?: React.CSSProperties;
};

const colors = [palette.red.main, palette.purple.main, palette.lightBlue.main];

function StandardStackedBarChart(props: StandardStackedBarChartProps) {
	return (
		<ResponsiveContainer height={props.responsiveHeight} width="100%">
			<BarChart data={props.data} layout={props.layout ?? "horizontal"}>
				{props.showGrid !== false ? <CartesianGrid strokeDasharray="3 3" /> : null}
				{props.layout === "vertical" ? (
					<>
						<XAxis type="number" />
						<YAxis type="category" dataKey={props.xKey ?? "name"} width={200} />
					</>
				) : (
					<>
						<XAxis dataKey={props.xKey ?? "name"} />
						<YAxis />
					</>
				)}

				<Tooltip />
				<Legend verticalAlign={props.legendVerticalAlign} align={props.legendAlign} iconType="circle" iconSize={24} wrapperStyle={props.legendStyle} />
				{props.yKeys.map((yk, x) => {
					return <Bar key={x} dataKey={yk.key} stackId="a" fill={yk.color ?? colors.length > x ? colors[x] : palette.primary.dark} />;
				})}
			</BarChart>
		</ResponsiveContainer>
	);
}

export default StandardStackedBarChart;
