import { Grid } from "@mui/material";
import { DataGridPro, GridCellParams, GridColumns, GridRenderCellParams, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import clsx from "clsx";
import displayConverter from "shared/utilities/displayConverter";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";

interface Props {}

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Sample Bill Rates"} {...props} />;
}

const columns: GridColumns = [
	{
		field: "typeService",
		headerName: "Type Service",
		flex: 3,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "billingCode",
		headerName: "Billing Code",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "amountAllowed",
		headerName: "Amount Allowed",
		flex: 1,
		headerAlign: "right",
		align: "right",
		valueFormatter: toUSDFormatter,
	},
	{
		field: "competitorBills",
		headerName: "Competitor Bills",
		flex: 1,
		headerAlign: "right",
		align: "right",
		valueFormatter: toUSDFormatter,
	},
	{
		field: "yourBilling",
		headerName: "Your Billing",
		flex: 1,
		headerAlign: "right",
		align: "right",
		valueFormatter: toUSDFormatter,
	},
	{
		field: "gainLoss",
		headerName: "Gain/Loss",
		flex: 1,
		headerAlign: "right",
		align: "right",
		cellClassName: (params: GridCellParams<number>) =>
			clsx("super-app", {
				negative: params.value !== undefined ? params.value < 0 : false,
				positive: params.value !== undefined ? params.value > 0 : false,
			}),
		renderCell: (params: GridRenderCellParams<number>) => (
			<strong>({displayConverter.toUSD(Math.abs(params.value !== undefined ? params.value : 0))})</strong>
		),
	},
];

function toUSDFormatter(params: GridValueFormatterParams) {
	return displayConverter.toUSD(params.value as number);
}

type Referral = {
	id: number;
	typeService: string;
	billingCode: number;
	amountAllowed: number;
	competitorBills: number;
	yourBilling: number;
	gainLoss: number;
};

const referraldata: Array<Referral> = [
	{
		id: 1,
		typeService: "Pathology Examination of Tissue using microscope",
		billingCode: 41244,
		amountAllowed: 22.98,
		competitorBills: 14,
		yourBilling: 14,
		gainLoss: 12,
	},
	{
		id: 2,
		typeService: "Leuprolide Acetate",
		billingCode: 41244,
		amountAllowed: 22.98,
		competitorBills: 14,
		yourBilling: 14,
		gainLoss: 12,
	},
	{
		id: 3,
		typeService: "Biopsy of Single Growth of skin/or tissue",
		billingCode: 41244,
		amountAllowed: 22.98,
		competitorBills: 14,
		yourBilling: 14,
		gainLoss: -12,
	},
	{
		id: 4,
		typeService: "Repair of Wound of Scalp, neck, arms",
		billingCode: 41244,
		amountAllowed: 22.98,
		competitorBills: 14,
		yourBilling: 14,
		gainLoss: 12,
	},
	{
		id: 5,
		typeService: "Patient Office or Other Outpatient Visit",
		billingCode: 41244,
		amountAllowed: 22.98,
		competitorBills: 14,
		yourBilling: 14,
		gainLoss: -12,
	},
];

interface Filters {
	searchQuery?: string;
}

function PanelDoctorCompLandscape(props: Props) {
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("users", columns);
	function onSearchEntered(searchString: string) {
		if (query.searchQuery === searchString) {
			return null;
		}
		updateSearchQuery({ searchQuery: searchString });
	}
	return (
		<Box
			sx={{
				width: "100%",
				"& .super-app.negative": {
					color: "rgba(245, 12, 12, 0.767)",
					fontWeight: "600",
				},
				"& .super-app.positive": {
					color: "#21c41c",
					fontWeight: "600",
				},
			}}
		>
			<Grid item xs={12}>
				<Box sx={{ display: "flex", height: "100%" }}>
					<Box
						sx={{
							flexGrow: 1,
						}}
					>
						<DataGridPro
							{...grid}
							columns={columns}
							autoHeight
							rows={referraldata}
							components={{ Toolbar: Toolbar }}
							componentsProps={{
								toolbar: {
									onSearch: (searchString: string) => {
										onSearchEntered(searchString);
									},
									initialSearch: query.searchQuery ?? "",
								},
							}}
						/>
					</Box>
				</Box>
			</Grid>
		</Box>
	);
}

export default PanelDoctorCompLandscape;
