import { Box, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import displayConverter from "../../../../shared/utilities/displayConverter";
import ValueTile from "../../../../shared/components/ValueTile";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";
import { ModelsCodeCountLookup, ModelsMedicationRangeCacheMonthlyAggregate, ModelsPatientRiskCategory, RequestModelsCacheMonthlyQueryRequest } from "api";
import { useMemo } from "react";

import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { DateConverter } from "shared/utilities/DateConverter";
import { MedicationTypeApiRequest } from "../MedicationDetailsMain";
import { useX_getCacheMonthlyDetailCombined } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyDetailCombined";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { UserFollowedItemButton } from "shared/components/UserFollowedItemButton";
import { Loader } from "shared/components/Loaders/Loader";
import { ViewEntityDetailButton } from "shared/components/ViewEntityDetailButton";

SwiperCore.use([Pagination]);

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Members List"} {...props} enableExport={false} />;
}

/*

















*/
type MedicationDetailsMembersProps = {
	apiRequest: MedicationTypeApiRequest;
	rangeAggregateItem?: ModelsMedicationRangeCacheMonthlyAggregate;
	totalMembers?: number;
};
function MedicationDetailsMembers(props: MedicationDetailsMembersProps) {
	const columns: GridColumns = [
		{
			field: "code",
			headerName: "Member ID",
			flex: 1,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (params) => {
				return params.row.code ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Box>
							<UserFollowedItemButton itemType="PATIENT" code={params.row.code + "||" + params.row.jobId} />
						</Box>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<Typography>{params.row.code}</Typography>
							</Box>
						</Box>
					</Box>
				) : (
					params.row.npi
				);
			},
		},
		{
			field: "gender",
			headerName: "Gender",
			headerAlign: "left",
			align: "left",
			flex: 0.25,
			sortable: false,
		},
		{
			field: "birthDate",
			headerName: "Age",
			flex: 0.25,
			headerAlign: "left",
			align: "left",
			valueFormatter: (params) => {
				return DateConverter.ageFromApiStringDate((params.value ?? null) as string | null);
			},
			sortable: true,
		},
		{
			field: "jobId",
			headerName: "Provider",
			flex: 0.5,
			headerAlign: "left",
			align: "left",
			sortable: false,
		},
		{
			field: "cost",
			headerName: "Total Spend",
			headerAlign: "left",
			align: "left",
			flex: 0.5,
			renderCell: (params) => {
				return displayConverter.toUSD(params.value);
			},
			sortable: true,
		},
		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			width: 128,
			sortable: false,
			disableColumnMenu: true,

			renderCell: ({ row }) => {
				return <ViewEntityDetailButton itemType="PATIENT" code={row.patId} />;
			},
		},
	];
	const { apiRequest, rangeAggregateItem, totalMembers } = props;
	const { grid } = useDataGrid("patient", columns);

	const swiper = useMemo(() => {
		return rangeAggregateItem && totalMembers != null ? RenderSwiper(rangeAggregateItem, totalMembers) : null;
	}, [rangeAggregateItem, totalMembers]);
	const { values: pfValues } = usePrimaryFilters();
	const queryRequest: RequestModelsCacheMonthlyQueryRequest | null = useMemo(() => {
		return {
			jobIds: [...pfValues.Members],
			codes: apiRequest.codes,
			itemType: "MEDICATION",
			includeLookup: true,
			paginationRequest: { skip: 0, take: 1000, maxNestedSize: 100 },
		};
	}, [apiRequest.codes, pfValues.Members]);
	const apiResult = useX_getCacheMonthlyDetailCombined(queryRequest);
	const data = useMemo(() => {
		if (!apiResult.data?.response?.bucketGroups) return null;
		return apiResult.data?.response?.bucketGroups[apiRequest.codes[0]][0];
	}, [apiRequest.codes, apiResult.data?.response?.bucketGroups]);

	const membersWithInfo = useMemo(() => {
		let membersWithInfo: (ModelsCodeCountLookup & ModelsPatientRiskCategory)[] = [];
		const dictionary = apiResult?.data?.response?.lookupDictionaries?.patientRiskCategory?.itemDictionary;
		data?.patient?.codeCounts?.forEach((x) => {
			if (dictionary && x.code && dictionary[x.code]) {
				const test = dictionary[x.code];
				membersWithInfo.push({ ...x, ...test });
			}
			return null;
		});
		return membersWithInfo;
	}, [apiResult?.data?.response?.lookupDictionaries?.patientRiskCategory?.itemDictionary, data?.patient?.codeCounts]);

	return (
		<Box>
			{swiper}
			<QueryStatusDisplay queryResult={apiResult} showAsLoading={!data}>
				{data ? (
					<Box sx={{ display: "flex" }}>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							<DataGridPro
								{...grid}
								initialState={{
									sorting: {
										sortModel: [{ field: "cost", sort: "desc" }],
									},
								}}
								disableColumnFilter
								disableColumnMenu
								getRowId={(row) => row.code}
								components={{ Toolbar: Toolbar }}
								columns={columns}
								autoHeight
								rows={membersWithInfo}
								rowCount={membersWithInfo.length}
								sx={{ pt: 2 }}
								pagination
								paginationMode="client"
								rowsPerPageOptions={[10]}
							/>
						</Box>
					</Box>
				) : (
					<Loader />
				)}
			</QueryStatusDisplay>
		</Box>
	);
}

export { MedicationDetailsMembers };

function RenderSwiper(rangeAggregateItem: ModelsMedicationRangeCacheMonthlyAggregate, totalMembers: number) {
	return (
		<Box
			sx={{
				"& .swiper-container": {
					pb: { xs: 4, md: 0 },
				},
				"& .swiper-pagination-bullets": {
					bottom: "0",
				},
			}}
		>
			<Swiper
				spaceBetween={16}
				slidesPerView={1}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					"700": {
						slidesPerView: 3,
					},
					"1200": {
						slidesPerView: 4,
					},
				}}
			>
				<SwiperSlide>
					<ValueTile value={displayConverter.insertThousandsCommas(rangeAggregateItem.uniquePatientCount)} title="Members" minHeight="7rem" />
				</SwiperSlide>
				<SwiperSlide>
					<ValueTile value={displayConverter.insertThousandsCommas(rangeAggregateItem.occurrenceCount)} title="Fill Count" minHeight="7rem" />
				</SwiperSlide>
				<SwiperSlide>
					<ValueTile value={displayConverter.toUSD(rangeAggregateItem.totalAmount)} title="Total Spend" minHeight="7rem" />
				</SwiperSlide>
				<SwiperSlide>
					<ValueTile
						value={displayConverter.toUSD(rangeAggregateItem.totalAmount / rangeAggregateItem.occurrenceCount)}
						title="Average Spend"
						minHeight="7rem"
					/>
				</SwiperSlide>
			</Swiper>
		</Box>
	);
}
