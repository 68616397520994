import { Box } from "@mui/material";
import { DataGridPro, GridEnrichedColDef, GridSortItem, GridSortModel } from "@mui/x-data-grid-pro";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { ModelsPaginationAndSortRequest, ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts, RequestModelsCacheMonthlyQueryRequest } from "api";
import { useX_getCacheMonthlyAggregation } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyAggregation";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { DataGridColumns } from "shared/utilities/DataGridColumns/DataGridColumns";
import { ReducerUtility } from "shared/utilities/ReducerUtility";
import { useQueryClient } from "react-query";
import { SortBarSelect } from "shared/components/SortBarSelect";

function CacheMonthlyListDisplay({
	itemType,
	titleText,
	queryRequest,
	columns,
	useSortBarSelect,
	searchBar,
	query,
}: {
	itemType: UserFollowedItemTypeNames;
	titleText?: string;
	queryRequest?: Partial<RequestModelsCacheMonthlyQueryRequest>;
	columns?: GridEnrichedColDef[];
	useSortBarSelect?: boolean;
	searchBar?: React.ReactNode;
	query?: string | null;
}) {
	const toolbar = useCallback(
		(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) => {
			return <DataGridToolbar header={titleText} {...props} enableExport={false} />;
		},
		[titleText]
	);
	const queryClient = useQueryClient();
	const { values: pfValues } = usePrimaryFilters();
	/*




*/
	//
	const pageSize = 10;
	const [page, set_page] = useState(0);

	const [cumulativeRows, set_cumulativeRows] = useState<Array<ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts> | null>(null);
	const [totalCount, set_totalCount] = useState(0);

	const [sortItem, set_sortItem] = useState<GridSortItem>({ field: "total_amount", sort: "desc" });
	///
	//
	const _columns = useMemo(() => {
		if (columns) {
			return columns;
		}
		return DataGridColumns.CacheMonthly(itemType);
	}, [itemType, columns]);

	const [paginationRequest, dispatch_paginationRequest] = useReducer(
		(x: ModelsPaginationAndSortRequest, action: Partial<ModelsPaginationAndSortRequest>) => {
			return ReducerUtility.UpdatePaginationRequestWithPartialValue(x, action);
		},
		{
			field: "total_amount",
			descending: true,
			skip: 0,
			take: 40,
		}
	);

	useEffect(() => {
		dispatch_paginationRequest({ skip: 0 });
		set_page(0);
	}, [paginationRequest.field, paginationRequest.descending, pfValues]);
	const queryRequestX: RequestModelsCacheMonthlyQueryRequest = useMemo(() => {
		return Object.assign(
			{
				jobIds: [...pfValues.Members],
				codes: query ? [query] : undefined,
				itemType: itemType,
				paginationRequest: paginationRequest,
				includeLookup: true,
				includeMissingItemsLookup: true,
			},
			queryRequest
		);
	}, [pfValues.Members, itemType, paginationRequest, queryRequest, query]);

	const apiResult = useX_getCacheMonthlyAggregation(queryRequestX, false);

	useEffect(() => {
		if (apiResult.data?.response?.buckets) {
			if (page > 0) {
				set_cumulativeRows((cumulativeRows ?? []).concat(apiResult.data.response.buckets));
			} else {
				set_cumulativeRows(apiResult.data.response.buckets);
			}
			set_totalCount(apiResult?.data?.response?.totalItems ?? 0);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiResult.data?.response?.buckets]);

	// generate sortOptions from the columns
	const sortOptions = useMemo(() => {
		return _columns
			.filter((c) => !!c.sortable && c.headerName && c.field)
			.map((c) => {
				return { label: c.headerName as string, value: c.field };
			});
	}, [_columns]);

	/*
	
	
	

	
	*/

	return (
		<Box style={{ paddingTop: useSortBarSelect ? "2rem" : "0px" }}>
			<button
				style={{ display: "none" }}
				onClick={() => {
					queryClient.invalidateQueries(["getCacheMonthlyAggregation"]);
				}}
			>
				Re--Send
			</button>
			<Box display={"flex"} justifyContent={"space-between"}>
				{searchBar !== undefined && searchBar}
				{useSortBarSelect ? (
					<SortBarSelect
						sort={sortItem.field}
						descending={paginationRequest.descending ?? false}
						set_sort={(field: string) => {
							dispatch_paginationRequest({ field: field });
							set_sortItem({ field: field, sort: sortItem.sort });
						}}
						sortOptions={sortOptions}
						set_descending={(desc: boolean) => {
							dispatch_paginationRequest({ descending: desc });
							set_sortItem({ field: sortItem.field, sort: desc ? "desc" : "asc" });
						}}
					/>
				) : null}
			</Box>

			<QueryStatusDisplay queryResult={apiResult} loaderSize="huge">
				{cumulativeRows ? (
					<DataGridPro
						columns={_columns}
						autoHeight
						rowHeight={80}
						getRowId={(row) => row.code}
						rows={cumulativeRows}
						pagination
						pageSize={pageSize}
						rowsPerPageOptions={[10]}
						sx={{ pt: 2 }}
						disableColumnFilter
						disableColumnMenu
						sortingMode="server"
						sortModel={[sortItem]}
						rowCount={totalCount}
						onSortModelChange={(sm: GridSortModel) => {
							if (sm.length > 0) {
								if (sm[0].field !== paginationRequest.field) {
									// on the first click of a field use descending
									sm[0].sort = "desc";
								}
								let descending = sm[0].sort === "desc";
								dispatch_paginationRequest({ field: sm[0].field, descending: descending });
								set_sortItem(sm[0]);
							} else {
								// every third click calls with an empty sm array. It's to 'reset' the filters
								let descending = !paginationRequest.descending;
								dispatch_paginationRequest({ descending: descending });
								set_sortItem({ field: sortItem.field, sort: descending ? "desc" : "asc" });
							}
						}}
						components={{ Toolbar: toolbar }}
						page={page}
						onPageChange={(newPage) => {
							let maxDisplayedRow: number = (newPage + 1) * pageSize;
							set_page(newPage);

							console.log({ newPage, maxDisplayedRow, totalRows: cumulativeRows.length });
							if (maxDisplayedRow > cumulativeRows.length && cumulativeRows.length < totalCount) {
								dispatch_paginationRequest({ skip: cumulativeRows.length });
							}
						}}
					/>
				) : (
					<p>No Data To Display</p>
				)}
			</QueryStatusDisplay>
		</Box>
	);
}

export { CacheMonthlyListDisplay };
