import { Box } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import displayConverter from "../../../../shared/utilities/displayConverter";
import ValueTile from "../../../../shared/components/ValueTile";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { getConditionDetailsServices } from "api/conditions/conditions";
import { AdminGetConditionDetailsServicesResult } from "api";
import { useRunFilterQuery } from "../../../../DataProvider/hooks/useRunFilterQuery";
import { Loader } from "shared/components/Loaders/Loader";

SwiperCore.use([Pagination]);

const columns: GridColumns = [
	{
		field: "patientId",
		headerName: "Patient ID",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},

	{
		field: "age",
		headerName: "Age",
		flex: 0.25,
		headerAlign: "left",
		align: "left",
	},

	{
		field: "doctorName",
		headerName: "Doctor Name",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "serviceType",
		headerName: "Service Type",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "date",
		headerName: "Date",
		flex: 0.75,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "totalBilling",
		headerName: "Total Billing",
		flex: 1,
		headerAlign: "right",
		align: "right",
		valueGetter: (params) => displayConverter.toUSD(params.row.totalBilling),
	},
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Services List"} {...props} />;
}

interface Filters {
	tableQuery?: string;
}

type DiagnosisDetailsServicesProps = {
	diagnosisID: string;
	icd10Query: string;
	subRangeQuery: string;
};
function DiagnosisDetailsServices(props: DiagnosisDetailsServicesProps) {
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("services", columns);

	const { data } = useRunFilterQuery<AdminGetConditionDetailsServicesResult>(getConditionDetailsServices, {
		...query,
		searchQuery: props.diagnosisID,
		icd10Query: props.icd10Query,
		subRangeQuery: props.subRangeQuery,
	});

	function onSearchEntered(searchString: string) {
		if (query.tableQuery === searchString) {
			return null;
		}
		updateSearchQuery({ tableQuery: searchString });
	}

	return (
		<Box>
			{data ? (
				<>
					<Box
						sx={{
							"& .swiper-container": {
								pb: { xs: 4, md: 0 },
							},
							"& .swiper-pagination-bullets": {
								bottom: "0",
							},
						}}
					>
						<Swiper
							spaceBetween={16}
							slidesPerView={1}
							// onSlideChange={() => console.log("slide change")}
							// onSwiper={(swiper) => console.log(swiper)}
							pagination={{
								clickable: true,
							}}
							breakpoints={{
								"700": {
									slidesPerView: 3,
								},
								"1200": {
									slidesPerView: 3,
								},
							}}
						>
							{/* <SwiperSlide>
								<ValueTile
									value={displayConverter.insertThousandsCommas(data?.conditions?.appointments)}
									title="Total Services"
									minHeight="7rem"
								/>
							</SwiperSlide> */}
							<SwiperSlide>
								<ValueTile
									value={displayConverter.insertThousandsCommas(data?.conditions?.prescriptions)}
									title="Prescriptions"
									minHeight="7rem"
								/>
							</SwiperSlide>
							<SwiperSlide>
								<ValueTile value={displayConverter.insertThousandsCommas(data?.conditions?.procedures)} title="Procedures" minHeight="7rem" />
							</SwiperSlide>
							<SwiperSlide>
								<ValueTile value={displayConverter.insertThousandsCommas(data?.conditions?.referrals)} title="Referrals" minHeight="7rem" />
							</SwiperSlide>
						</Swiper>
					</Box>
					<Box sx={{ display: "flex", height: "100%" }}>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							{data ? (
								<DataGridPro
									{...grid}
									columns={columns}
									autoHeight
									getRowId={(row) => row.recordId}
									rowCount={data.conditions?.totalServices}
									rows={data.conditions?.services!}
									components={{ Toolbar: Toolbar }}
									componentsProps={{
										toolbar: {
											onSearch: (searchString: string) => {
												if (searchString) {
													onSearchEntered(searchString);
												}
											},
											initialSearch: query.tableQuery ?? "",
										},
									}}
									sx={{ pt: 2 }}
								/>
							) : null}
						</Box>
					</Box>
				</>
			) : (
				<Loader />
			)}
		</Box>
	);
}

export { DiagnosisDetailsServices };
