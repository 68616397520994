import { Box, Grid, Card, CardContent, CardHeader } from "@mui/material";
import DashboardAnalytics from "./DashboardAnalytics";
import { MembersRiskModel } from "./MemberHealthRiskModel/MembersRiskModel";
import TopSpendCard from "./TopSpendCard";

function Overview() {
	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader title="Summary" />
						<CardContent sx={{ pt: 0 }}>
							<DashboardAnalytics />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} xl={6}>
					<Card elevation={4}>
						<CardHeader title="Members Health" />
						<CardContent>
							<MembersRiskModel />
							{/* <MembersRiskModelCached />  Uses the overview table */}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} xl={6}>
					<TopSpendCard />
				</Grid>
			</Grid>
		</Box>
	);
}

export { Overview };
