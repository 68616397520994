import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, Stack, Card, CardContent, CardHeader, Tabs } from "@mui/material";
import { icons } from "../../../theme";
import Icon from "../../../shared/Icon";
import { useParams } from "react-router-dom";
import { Condition } from "../../../shared/schemas/dataStructures";
import { DiagnosisDetailsOverview } from "./panels/DiagnosisDetailsOverview";
import { DiagnosisDetailsDoctors } from "./panels/DiagnosisDetailsDoctors";
import DiagnosisNotificationList from "../../../notifications/DiagnosisNotificationList";
import { ICD10SubSelect } from "../components/ICD10SubSelect";
import { ConditionSelectSingle } from "../components/ConditionSelectSingle";
import { useDiagnosisFilters } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";
import { DiagnosisDetailsMembers } from "./panels/DiagnosisDetailsMembers";
import { useFilterQuery } from "../../../DataProvider/hooks/useRunFilterQuery";
import { SubRangeSelect } from "../components/SubRangeSelect";
import { TabsUtilities } from "shared/components/Tabs/TabsUtilities";
import { DateConverter } from "shared/utilities/DateConverter";
import { useX_getConditionDetails } from "api_extension/useQueryX/cacheDiagnosisData/useX_getConditionDetails";
import { useX_getDiagnosisRangeAggregations } from "api_extension/useQueryX/cacheDiagnosisData/useX_getDiagnosisRangeAggregations";
import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { useMemo_totalMembersInFilter } from "shared/filters/useMemo_TotalMembersInFilter";
import { ErrorDisplay } from "shared/components/DisplayElements/ErrorDisplay";
import { useContextCodeDefinitions } from "DataProvider/contexts/Context_CodeDefinitions_Provider";
import { DiagnosisDetailsMedications } from "./panels/DiagnosisDetailsMedications";
import { DiagnosisDetailsProductServices } from "./panels/DiagnosisDetailsProcedures";
import { DiagnosisDetailsImpact } from "./panels/DiagnosisDetailsImpact";
import { UserFollowedItemButton } from "shared/components/UserFollowedItemButton";
import { useX_getDiagnosis_GenderAndAge } from "api_extension/useQueryX/cacheDiagnosisData/useX_getDiagnosis_GenderAndAge";
import { Loader } from "shared/components/Loaders/Loader";
import { DiagnosisDetailsFacilities } from "./panels/DiagnosisDetailsFacilities";

interface DiagnosisTypeApiRequest extends DateRangeStrings {
	searchQuery: string | undefined;
	icd10Query: string;
	subRangeQuery: string;
}

interface DiagnosisRangeApiRequest extends DateRangeStrings {
	codes: Array<string> | null;
	ranges: Array<string>;
	jobIds: Array<string>;
}

const { TabPanel, TabControl } = TabsUtilities.create_TabRenderFunctions("diagnosis-details");

function DiagnosisDetailsMain() {
	const tabs = ["Overview", "Members", "Doctors", "Facilities", "Prescriptions", "Procedures", "Radius Impact"];

	const { values: primaryFilterValues } = useContext(PrimaryFiltersContext);

	const { Conditions } = useContextCodeDefinitions();
	const diagnosisFilters = useDiagnosisFilters();

	let params = useParams() as { diagnosisId: string };
	const diagnosisID: string | number = params.hasOwnProperty("diagnosisId") ? params["diagnosisId"] : 0;
	const [conditionValue, setConditionValue] = useState<Condition>();

	// If no value selected, take Condition from URL and
	// select the value in the condition select.
	useEffect(() => {
		if (Conditions?.data) {
			Conditions?.data.every((element) => {
				if (params.diagnosisId === element.id) {
					let c: Condition = {
						id: element.id,
						name: element.name,
						icd10_codes: (element as any).subRanges,
					};
					diagnosisFilters.setValues({ Conditions: [c] });
					setConditionValue(c);
					return false;
				} else {
					return true;
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/*
	REQUEST
	*/

	const [ranges, codes] = useMemo(() => {
		const r: Array<string> = [];
		if (diagnosisFilters.values.SubRangeByCondition) {
			Object.keys(diagnosisFilters.values.SubRangeByCondition).forEach((key) => {
				let subRange = diagnosisFilters.values.SubRangeByCondition[key];
				if (subRange?.range) {
					r.push(subRange.range);
				}
			});
		}
		if (r.length === 0) {
			r.push(String(diagnosisID));
		}
		let c: Array<string> | null = null;
		if (diagnosisFilters.values.ICD10CodesByCondition) {
			let c_: Array<string> = [];
			Object.keys(diagnosisFilters.values.ICD10CodesByCondition).forEach((key) => {
				if (conditionValue?.id === key) {
					if (diagnosisFilters.values.ICD10CodesByCondition[key].code) {
						c_.push(diagnosisFilters.values.ICD10CodesByCondition[key].code as string);
					}
				}
			});
			if (c_.length > 0) {
				c = c_;
			}
		}

		return [r, c];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [diagnosisFilters.values.SubRangeByCondition, diagnosisFilters.values.ICD10CodesByCondition, conditionValue?.id, diagnosisID]);

	const genderAndAgeResponse = useX_getDiagnosis_GenderAndAge(codes ?? undefined, ranges ?? undefined).data?.response;

	const diagnosisRangeRequest: DiagnosisRangeApiRequest = useMemo(() => {
		return {
			startDate: DateConverter.stringDateForAPI_Nullable(primaryFilterValues.DateRangeCustomDates.start),
			endDate: DateConverter.stringDateForAPI_Nullable(primaryFilterValues.DateRangeCustomDates.end),
			ranges: ranges,
			codes: codes,
			jobIds: primaryFilterValues.Members,
		};
	}, [primaryFilterValues.DateRangeCustomDates.start, primaryFilterValues.DateRangeCustomDates.end, primaryFilterValues.Members, ranges, codes]);

	const rangeAggregationResponse = useX_getDiagnosisRangeAggregations(diagnosisRangeRequest.codes, diagnosisRangeRequest.ranges, diagnosisRangeRequest);
	const rangeAggregateItem = rangeAggregationResponse?.data?.response?.aggregateItems?.[0];
	const totalMembers = useMemo_totalMembersInFilter();

	const [activeTab, set_activeTab] = React.useState<number>(0);

	const icd10Codes = diagnosisFilters.values.ICD10CodesByCondition[diagnosisFilters.values.Conditions[0]?.id]?.code ?? "";
	const subRanges = diagnosisFilters.values.SubRangeByCondition[diagnosisFilters.values.Conditions[0]?.id]?.range ?? "";

	const filters = useFilterQuery();
	const apiRequest: DiagnosisTypeApiRequest = useMemo(() => {
		return {
			...filters,
			searchQuery: conditionValue?.id,
			icd10Query: icd10Codes,
			subRangeQuery: subRanges,
			startDate: DateConverter.stringDateForAPI_Nullable(primaryFilterValues.DateRangeCustomDates.start),
			endDate: DateConverter.stringDateForAPI_Nullable(primaryFilterValues.DateRangeCustomDates.end),
		};
	}, [filters, conditionValue?.id, icd10Codes, subRanges, primaryFilterValues.DateRangeCustomDates.start, primaryFilterValues.DateRangeCustomDates.end]);

	const { data } = useX_getConditionDetails(apiRequest);
	let [followItemCode, setFollowItemCode] = useState<string | undefined>();
	let followedItemType: UserFollowedItemTypeNames = useMemo(() => {
		if (codes && codes.length > 0) {
			setFollowItemCode(codes[0]);
			return "DIAGNOSIS";
		} else {
			setFollowItemCode(ranges[0]);
			return "DIAGNOSIS_RANGE";
		}
	}, [codes, ranges]);
	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardContent>
							<Stack direction="row" flexWrap="wrap">
								<Box sx={{ display: "flex", alignItems: "center" }}>
									{followItemCode && <UserFollowedItemButton itemType={followedItemType} code={followItemCode} />}
								</Box>
								<ConditionSelectSingle
									styles={{
										mr: { xs: 0, md: 0.5 },
										width: { xs: "100%", md: "14rem" },
										pb: { xs: 2, md: 0 },
									}}
									condition={conditionValue}
									icon={<Icon icon={icons.diagnosis} sx={{ color: "primary.main", pl: 1 }}></Icon>}
									placeholder={"Filter by Condition"}
									setValue={(val: any) => {
										setConditionValue(val);
									}}
								/>
								{data?.conditions?.conditionModel?.subRanges ? (
									<SubRangeSelect
										styles={{
											ml: { xs: 0, md: 0.5 },
											width: { xs: "100%", md: "14rem" },
											pb: { xs: 2, md: 0 },
										}}
										condition={conditionValue}
										subRanges={data?.conditions?.conditionModel?.subRanges}
										icon={<Icon icon={icons.diagnosis} sx={{ color: "primary.main", pl: 1 }}></Icon>}
									/>
								) : (
									<Box sx={{ flexGrow: 1, alignSelf: "center" }}>
										<Loader size={"miniscule"} />
									</Box>
								)}
								{data?.conditions?.conditionModel?.icD10Codes ? (
									<ICD10SubSelect
										styles={{
											ml: { xs: 0, md: 0.5 },
											width: { xs: "100%", md: "14rem" },
											pb: { xs: 2, md: 0 },
										}}
										condition={conditionValue}
										icd10_codes={data?.conditions?.conditionModel?.icD10Codes}
										icon={<Icon icon={icons.diagnosis} sx={{ color: "primary.main", pl: 1 }}></Icon>}
									/>
								) : (
									<Box sx={{ flexGrow: 1, alignSelf: "center" }}>
										<Loader size={"miniscule"} />
									</Box>
								)}
							</Stack>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} lg={9}>
					<Card elevation={4}>
						{!rangeAggregateItem && !rangeAggregationResponse.isLoading ? (
							<ErrorDisplay errorType={"NEUTRAL"} errorMessage="No Results" styling={{ margin: "0.5rem" }} />
						) : (
							<>
								<CardHeader
									sx={{
										"& .MuiCardHeader-action": {
											width: "100%",
										},
									}}
									action={
										<Tabs
											value={activeTab}
											onChange={(event: React.SyntheticEvent, newValue: number) => {
												set_activeTab(newValue);
											}}
											aria-label="Diagnosis Details Tabs"
											variant="scrollable"
											scrollButtons={false}
											sx={{
												width: "100%",
												"& .MuiTab-root": {
													flex: { xs: "none", md: "1" },
													whiteSpace: "nowrap",
												},
											}}
										>
											{tabs.map((label, value) => {
												return <TabControl key={label + value} label={label} value={value} />;
											})}
										</Tabs>
									}
								></CardHeader>
								<CardContent
									sx={{
										pt: 0,
									}}
								>
									<TabPanel value={activeTab} index={0}>
										<DiagnosisDetailsOverview
											api_data={data}
											diagnosisID={conditionValue?.id!}
											rangeAggregateItem={rangeAggregateItem}
											totalMembers={totalMembers}
											diagnosisRangeApiRequest={diagnosisRangeRequest}
											genderAndAge={genderAndAgeResponse}
										/>
									</TabPanel>
									<TabPanel value={activeTab} index={1}>
										<DiagnosisDetailsMembers
											apiRequest={apiRequest}
											api_data={data}
											rangeAggregateItem={rangeAggregateItem}
											totalMembers={totalMembers}
										/>
									</TabPanel>
									<TabPanel value={activeTab} index={2}>
										<DiagnosisDetailsDoctors
											apiRequest={apiRequest}
											diagnosisID={conditionValue?.id!}
											icd10Query={icd10Codes}
											subRangeQuery={subRanges}
											rangeAggregateItem={rangeAggregateItem}
										/>
									</TabPanel>
									<TabPanel value={activeTab} index={3}>
										<DiagnosisDetailsFacilities
											apiRequest={apiRequest}
											diagnosisID={conditionValue?.id!}
											icd10Query={icd10Codes}
											subRangeQuery={subRanges}
											rangeAggregateItem={rangeAggregateItem}
										/>
									</TabPanel>
									<TabPanel value={activeTab} index={4}>
										<DiagnosisDetailsMedications
											apiRequest={apiRequest}
											diagnosisID={conditionValue?.id!}
											icd10Query={icd10Codes}
											subRangeQuery={subRanges}
											rangeAggregateItem={rangeAggregateItem}
										/>
									</TabPanel>
									<TabPanel value={activeTab} index={5}>
										<DiagnosisDetailsProductServices
											apiRequest={apiRequest}
											diagnosisID={conditionValue?.id!}
											icd10Query={icd10Codes}
											subRangeQuery={subRanges}
											rangeAggregateItem={rangeAggregateItem}
										/>
									</TabPanel>
									<TabPanel value={activeTab} index={6}>
										<DiagnosisDetailsImpact apiRequest={apiRequest} rangeAggregateItem={rangeAggregateItem} />
									</TabPanel>
								</CardContent>
							</>
						)}
					</Card>
				</Grid>
				<Grid item xs={12} lg={3}>
					<Card elevation={4}>
						<CardHeader title="Member Notification"></CardHeader>
						<CardContent>
							<DiagnosisNotificationList diagnosisID={diagnosisID} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}
export { DiagnosisDetailsMain };
export type { DiagnosisTypeApiRequest, DiagnosisRangeApiRequest };
