import { apiRequest } from "../../../../api_extension/apiRequest";
import { useQuery, UseQueryResult } from "react-query";
import { DiagnosisDetails_QueryParams } from "../useDiagnosisDetails";
// import { DiagnosisDetail_Overview } from "./useDiagnosisDetailsOverview";
import { randomizeDemoData } from "../../../../shared/utilities/demoDataRandomizer";

type DiagnosisDetails_CompLandscape = {
	total_revenue: number;
	category_percent: number;
	retention_rate: number;
	chart_data: Array<{ name: string; nd: number }>;
	rendered_services_data: Array<{
		name: string;
		Reengagements: number;
		Appointment: number;
		ReceivedCare: number;
	}>;
};

// let d = {
//   total_revenue: 540000,
//   category_percent: 0.45,
//   retention_rate: 0.8,
//   chart_data: [
//     { name: "M", nd: 400 },
//     { name: "J", nd: 200 },
//     { name: "J", nd: 300 },
//     { name: "A", nd: 400 },
//     { name: "S", nd: 300 },
//     { name: "O", nd: 200 },
//   ],
//   rendered_services_data: [
//     {
//       name: "Provider A",
//       Appointments: 4000,
//       Prescriptions: 2400,
//       Procedures: 2400,
//     },
//     {
//       name: "Provider B",
//       Appointments: 3000,
//       Prescriptions: 1398,
//       Procedures: 2210,
//     },
//     {
//       name: "Provider C",
//       Appointments: 2000,
//       Prescriptions: 9800,
//       Procedures: 2290,
//     },
//   ],
// };
// console.debug(JSON.stringify(d));

async function retrieveDiagnosisDetailsCompLandscape(params: DiagnosisDetails_QueryParams): Promise<DiagnosisDetails_CompLandscape> {
	let diagnosis_data = await apiRequest({
		url: `${process.env.PUBLIC_URL}/test_data/diagnosis_detail/radius_impact.json`,
		method: "GET",
	}).then((data) => {
		randomizeDemoData([data]);
		randomizeDemoData(data.chart_data);
		randomizeDemoData(data.rendered_services_data);
		return data;
	});
	return diagnosis_data;
}

function useDiagnosisDetailsCompLandscape(params: DiagnosisDetails_QueryParams): UseQueryResult<DiagnosisDetails_CompLandscape> {
	return useQuery(
		["DiagnosisDetailsCompLandscape", `DiagnosisDetailsCompLandscape_${params.diagnosisID}`],
		retrieveDiagnosisDetailsCompLandscape.bind(null, params),
		{ cacheTime: Infinity, staleTime: Infinity }
	);
}

export { useDiagnosisDetailsCompLandscape };
