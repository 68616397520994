import { Box, Button, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import displayConverter from "shared/utilities/displayConverter";
import { useNavigate } from "react-router-dom";
import { useContext, useMemo } from "react";
import { useX_getMedicationAggregations } from "api_extension/useQueryX/cacheMedicationData/useX_getMedicationAggregations";
import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { getFilterDates } from "DataProvider/hooks/useRunFilterQuery";
import useDataGrid from "shared/components/grid/useDataGrid";
import SimpleTooltipColumn from "shared/components/grid/GridColumn/SimpleTooltipColumn";

interface Filters {
	searchQuery?: string;
}

function TopMedicationsPanel() {
	const navigate = useNavigate();
	const columns: GridColumns = [
		{
			field: "order",
			headerName: "Rank",
			headerAlign: "left",
			align: "left",
			sortable: false,
			minWidth: 40,
			flex: 0.4,
		},
		{
			field: "medication",
			headerName: "Name",
			flex: 2.1,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (value) => {
				let x = value.row;
				return (
					<SimpleTooltipColumn
						code={x.code}
						name={x.name}
						tooltipTitle={
							<Box>
								<Typography>{x.code}</Typography>
							</Box>
						}
						followItemType="MEDICATION"
					/>
				);
			},
		},
		{
			field: "occurrenceCount",
			headerName: "Total Fills",
			flex: 0.8,
			headerAlign: "left",
			align: "left",
			valueGetter: (params) => displayConverter.insertThousandsCommas(params.value),
			sortable: false,
			minWidth: 80,
		},
		{
			field: "totalAmount",
			headerName: "Total Spend",
			flex: 1.4,
			headerAlign: "left",
			align: "left",
			valueGetter: (params) => displayConverter.toUSD(params.value),
			sortable: false,
			minWidth: 40,
		},
		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			width: 128,
			sortable: false,
			renderCell: ({ row }) => (
				<Button
					onClick={() => {
						navigate(`/prescriptions/details/${row.code}`);
					}}
					color="secondary"
				>
					Details
				</Button>
			),
		},
	];
	const { values: primaryFilterValues } = useContext(PrimaryFiltersContext);
	const { grid } = useDataGrid<Filters>("medications", columns);
	useContext(PrimaryFiltersContext);

	let dates = primaryFilterValues.DateRange;
	let x = null;
	if (dates.length) {
		x = getFilterDates(primaryFilterValues);
	}
	const rangeAggregationResponse = useX_getMedicationAggregations([], {
		startDate: x?.start,
		endDate: x?.end,
		memberTypes: primaryFilterValues.Members,
	});

	const rangeAggregateItemsWithOrder = useMemo(() => {
		if (rangeAggregationResponse.data?.response?.aggregateItems) {
			return rangeAggregationResponse.data?.response?.aggregateItems.map((item, index) => ({
				...item,
				order: index + 1,
			}));
		}
		return null;
	}, [rangeAggregationResponse]);
	console.log("rangeAggregationResponse.data?.response?.aggregateItems", rangeAggregationResponse.data?.response?.aggregateItems);

	return (
		<Box>
			<Box sx={{ display: "flex", height: "100%" }}>
				<Box
					sx={{
						flexGrow: 1,
						"& .MuiDataGrid-footerContainer": { display: "none" },
						"& .MuiDataGrid-toolbarContainer": { display: "none" },
					}}
				>
					{rangeAggregateItemsWithOrder ? (
						<DataGridPro
							{...grid}
							getRowId={(row) => row.code}
							rowsPerPageOptions={[10]}
							rowCount={10}
							columns={columns}
							autoHeight
							rows={rangeAggregateItemsWithOrder.slice(0, 10)}
						/>
					) : null}
				</Box>
			</Box>
		</Box>
	);
}
export default TopMedicationsPanel;
