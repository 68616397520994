import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { RequestModelsCacheMonthlyQueryRequest, getCacheMonthlyDetailCombined } from "api";
import { useQuery } from "react-query";

function getCacheMonthlyDetailCombinedX(request: RequestModelsCacheMonthlyQueryRequest) {
	return getCacheMonthlyDetailCombined(request);
}

const useX_getCacheMonthlyDetailCombined = (request_: RequestModelsCacheMonthlyQueryRequest | null) => {
	let r = { ...request_, ...useFilterQuery() };

	let request: RequestModelsCacheMonthlyQueryRequest = {
		dateRangeRequest: {
			startDate: r.startDate,
			endDate: r.endDate,
		},
		jobIds: r.memberTypes,
		codes: r.codes,
		nestedCodes: r.nestedCodes,
		paginationRequest: r.paginationRequest,
		itemType: r.itemType,
		includeLookup: r.includeLookup,
	};

	let cacheKeys = ["getCacheMonthlyDetailCombined", "getCacheMonthlyDetailCombined_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getCacheMonthlyDetailCombinedX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getCacheMonthlyDetailCombined };
