/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  AdminGetAllDiagnosesResult,
  AdminGetAllDiagnosesQuery
} from '../backend.schemas'
import { apiRequest } from '../../api_extension/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllDiagnosis = (
    adminGetAllDiagnosesQuery: AdminGetAllDiagnosesQuery,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<AdminGetAllDiagnosesResult>(
      {url: `/api/admin/Diagnosis/GetAllDiagnosis`, method: 'post',
      data: adminGetAllDiagnosesQuery
    },
      options);
    }
  


    export const useGetAllDiagnosis = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof getAllDiagnosis>, TError,{data: AdminGetAllDiagnosesQuery}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof getAllDiagnosis>, {data: AdminGetAllDiagnosesQuery}> = (props) => {
          const {data} = props || {};

          return  getAllDiagnosis(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof getAllDiagnosis>, TError, {data: AdminGetAllDiagnosesQuery}, TContext>(mutationFn, mutationOptions)
    }
    