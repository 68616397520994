import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { getFilterDates } from "DataProvider/hooks/useRunFilterQuery";
import { AdminRetrieveCacheDoctorDataResult, retrieveCacheDoctorData } from "api";
import { useCallback, useContext, useState } from "react";
// import { Loader } from "shared/components/Loaders/Loader";

const DoctorCacheTools = () => {
	const pFilters = useContext(PrimaryFiltersContext);

	const [adminRetrieveCacheDoctorDataResult, set_adminRetrieveCacheDoctorDataResult] = useState<AdminRetrieveCacheDoctorDataResult | null>(null);

	const [requestInProgress, set_requestInProgress] = useState<string | null>(null);

	const retrieveDoctorCacheMonthlies = useCallback(async () => {
		if (requestInProgress) {
			alert("Request in progress");
			return null;
		}

		set_requestInProgress("--");

		var dates = getFilterDates(pFilters.values);

		var response = await retrieveCacheDoctorData({ dateRangeRequest: { startDate: dates.start, endDate: dates.end } });
		if (response.result?.success) {
			set_adminRetrieveCacheDoctorDataResult(response ?? null);
		}

		set_requestInProgress(null);
	}, [requestInProgress, set_adminRetrieveCacheDoctorDataResult, pFilters.values]);
	/*
██████  ███████  ██████  ██    ██ ███████ ███████ ████████   
██   ██ ██      ██    ██ ██    ██ ██      ██         ██      
██████  █████   ██    ██ ██    ██ █████   ███████    ██      
██   ██ ██      ██ ▄▄ ██ ██    ██ ██           ██    ██      
██   ██ ███████  ██████   ██████  ███████ ███████    ██      
                    ▀▀                                                                
*/

	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
                                                  
*/

	return (
		<div>
			<h1>Doctor Cache Tools</h1>

			<div style={{ display: "flex", flexDirection: "row" }}>
				<button
					disabled={!!requestInProgress}
					onClick={() => {
						retrieveDoctorCacheMonthlies();
					}}
				>
					retrieveDoctorCacheMonthlies
				</button>
			</div>
			<p>..{requestInProgress}</p>

			<pre>{JSON.stringify(adminRetrieveCacheDoctorDataResult, null, 4)}</pre>
		</div>
	);
};

export { DoctorCacheTools };
