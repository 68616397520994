import { Grid, Card, CardHeader, CardContent } from "@mui/material";
import { DiagnosisNotificationDetails } from "./hooks/useDiagnosisNotificationDetails";
import { StandardPieChart } from "../charts/pie/StandardPieChart";
import { palette } from "../theme";
import DoctorStat from "../sections/members_doctors/doctors/components/DoctorStat";

type PanelDiagnosisNotificationCompLandscapeProps = {
	data?: DiagnosisNotificationDetails;
};

function PanelDiagnosisNotificationCompLandscape(props: PanelDiagnosisNotificationCompLandscapeProps) {
	return (
		<Grid container spacing={1} sx={{ height: "100%" }}>
			<Grid item xs={12} lg={6}>
				<Card
					elevation={0}
					sx={{
						backgroundColor: "gray.main",
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<CardHeader title="Successful Conversions by Health Provider"></CardHeader>
					<CardContent sx={{ flex: 1 }}>
						{props.data?.provider_conversion_data ? (
							<StandardPieChart
								data={props.data.provider_conversion_data}
								colors={[palette.red.main, palette.purple.main, palette.green.main, palette.lightBlue.main]}
								showLegend
								innerRadius={32}
								responsiveHeight={300}
							/>
						) : null}
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} lg={6}>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", height: "100%" }}>
					<CardHeader title="Top Doctors by Conversion"></CardHeader>
					<CardContent>
						<DoctorStat rating={4} statLabel={"1. GRAY TEAS, DR."} statValue={"4"} statValueColor={palette.purple.main} />
						<DoctorStat rating={5} statLabel={"2. WHITSON JOSHUA"} statValue={"3"} statValueColor={palette.purple.main} />
						<DoctorStat rating={4} statLabel={"3. CHUN ALEXANDER, DR."} statValue={"3"} statValueColor={palette.purple.main} />
						<DoctorStat rating={2} statLabel={"4. MULBERRY BRIAN"} statValue={"3"} statValueColor={palette.purple.main} />
						<DoctorStat rating={4} statLabel={"5. SZNYTER LAURA, DR."} statValue={"2"} statValueColor={palette.purple.main} />
						<DoctorStat rating={4} statLabel={"6. SULLIVAN BRIGID, DR."} statValue={"1"} statValueColor={palette.purple.main} />
						<DoctorStat rating={3} statLabel={"7. HAYS ROBERT"} statValue={"1"} statValueColor={palette.purple.main} />
						<DoctorStat rating={4} statLabel={"8. LEE SANG, DR."} statValue={"1"} statValueColor={palette.purple.main} />
						<DoctorStat rating={1} statLabel={"9. MOTIKA CALVIN"} statValue={"1"} statValueColor={palette.purple.main} />
						<DoctorStat rating={4} statLabel={"10. HAUGABROOK ANITA, DR."} statValue={"1"} statValueColor={palette.purple.main} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

export default PanelDiagnosisNotificationCompLandscape;
