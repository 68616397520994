import { useState, useEffect } from "react";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer, Legend } from "recharts";
import { palette } from "../../theme";
import { Box, Grid, SxProps, Theme } from "@mui/material";
import LegendRow from "../../shared/components/LegendRow";
import displayConverter from "../../shared/utilities/displayConverter";
interface StandardPieChartProps {
	data: Array<{ [attr: string]: string | number }>;
	colors?: Array<any>;
	innerRadius?: number;
	outerRadius?: number;
	dataKey?: string;
	nameKey?: string;
	showLegend?: boolean;
	fullLegendSx?: SxProps<Theme>;
	showStandardLegend?: boolean;
	standardLegendVerticalAlign?: "top" | "middle" | "bottom";
	legendIcon?: "line" | "plainline" | "square" | "rect" | "circle" | "cross" | "diamond" | "star" | "triangle" | "wye";
	legendRowSx?: SxProps<Theme>;
	width?: number;
	height?: number;
	legendBorder?: string;
	legendPadding?: string;
	displayPercentage?: boolean;
	responsiveHeight?: number;
	tooltipFormatter?: (value: any, name: any, props: any) => string | Array<string>;
	tooltipLabelFormatter?: (label: any, payload: any) => string | Array<string>;
	legendFormatter?: (value: any, name: any, props: any) => string | Array<string>;

	dollarAboveLegend?: boolean;
}

function StandardPieChart(props: StandardPieChartProps) {
	const [dataTimeStamp, setDataTimeStamp] = useState(Date.now());
	useEffect(() => {
		// force rerender and animate
		setDataTimeStamp(Date.now());
	}, [props.data]);

	let total = 0;
	for (const value of props.data) {
		let referrals = value[props.dataKey ?? "value"] as number;
		total += referrals;
	}

	let colors = [
		palette.lightBlue.main,
		palette.red.main,
		palette.purple.main,
		palette.primary.main,
		palette.orange.light,
		palette.green.light,
		palette.lightBlue.light,
		palette.red.dark,
		palette.purple.light,
		palette.green.dark,
	];
	let chart = (
		<ResponsiveContainer height={props.responsiveHeight} width="100%">
			<PieChart key={dataTimeStamp}>
				<Pie
					data={props.data}
					nameKey={props.nameKey ?? "name"}
					dataKey={props.dataKey ?? "value"}
					cx="50%"
					cy="50%"
					innerRadius={props.innerRadius ?? 60}
					fill="#8884d8"
				>
					{props.data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={props.colors ? props.colors[index] : colors[index]} />
					))}
				</Pie>
				<Tooltip labelFormatter={props.tooltipLabelFormatter} formatter={props.tooltipFormatter} />
				<>
					{props.showStandardLegend ? (
						<Legend verticalAlign="top" iconType={props.legendIcon} iconSize={16} formatter={props.legendFormatter} />
					) : null}
				</>
			</PieChart>
		</ResponsiveContainer>
	);

	if (props.showLegend) {
		return (
			<Grid container sx={{ height: "100%" }}>
				<Grid
					xs={12}
					sm={5}
					md={4}
					item
					border={props.legendBorder}
					padding={props.legendPadding}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Box className="chart-legend-container" sx={props.fullLegendSx}>
						{props.data.map((row, x) => {
							const value = row[props.dataKey ?? "value"] as number;
							let displayValue = displayConverter.insertThousandsCommas(value);
							let aboveLegendDisplay = null;
							if (props.dollarAboveLegend) {
								aboveLegendDisplay = displayConverter.toUSD(value);
							}
							if (props.displayPercentage) {
								displayValue = displayConverter.toPercent(value / total, { plus_minus: undefined }, 1);
							}
							return (
								<LegendRow
									key={x}
									item={{
										name: row[props.nameKey ?? "name"] as string,
										value: displayValue,
									}}
									legendValue={aboveLegendDisplay}
									color={props.colors ? props.colors[x] : colors[x]}
									legendRowSx={props.legendRowSx}
								/>
							);
						})}
					</Box>
				</Grid>
				<Grid
					xs={12}
					sm={7}
					md={8}
					item
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{chart}
				</Grid>
			</Grid>
		);
	}

	return chart;
}

export { StandardPieChart };
