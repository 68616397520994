import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material";
import { AuthProvider } from "account/auth/AuthProvider";
import { AccountRoutes } from "account/AccountRoutes";
import { SignOutPage } from "account/SignOutPage";
import { DataProvider } from "./DataProvider/DataProvider";
import { SnackbarProvider } from "notistack";
import { theme } from "./theme";
import { Shell } from "shared/layout/Shell";
import { Overview } from "sections/dashboard/Overview";
import { DiagnosisRoutes } from "sections/diagnosis/DiagnosisRoutes";
import { RevenueRoutes } from "sections/revenue/RevenueRoutes";
import { AdminRoutes } from "sections/settings/AdminRoutes";
import { clearJwtToken, setJwtToken } from "api_extension/client";
import { AuthState, getAuthSession } from "account/auth/context";
import { PublicRoutes } from "account/PublicRoutes";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { ReportingRoutes } from "sections/reporting/ReportingRoutes";
import { DeveloperRouter } from "sections/developer/DeveloperRouter";
import { MedicationRoutes } from "sections/prescription/MedicationRoutes";
import { FollowedItemsMain } from "sections/followed_items/FollowedItemsMain";
import { ItemDetailsMain } from "sections/item_details/ItemDetailsMain";
import { ProcedureRoutes } from "sections/procedures/ProcedureRoutes";
import MembersRoutes from "sections/members_doctors/members/MembersRoutes";
import DoctorsRoutes from "sections/members_doctors/doctors/DoctorsRoutes";
import FacilitiesRoutes from "sections/facilities/FacilitiesRoutes";

const queryClient = new QueryClient();
window.RadiusCare = {
	data: {},
	developer: {
		queryInvalidateCache: (x: string) => null,
		enableResendButtons: (x: boolean) => null,
		test: {},
	},
};

function onAuthStateChange(state: AuthState) {
	if (state.authenticated) {
		setJwtToken(async () => (await getAuthSession(state.user)).getIdToken().getJwtToken());
	} else {
		clearJwtToken();
	}
}

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<SnackbarProvider autoHideDuration={2000}>
						<AuthProvider onStateChange={onAuthStateChange} publicRoutes={<PublicRoutes />}>
							<QueryClientProvider client={queryClient}>
								<DataProvider>
									<Shell>
										<Routes>
											<Route path="/account/*" element={<AccountRoutes />} />
											<Route path="/diagnosis/*" element={<DiagnosisRoutes />} />
											<Route path="/prescriptions/*" element={<MedicationRoutes />} />
											<Route path="/procedures/*" element={<ProcedureRoutes />} />
											<Route path="/overview/" element={<Overview />} />
											<Route path="/signout/" element={<SignOutPage />} />
											<Route path="/spend/*" element={<RevenueRoutes />} />
											<Route path="/settings/*" element={<AdminRoutes />} />
											<Route path="/reporting/*" element={<ReportingRoutes />} />
											<Route path="/followedItems/*" element={<FollowedItemsMain />} />
											{/* <Route path="/membersAndDoctors/*" element={<MembersAndDoctorsRoutes />} /> */}
											<Route path="/members/*" element={<MembersRoutes />} />
											<Route path="/doctors/*" element={<DoctorsRoutes />} />
											<Route path="/facilities/*" element={<FacilitiesRoutes />} />
											<Route path="/itemDetails/*" element={<ItemDetailsMain /*forceCacheItemDetails={true}*/ />} />
											<Route path="/developer/*" element={<DeveloperRouter />} />
											<Route path="*" element={<Navigate to="overview" />} />
										</Routes>
									</Shell>
								</DataProvider>
							</QueryClientProvider>
						</AuthProvider>
					</SnackbarProvider>
				</LocalizationProvider>
			</Router>
		</ThemeProvider>
	);
}

export default App;
