import { useEffect, useMemo, useReducer, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DataGridPro, GridColumns, GridSortModel } from "@mui/x-data-grid-pro";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import { ModelsPaginationAndSortRequest, ModelsPatientCacheBasicResponseItem, ProvidersRiskModelFilterCriteria } from "api";
import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { DateConverter } from "shared/utilities/DateConverter";
import { useX_retrieveCachePatientData } from "api_extension/useQueryX/riskModel/useX_retrieveCachePatientData";
import { gridColumnsLayout_CachePatientMonthly_Basic } from "shared/utilities/DataGridColumns/dataGridLayouts/gridColumns_cachePatientMonthly";
import { ReducerUtility } from "shared/utilities/ReducerUtility";
import { ViewEntityDetailButton } from "shared/components/ViewEntityDetailButton";
import { SortBarSelect } from "shared/components/SortBarSelect";
import { ResendQueryButton } from "shared/components/Developer/ResendQueryButton";
import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { PatientSelect } from "../PatientSelect";

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Members List"} {...props} enableFilter={false} enableExport={false} />;
}

const sortOptions = [
	{ label: "Age", value: "birth_date" },
	{ label: "Diagnoses", value: "diagnosis_count" },
	{ label: "Prescriptions", value: "medication_count" },
	{ label: "Procedures", value: "procedure_count" },
	{ label: "Records", value: "record_count" },
	{ label: "Spend", value: "total_amount" },
];

function RiskScoreMembersList({
	riskModelFilters,
	activeRiskCategory,
}: {
	riskModelFilters: ProvidersRiskModelFilterCriteria | null;
	activeRiskCategory: RiskCategoryNames | null;
}) {
	// Leave in case for future use
	// const [startDate, set_startDate] = useState<Date | null>(null);
	// const [endDate, set_endDate] = useState<Date | null>(null);
	// const MonthRangeSelectInstance = useMemo(() => {
	// 	return <MonthRangeSelection startDate={startDate} set_startDate={set_startDate} endDate={endDate} set_endDate={set_endDate} inheritGlobalFilter />;
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);
	/*


*/
	let [selected, setSelected] = useState<string | null>(null);
	const [paginationRequest, dispatch_paginationRequest] = useReducer(
		(x: ModelsPaginationAndSortRequest, action: Partial<ModelsPaginationAndSortRequest>) => {
			return ReducerUtility.UpdatePaginationRequestWithPartialValue(x, action);
		},
		{
			field: "total_amount",
			descending: true,
			skip: 0,
			take: 40,
		}
	);

	const patientCacheDataColumns: GridColumns = useMemo(() => {
		return [
			...gridColumnsLayout_CachePatientMonthly_Basic,
			{
				field: "Actions",
				headerName: "",
				headerAlign: "right",
				align: "right",
				width: 128,
				sortable: false,
				disableColumnMenu: true,
				renderCell: ({ row }) => <ViewEntityDetailButton itemType="PATIENT" code={row.patientId} />,
			},
		];
	}, []);

	let filters = useFilterQuery();
	const enhancedRiskModelFilters = useMemo<ProvidersRiskModelFilterCriteria>(() => {
		return {
			memberTypes: riskModelFilters?.memberTypes ?? null,
			startDate: filters.startDate ? DateConverter.stringDateForAPI(filters.startDate) : null,
			endDate: filters.endDate ? DateConverter.stringDateForAPI(filters.endDate) : null,
			riskModel: riskModelFilters?.riskModel ?? null,
		} as ProvidersRiskModelFilterCriteria;
	}, [riskModelFilters, filters.startDate, filters.endDate]);

	const [queriedRiskCategory, set_queriedRiskCategory] = useState<RiskCategoryNames | null>(activeRiskCategory);
	const [queriedCriteria, set_queriedCriteria] = useState<ProvidersRiskModelFilterCriteria>(enhancedRiskModelFilters);

	useEffect(() => {
		dispatch_paginationRequest({ skip: 0 });
		set_page(0);
		set_cumulativeRows(null);
		set_queriedRiskCategory(activeRiskCategory);
		set_queriedCriteria(enhancedRiskModelFilters);
	}, [activeRiskCategory, enhancedRiskModelFilters, paginationRequest.field, paginationRequest.descending]);

	const membersResponse = useX_retrieveCachePatientData(queriedCriteria, queriedRiskCategory, paginationRequest, [selected ?? ""]);

	const pageSize = 10;
	const [page, set_page] = useState(0);

	const [cumulativeRows, set_cumulativeRows] = useState<Array<ModelsPatientCacheBasicResponseItem> | null>(null);
	const [totalCount, set_totalCount] = useState(0);

	useEffect(() => {
		if (membersResponse.data?.result?.data?.items) {
			if (page > 0) {
				set_cumulativeRows((cumulativeRows ?? []).concat(membersResponse.data.result.data.items));
			} else {
				set_cumulativeRows(membersResponse.data.result.data.items);
			}
			set_totalCount(membersResponse.data.result.data.total ?? 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [membersResponse.data?.result?.data?.items, membersResponse.data?.result]);

	/*
	
	
	
	
	

	RENDER
	*/
	return (
		<Box style={{ paddingTop: "2rem" }}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
				<PatientSelect styles={{ width: 30 }} setSelectedValue={setSelected} />
				<SortBarSelect
					sort={paginationRequest.field ?? ""}
					set_sort={(field: string) => {
						dispatch_paginationRequest({ field: field });
					}}
					sortOptions={sortOptions}
					descending={paginationRequest.descending ?? false}
					set_descending={(desc: boolean) => {
						dispatch_paginationRequest({ descending: desc });
					}}
				/>
				{/* <Stack
					sx={{ alignItems: "flex-end" }}
					style={
						{ display: "none" }
						// dont delete this -- it is needed to pass through the filter dates as is - can be carved out but like this it can easily be added back if desired
					}
				>
					{MonthRangeSelectInstance}
				</Stack> */}
			</Stack>
			<ResendQueryButton queryCacheName="getPaginatedPatientCacheData" />
			<QueryStatusDisplay showAsLoading={!membersResponse?.data} queryResult={membersResponse ?? undefined}>
				<Box sx={{ display: "flex", height: "100%", pt: 2 }}>
					<Box
						sx={{
							flexGrow: 1,
						}}
					>
						{cumulativeRows ? (
							<DataGridPro
								disableColumnFilter
								disableColumnMenu
								columns={patientCacheDataColumns}
								autoHeight
								getRowId={(x) => {
									return x.patientId;
								}}
								rows={cumulativeRows}
								rowCount={totalCount}
								components={{ Toolbar: Toolbar }}
								pagination // Was asked to remove pagination - comment this out to remove and change take size to what is desired.
								page={page}
								onPageChange={(newPage) => {
									let maxDisplayedRow = (newPage + 1) * pageSize;
									if (maxDisplayedRow > cumulativeRows.length && cumulativeRows.length < totalCount) {
										dispatch_paginationRequest({ skip: cumulativeRows.length });
									}
									set_page(newPage);
								}}
								pageSize={pageSize}
								rowsPerPageOptions={[10]}
								sortingMode="server"
								sortModel={[{ field: paginationRequest.field ?? "", sort: paginationRequest.descending ? "desc" : "asc" }]}
								onSortModelChange={(sm: GridSortModel) => {
									if (sm.length > 0) {
										let descending = sm[0].sort === "desc";
										if (sm[0].field !== paginationRequest.field) {
											descending = true;
										}
										dispatch_paginationRequest({ field: sm[0].field, descending: descending });
									} else {
										// every third click calls with an empty sm array. It's to 'reset' the filters
										dispatch_paginationRequest({ descending: !paginationRequest.descending });
									}
								}}
							/>
						) : null}
					</Box>
				</Box>
			</QueryStatusDisplay>
		</Box>
	);
}

export { RiskScoreMembersList };
