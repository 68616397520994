import { Route, Routes } from "react-router-dom";
import { ProcedureSummaryTileList } from "./ListSummaries/ProcedureSummaryTileList";
import { ProductServiceDetailsMain } from "./details/ProductServiceDetailsMain";

function ProcedureRoutes() {
	return (
		<Routes>
			<Route path={"*"} element={<ProcedureSummaryTileList />} />
			<Route path={`details/:procedureId`} element={<ProductServiceDetailsMain />} />
		</Routes>
	);
}

export { ProcedureRoutes };
