import { FilterQueryRequest, useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { RequestModelsCacheMonthlyQueryRequest, getCacheMonthlyDetail } from "api";
import { useQuery } from "react-query";

function getCacheMonthlyDetailX(request: RequestModelsCacheMonthlyQueryRequest) {
	return getCacheMonthlyDetail(request);
}

const useX_getCacheMonthlyDetail = (request_: RequestModelsCacheMonthlyQueryRequest | null) => {
	let filterQ = useFilterQuery();
	let r = Object.assign({}, filterQ, request_);

	let request: RequestModelsCacheMonthlyQueryRequest = {
		dateRangeRequest: {
			startDate: r.startDate,
			endDate: r.endDate,
		},
		jobIds: r.memberTypes,
		codes: r.codes,
		nestedCodes: r.nestedCodes,
		paginationRequest: r.paginationRequest,
		itemType: r.itemType,
		includeLookup: r.includeLookup,
	};

	let cacheKeys = ["getCacheMonthlyDetail", "getCacheMonthlyDetail_" + JSON.stringify(request)];

	return useQuery(cacheKeys, getCacheMonthlyDetailX.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

function addFilterQueryToRequest<T>(request_: T, fq: FilterQueryRequest) {
	let fqX = {
		dateRangeRequest: {
			startDate: fq.startDate,
			endDate: fq.endDate,
		},
		jobIds: fq,
	};

	return Object.assign({}, fqX, request_);
}

export { useX_getCacheMonthlyDetail, addFilterQueryToRequest };
