import { useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import ValueTile from "../../../../../shared/components/ValueTile";
import { Swiper, SwiperSlide } from "swiper/react";
import displayConverter from "shared/utilities/displayConverter";
import { constructProvidersRiskModelFilterCriteria } from "DataProvider/hooks/useContextPrimaryFilters";
import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { RiskScoreMembersList } from "./RiskScoreMembersList";
import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import { useSearchParams } from "react-router-dom";
import { riskCategoryVariants } from "sections/dashboard/MemberHealthRiskModel/MemberRiskRow";
import { ResendQueryButton } from "shared/components/Developer/ResendQueryButton";
import { useX_GetCachePatientRiskModelScoreOverview } from "api_extension/useQueryX/riskModel/useX_GetCachePatientRiskModelScoreOverview";

interface RiskCategoryValueDisplay extends SimpleValueDisplay {
	patientCount: number;
	eobCount: number;
	totalAmount: number;
}

function RiskScorePanel() {
	const [searchParams] = useSearchParams();

	let _activeRiskCategory = null;
	if (Array.from(searchParams.keys()).indexOf("riskCategory") !== -1) {
		_activeRiskCategory = searchParams.get("riskCategory") as RiskCategoryNames;
	}

	const [activeRiskCategory, set_activeRiskCategory] = useState<null | RiskCategoryNames>(_activeRiskCategory);
	const primaryFilters = useContext(PrimaryFiltersContext);

	const riskModelFilters = useMemo(() => {
		return constructProvidersRiskModelFilterCriteria(primaryFilters.values);
	}, [primaryFilters.values]);

	const cachePatientRiskModelScoresResponse = useX_GetCachePatientRiskModelScoreOverview(riskModelFilters);

	const categoryCounts = useMemo(() => {
		let values: { [key in RiskCategoryNames]: RiskCategoryValueDisplay | undefined } = {
			healthy: undefined,
			moderate: undefined,
			serious: undefined,
			no_visits: undefined,
		};

		let data = cachePatientRiskModelScoresResponse.data?.result;

		if (data?.data?.categories) {
			data.data.categories.forEach((x) => {
				let v: RiskCategoryValueDisplay = {
					value: x.patientCount ?? 0,
					displayName: x.riskCategory ?? "-",
					displayOptions: { color: riskCategoryVariants[x.riskCategory ?? ""]?.textColor },
					patientCount: x.patientCount ?? 0,
					eobCount: x.eobCount ?? 0,
					totalAmount: x.totalAmount ?? 0,
				};
				switch (x.riskCategory) {
					case "Healthy":
						values.healthy = v;
						break;
					case "Moderate":
						values.moderate = v;
						break;
					case "Serious":
						values.serious = v;
						break;
					case "No Visits":
						values.no_visits = v;
						break;
				}
			});
		}
		return values;
	}, [cachePatientRiskModelScoresResponse.data]);

	return (
		<Box>
			<ResendQueryButton queryCacheName="retrieveCachePatientRiskModelScoreOverview" />
			<Box
				sx={{
					"& .swiper-container": {
						pb: { xs: 4, md: 0 },
					},
					"& .swiper-pagination-bullets": {
						bottom: "0",
					},
				}}
			>
				<QueryStatusDisplay showAsLoading={cachePatientRiskModelScoresResponse === null} queryResult={cachePatientRiskModelScoresResponse ?? undefined}>
					<Swiper
						spaceBetween={16}
						slidesPerView={1}
						pagination={{
							clickable: true,
						}}
						breakpoints={{
							"700": {
								slidesPerView: 3,
							},
							"1200": {
								slidesPerView: 4,
							},
						}}
					>
						{Object.getOwnPropertyNames(categoryCounts).map((_key) => {
							let key = _key as RiskCategoryNames;
							let isActive = key === activeRiskCategory;
							return (
								<SwiperSlide key={key}>
									<ValueTile
										value={displayConverter.insertThousandsCommas(categoryCounts[key]?.value)}
										title={categoryCounts[key as RiskCategoryNames]?.displayName as string}
										minHeight="7rem"
										onClick={() => {
											set_activeRiskCategory(isActive ? null : key);
										}}
										active={isActive}
										primaryColor={categoryCounts[key as RiskCategoryNames]?.displayOptions?.color}
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</QueryStatusDisplay>
			</Box>
			<RiskScoreMembersList riskModelFilters={riskModelFilters} activeRiskCategory={activeRiskCategory} />
		</Box>
	);
}

export { RiskScorePanel };
