import { Card, CardHeader, CardContent, Box } from "@mui/material";
import displayConverter from "../../../../shared/utilities/displayConverter";
import ValueTile from "../../../../shared/components/ValueTile";
import { getConditionDetailsImpact } from "api";
import { AdminGetConditionDetailsImpactResult } from "api";
import { useRunFilterQuery } from "../../../../DataProvider/hooks/useRunFilterQuery";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { useDiagnosisDetailsCompLandscape } from "../../hooks/diagnosisDetails/useDiagnosisDetailsCompLandscape";
import StandardStackedBarChart from "charts/bar/StandardStackedBarChart";
SwiperCore.use([Pagination]);

type DiagnosisDetailsCompLandscapeProps = {
	diagnosisID: string;
	icd10Query: string;
	subRangeQuery: string;
};

function DiagnosisDetailsCompLandscape(props: DiagnosisDetailsCompLandscapeProps) {
	const { data } = useRunFilterQuery<AdminGetConditionDetailsImpactResult>(getConditionDetailsImpact, {
		searchQuery: props.diagnosisID,
		icd10Query: props.icd10Query,
		subRangeQuery: props.subRangeQuery,
	});

	const fakeData = useDiagnosisDetailsCompLandscape({
		diagnosisID: props.diagnosisID,
	});

	return (
		<Box>
			<Box
				sx={{
					"& .swiper-container": {
						pb: { xs: 4, md: 0 },
					},
					"& .swiper-pagination-bullets": {
						bottom: "0",
					},
				}}
			>
				<Swiper
					spaceBetween={16}
					slidesPerView={1}
					// onSlideChange={() => console.log("slide change")}
					// onSwiper={(swiper) => console.log(swiper)}
					pagination={{
						clickable: true,
					}}
					breakpoints={{
						"700": {
							slidesPerView: 3,
						},
						"1200": {
							slidesPerView: 3,
						},
					}}
				>
					<SwiperSlide>
						<ValueTile value={displayConverter.toUSD(data?.items?.totalRevenue)} title="Total Revenue" minHeight="7rem" />
					</SwiperSlide>
					<SwiperSlide>
						<ValueTile value={displayConverter.toPercent(0)} title="Category PCT" minHeight="7rem" />
					</SwiperSlide>
					<SwiperSlide>
						<ValueTile value={displayConverter.toPercent(0)} title="Retention Rate" minHeight="7rem" />
					</SwiperSlide>
				</Swiper>
			</Box>
			<Box pt={2}>
				<Card
					elevation={0}
					sx={{
						backgroundColor: "gray.main",
					}}
				>
					<CardHeader title="Radius Care Engagement & Behavior Change"></CardHeader>
					<CardContent>
						{fakeData.data ? (
							<StandardStackedBarChart
								data={fakeData.data.rendered_services_data}
								xKey="name"
								yKeys={[{ key: "Re-engagements" }, { key: "Appointment" }, { key: "Received Care" }]}
								legendVerticalAlign="top"
								responsiveHeight={300}
								legendStyle={{ paddingBottom: "1rem" }}
							/>
						) : null}
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
}

export { DiagnosisDetailsCompLandscape };
