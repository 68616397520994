import DoctorsOverview from "./DoctorsOverview";
import { Route, Routes, Navigate } from "react-router-dom";

function DoctorsRoutes() {
	return (
		<Routes>
			<Route path={"*"} element={<Navigate to={`overview`} />} />
			<Route path={`overview`} element={<DoctorsOverview />} />
		</Routes>
	);
}

export default DoctorsRoutes;
