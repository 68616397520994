const displayConverter = {
	insertThousandsCommas(value: number | string | undefined | null) {
		if (value === undefined || value === null) {
			return "";
		}
		let s = Array.from(String(value));

		let decimal_offset = s.indexOf(".") === -1 ? 0 : s.length - s.indexOf(".");
		for (let i = 3 + decimal_offset; s.length - i > 0; i += 4) {
			s.splice(s.length - i, 0, ",");
		}
		return s.join("");
	},
	toUSD(value: number | undefined | null, options?: { cents?: boolean }) {
		if (value === undefined || value === null) {
			return "$0";
		}
		// add commas and round to two decimals
		let val: number | string;

		if (options?.cents) {
			val = value.toFixed(2);
		} else {
			val = value.toFixed(0);
		}
		// = value % 1 > 0 || options?.cents ? value.toFixed(2) : value.toFixed(0);

		return "$" + displayConverter.insertThousandsCommas(val);
	},
	toPercent(value: number | undefined, options?: { plus_minus?: boolean }, decimals?: number) {
		if (value === undefined) {
			return "";
		}
		if (decimals === 1) {
			return (options?.plus_minus ? (value >= 0 ? "+" : "") : "") + Math.round(value * 1000) / 10 + "%";
		}
		return (options?.plus_minus ? (value >= 0 ? "+" : "") : "") + Math.round(value * 100) + "%";
	},
	display(value: string | number | undefined): string {
		if (value === undefined) {
			return "";
		}
		return String(value);
	},
	periodizeIcdCode(icdCode: string | undefined): string {
		if (!icdCode) {
			return "";
		}
		icdCode = icdCode.replaceAll(".", "");
		if (icdCode.length > 3) {
			icdCode = icdCode.substring(0, 3) + "." + icdCode.substring(3);
		}
		return icdCode;
	},
};

export default displayConverter;
