import { Stack, Card, CardContent, CardHeader, Button, Box } from "@mui/material";
import displayConverter from "../../../shared/utilities/displayConverter";
import ValueTile from "shared/components/ValueTile";
import { Condition } from "../../../shared/schemas/dataStructures";
import { useDiagnosisFilters } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";
import { ModelsDiagnosisRangeCacheMonthlyAggregate } from "api";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import { Loader } from "shared/components/Loaders/Loader";
import { UserFollowedItemButton } from "shared/components/UserFollowedItemButton";

SwiperCore.use([Pagination]);

interface Props {
	loading?: boolean;
	condition?: Condition;
	rcma?: ModelsDiagnosisRangeCacheMonthlyAggregate;
	totalMembers: number;
}
function DiagnosisSummaryTile(props: Readonly<Props>) {
	const { condition, rcma, loading, totalMembers } = props;
	const diagnosisFilters = useDiagnosisFilters();

	const navigate = useNavigate();
	return (
		<Card
			elevation={4}
			sx={{
				mb: 2,
			}}
		>
			{loading ? (
				<Loader />
			) : (
				<>
					{rcma?.range ? (
						<Box
							style={{
								position: "relative",
								height: 0,
								overflow: "visible",
							}}
						>
							<Box
								style={{
									width: "0px",
									maxWidth: 0,
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}
								sx={{ padding: { xs: "1.15em 1em", sm: "1.3em 1em", md: "1.5em 1em" } }}
							>
								<UserFollowedItemButton itemType="DIAGNOSIS_RANGE" code={rcma?.range} />
							</Box>
						</Box>
					) : null}
					<CardHeader
						data-id="CardHeader"
						title={condition?.name ?? "[Missing Data]"}
						sx={{
							marginLeft: "2em",
							flexWrap: "wrap",
							"& .MuiCardHeader-content": {
								width: {
									xs: "100%",
									md: "auto",
								},
								pb: {
									xs: 1,
									md: 0,
								},
							},
							"& .MuiCardHeader-action": {
								width: {
									xs: "100%",
									md: "auto",
								},
							},
						}}
						action={
							<Stack direction="row" justifyContent="flex-end" alignItems="center">
								<Button
									disabled={!condition}
									color="secondary"
									onClick={() => {
										if (condition) {
											diagnosisFilters.setValues({
												Conditions: [condition],
											});
											navigate(`/diagnosis/details/${condition.id}`);
										}
									}}
								>
									Details
								</Button>
							</Stack>
						}
					></CardHeader>
					<CardContent sx={{ pt: 0 }}>
						<Box
							sx={{
								"& .swiper-container": {
									pb: { xs: 4, xl: 0 },
								},
								"& .swiper-pagination-bullets": {
									bottom: "0",
								},
							}}
						>
							<Swiper
								spaceBetween={16}
								slidesPerView={1}
								onSlideChange={() => console.log("slide change")}
								// onSwiper={(swiper) => console.log(swiper)}
								pagination={{
									clickable: true,
								}}
								breakpoints={{
									"700": {
										slidesPerView: 2,
									},
									"900": {
										slidesPerView: 3,
									},
									"1500": {
										slidesPerView: 5,
									},
									"1900": {
										slidesPerView: 5,
									},
								}}
							>
								{rcma ? (
									<>
										<SwiperSlide>
											<ValueTile
												value={
													(rcma.uniquePatientCount ?? 0) / totalMembers > 0.01
														? displayConverter.toPercent((rcma.uniquePatientCount ?? 0) / totalMembers)
														: "<1%"
												}
												title="PCT of Total Members"
												minHeight="7rem"
											/>
										</SwiperSlide>

										<SwiperSlide>
											<ValueTile
												value={displayConverter.insertThousandsCommas(rcma.uniquePatientCount)}
												title="Members"
												minHeight="7rem"
											/>
										</SwiperSlide>

										<SwiperSlide>
											<ValueTile value={displayConverter.insertThousandsCommas(rcma.eobCount)} title="Diagnoses" minHeight="7rem" />
										</SwiperSlide>

										<SwiperSlide>
											<ValueTile value={displayConverter.toUSD(rcma.totalAmount)} title="Total Cost" minHeight="7rem" />
										</SwiperSlide>

										<SwiperSlide>
											<ValueTile value={displayConverter.toUSD(rcma.pmpm, { cents: false })} title="PmPm" minHeight="7rem" />
										</SwiperSlide>
									</>
								) : (
									<SwiperSlide>
										<ValueTile value={0} title="Total Occurrences" minHeight="7rem" />
									</SwiperSlide>
								)}
							</Swiper>
						</Box>
					</CardContent>
				</>
			)}
		</Card>
	);
}

export { DiagnosisSummaryTile };
