import { Box } from "@mui/material";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyTotalsResponse } from "api";
import { Loader } from "shared/components/Loaders/Loader";
import displayConverter from "shared/utilities/displayConverter";
import ValueTile from "shared/components/ValueTile";

SwiperCore.use([Pagination]);

function FacilitiesOverviewHeaderTiles({ overview_data }: { overview_data: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyTotalsResponse[] | null }) {
	return (
		<Box
			sx={{
				"& .swiper-container": {
					pb: { xs: 4, xl: 0 },
				},
				"& .swiper-pagination-bullets": {
					bottom: "0",
				},
			}}
		>
			<Swiper
				spaceBetween={16}
				slidesPerView={1}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					"700": {
						slidesPerView: 3,
					},
					"1200": {
						slidesPerView: 4,
					},
					"1500": {
						slidesPerView: 5,
					},
					"1900": {
						slidesPerView: 5,
					},
				}}
			>
				{overview_data ? (
					<>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data?.find((x) => x.itemType === "FACILITY")?.totalCount)}
								title="Total Facilities"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data?.find((x) => x.itemType === "PATIENT_EOB")?.totalCount)}
								title="Visits"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data?.find((x) => x.itemType === "MEDICATION")?.totalCount)}
								title="Prescriptions"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(overview_data?.find((x) => x.itemType === "PRODUCTSERVICE")?.totalCount)}
								title="Procedures"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.toUSD(Number(overview_data?.find((x) => x.itemType === "FACILITY")?.totalAmount))}
								title="Billing"
								minHeight="7rem"
							/>
						</SwiperSlide>
					</>
				) : (
					<Loader />
				)}
			</Swiper>
		</Box>
	);
}

export { FacilitiesOverviewHeaderTiles };
