import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { AdminGetDoctorsByProductServiceQuery, ModelsPaginationAndSortRequest, getDoctorsByProductService } from "api";
import { useQuery } from "react-query";
import { ProductServiceTypeApiRequest } from "sections/procedures/details/ProductServiceDetailsMain";

export const useX_GetDoctorByProductService = (req: ProductServiceTypeApiRequest, pagination?: ModelsPaginationAndSortRequest) => {
	let fqrX = useFilterQuery();

	let request: AdminGetDoctorsByProductServiceQuery = {
		dateRangeRequest: {
			startDate: req.startDate,
			endDate: req.endDate,
		},
		jobIds: fqrX.memberTypes,
		codes: req.codes,
		paginationSortRequest: pagination,
	};

	console.debug(request);

	let cacheKeys = ["getDoctorByProductService", "GetDoctorByProductService_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getDoctorsByProductService.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};
