import { Stack, Card, CardContent, CardHeader, Button, Box } from "@mui/material";
import displayConverter from "../../../shared/utilities/displayConverter";
import ValueTile from "shared/components/ValueTile";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import { Loader } from "shared/components/Loaders/Loader";
import { UserFollowedItemButton } from "shared/components/UserFollowedItemButton";
import { ProductServiceCacheProductServiceMonthlyAggregate } from "api";

SwiperCore.use([Pagination]);

interface Props {
	loading?: boolean;
	monthlyAgg?: ProductServiceCacheProductServiceMonthlyAggregate;
	totalMembers: number;
}
function ProcedureSummaryTile(props: Readonly<Props>) {
	const { monthlyAgg, loading, totalMembers } = props;
	const navigate = useNavigate();
	return (
		<Card
			elevation={4}
			sx={{
				mb: 2,
			}}
		>
			{loading ? (
				<Loader />
			) : (
				<>
					{monthlyAgg?.productServiceCode ? (
						<Box
							style={{
								position: "relative",
								height: 0,
								overflow: "visible",
							}}
						>
							<Box
								style={{
									width: "0px",
									maxWidth: 0,
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}
								sx={{ padding: { xs: "1.15em 1em", sm: "1.3em 1em", md: "1.5em 1em" } }}
							>
								<UserFollowedItemButton itemType="PRODUCTSERVICE" code={monthlyAgg.productServiceCode} />
							</Box>
						</Box>
					) : null}
					<CardHeader
						title={monthlyAgg?.name ?? "[Procedure]"}
						sx={{
							marginLeft: "2em",
							flexWrap: "wrap",
							"& .MuiCardHeader-content": {
								width: {
									xs: "100%",
									md: "auto",
								},
								pb: {
									xs: 1,
									md: 0,
								},
							},
							"& .MuiCardHeader-action": {
								width: {
									xs: "100%",
									md: "auto",
								},
							},
						}}
						action={
							<Stack direction="row" justifyContent="flex-end" alignItems="center">
								<Button
									disabled={!monthlyAgg?.productServiceCode}
									color="secondary"
									onClick={() => {
										if (monthlyAgg?.productServiceCode) {
											navigate(`/procedures/details/${monthlyAgg?.productServiceCode}`);
										}
									}}
								>
									Details
								</Button>
							</Stack>
						}
					></CardHeader>
					<CardContent sx={{ pt: 0 }}>
						<Box
							sx={{
								"& .swiper-container": {
									pb: { xs: 4, xl: 0 },
								},
								"& .swiper-pagination-bullets": {
									bottom: "0",
								},
							}}
						>
							<Swiper
								spaceBetween={16}
								slidesPerView={1}
								onSlideChange={() => console.log("slide change")}
								// onSwiper={(swiper) => console.log(swiper)}
								pagination={{
									clickable: true,
								}}
								breakpoints={{
									"700": {
										slidesPerView: 2,
									},
									"900": {
										slidesPerView: 3,
									},
									"1500": {
										slidesPerView: 5,
									},
									"1900": {
										slidesPerView: 5,
									},
								}}
							>
								{monthlyAgg ? (
									<>
										<SwiperSlide>
											<ValueTile
												value={
													(monthlyAgg.uniquePatientCount ?? 0) / totalMembers > 0.01
														? displayConverter.toPercent((monthlyAgg.uniquePatientCount ?? 0) / totalMembers)
														: "<1%"
												}
												title="PCT of Total Members"
												minHeight="7rem"
											/>
										</SwiperSlide>
										<SwiperSlide>
											<ValueTile
												value={displayConverter.insertThousandsCommas(monthlyAgg.uniquePatientCount)}
												title="Total Members"
												minHeight="7rem"
											/>
										</SwiperSlide>
										{/* <SwiperSlide>
											<ValueTile value={displayConverter.insertThousandsCommas(0)} title="Category Pct" minHeight="7rem" />
										</SwiperSlide> */}

										<SwiperSlide>
											<ValueTile
												value={displayConverter.insertThousandsCommas(monthlyAgg.eobCount)}
												title="Total Procedures"
												minHeight="7rem"
											/>
										</SwiperSlide>

										<SwiperSlide>
											<ValueTile value={displayConverter.toUSD(monthlyAgg.totalAmount)} title="Total Spend" minHeight="7rem" />
										</SwiperSlide>

										<SwiperSlide>
											<ValueTile value={displayConverter.toUSD(monthlyAgg.pmPm, { cents: false })} title="PmPm" minHeight="7rem" />
										</SwiperSlide>
									</>
								) : (
									<>
										<SwiperSlide>
											<ValueTile value={displayConverter.insertThousandsCommas(0)} title="Total Members" minHeight="7rem" />
										</SwiperSlide>
										<SwiperSlide>
											<ValueTile value={displayConverter.insertThousandsCommas(0)} title="Category Pct" minHeight="7rem" />
										</SwiperSlide>

										<SwiperSlide>
											<ValueTile value={displayConverter.toUSD(0)} title="Total Fills" minHeight="7rem" />
										</SwiperSlide>

										<SwiperSlide>
											<ValueTile value={displayConverter.toUSD(0)} title="Total Spend" minHeight="7rem" />
										</SwiperSlide>

										<SwiperSlide>
											<ValueTile value={displayConverter.toUSD(0)} title="PmPm" minHeight="7rem" />
										</SwiperSlide>
									</>
								)}
							</Swiper>
						</Box>
					</CardContent>
				</>
			)}
		</Card>
	);
}

export { ProcedureSummaryTile };
