import { Box, Stack, Card, CardContent, Typography, Avatar, Button } from "@mui/material";
import { icons } from "../../../../theme";
import Icon from "../../../../shared/Icon";
import * as CSS from "csstype";
import { IconDefinition } from "@fortawesome/fontawesome-common-types/index";
import HelpPopover from "shared/alerts/HelpPopover";

interface MembersSummaryTileProps {
	title: string;
	color: CSS.Property.Color | undefined;
	backgroundColor?: CSS.Property.Color | undefined;
	primaryValue?: string;
	icon?: IconDefinition;
	description?: string;
}

function MembersSummaryTile(props: MembersSummaryTileProps) {
	return (
		<Card elevation={0} sx={{ backgroundColor: props.backgroundColor || "gray.main" }}>
			<CardContent sx={{ position: "relative" }}>
				<Box sx={{ position: "absolute", right: "1rem" }}>
					<HelpPopover text={props.description} color={"gray.dark"} verticalAnchor={"bottom"} horizontalAnchor={0} />
				</Box>
				<Stack direction="row">
					<Avatar
						sx={{
							fontSize: "1rem",
							color: props.color,
							borderColor: props.color,
						}}
					>
						<Icon icon={props.icon ? props.icon : icons.users}></Icon>
					</Avatar>
					<Box sx={{ textAlign: "left", pl: 1 }}>
						<Typography variant="h1" sx={{ color: props.color, fontWeight: 400 }}>
							{props.primaryValue}
						</Typography>
						<Typography
							component="span"
							sx={{
								textTransform: "uppercase",
								fontWeight: 700,
								color: "gray.dark",
							}}
						>
							{props.title}
						</Typography>
					</Box>
				</Stack>
				<Stack
					direction={"row"}
					justifyContent={"flex-end"}
					sx={{
						pt: 2,
						width: "100%",
						fontWeight: 700,
						textTransform: "uppercase",
						color: "primary.main",
					}}
				>
					<Button color="secondary">Details</Button>
				</Stack>
			</CardContent>
		</Card>
	);
}

export default MembersSummaryTile;
