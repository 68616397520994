import React from "react";
import { Box, Grid, Card, CardHeader, CardContent } from "@mui/material";
import { RiskScorePanel } from "./components/RiskScore/RiskScorePanel";
import PanelMembersRecapture from "./components/PanelMembersRecapture";
import PanelMembersConversions from "./components/PanelMembersConversions";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`revenue-services-tabpanel-${index}`}
			aria-labelledby={`revenue-services-tab-${index}`}
			sx={{ height: "100%" }}
			{...other}
		>
			{value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
		</Box>
	);
}

function MembersOverview() {
	const [value] = React.useState(0);
	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader
							sx={{
								"& .MuiCardHeader-action": {
									width: "100%",
								},
							}}
							// action={
							// 	<Tabs
							// 		value={value}
							// 		onChange={handleChange}
							// 		aria-label="Diagnosis Details Tabs"
							// 		variant="scrollable"
							// 		scrollButtons={false}
							// 		sx={{
							// 			width: "100%",
							// 			"& .MuiTab-root": {
							// 				flex: { xs: "none", sm: "1" },
							// 				whiteSpace: "nowrap",
							// 				maxWidth: "100%",
							// 			},
							// 		}}
							// 	>
							// 		<Tab label="Risk Score" {...a11yProps(0)} />
							// 		<Tab label="Recapture" {...a11yProps(1)} />
							// 		<Tab label="Conversions" {...a11yProps(2)} />
							// 	</Tabs>
							// }
						></CardHeader>
						<CardContent>
							<TabPanel value={value} index={0}>
								<RiskScorePanel />
							</TabPanel>
							<TabPanel value={value} index={1}>
								<PanelMembersRecapture />
							</TabPanel>
							<TabPanel value={value} index={2}>
								<PanelMembersConversions />
							</TabPanel>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export default MembersOverview;
