import { Box, Card, CardContent, CardHeader, Tab, Tabs } from "@mui/material";
import React from "react";
import TopMedicationsPanel from "./TopMedicationsPanel";
import TopNewDiagnosisPanel from "./TopNewDiagnosisPanel";
import TopProductServicePanel from "./TopProductServicePanel";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: Readonly<TabPanelProps>) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`dasboard-overview-tabpanel-${index}`}
			aria-labelledby={`dasboard-overview-tab-${index}`}
			sx={{ height: "100%" }}
			{...other}
		>
			{value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
		</Box>
	);
}

function a11yProps(index: number) {
	return {
		id: `dasboard-overview-tab-${index}`,
		"aria-controls": `dasboard-overview-tabpanel-${index}`,
	};
}

function TopSpendCard() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	return (
		<Card
			elevation={4}
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				minHeight: { xs: "700px", xl: "auto" },
			}}
		>
			<CardHeader title="Top Spend" />
			<CardHeader
				sx={{
					"& .MuiCardHeader-action": {
						width: "100%",
					},
				}}
				action={
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="Dashboard Overview tabs"
						variant="scrollable"
						scrollButtons={false}
						sx={{
							width: "100%",
							"& .MuiTab-root": {
								flex: { xs: "none", md: "1" },
								whiteSpace: "nowrap",
								maxWidth: "none",
							},
						}}
					>
						<Tab label="Top Diagnosis" {...a11yProps(0)} />
						<Tab label="Top Prescriptions" {...a11yProps(1)} />
						<Tab label="Top Procedures" {...a11yProps(2)} />
					</Tabs>
				}
			/>
			<CardContent sx={{ flex: 1 }}>
				<TabPanel value={value} index={0}>
					<TopNewDiagnosisPanel />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<TopMedicationsPanel />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<TopProductServicePanel />
				</TabPanel>
			</CardContent>
		</Card>
	);
}

export default TopSpendCard;
