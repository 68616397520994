import { createContext, useContext, useEffect, useMemo, useState } from "react";

type AppSetting_ContextType = {
	disable_following_items: boolean;
	enable_resend_buttons: boolean;
};

const AppSettings_Context = createContext<AppSetting_ContextType>({ disable_following_items: false, enable_resend_buttons: false });

function Context_AppSettings_Provider(props: { children: React.ReactNode }) {
	const [enableResendButtons, set_enableResendButtons] = useState<boolean>(
		window.location.hostname === "localhost" ? /*/Change this to true for local resend buttons/*/ true /*/ /////*/ : false // In case we forget to turn it off only set to true if localhost in url
	);
	const [disableFollowingItems] = useState<boolean>(false);

	const value = useMemo(() => {
		return { enable_resend_buttons: enableResendButtons, disable_following_items: disableFollowingItems };
	}, [enableResendButtons, disableFollowingItems]);

	useEffect(() => {
		// set the control so we can use it live in environments
		// to toggle in browser console : window.RadiusCare.developer.enableResendButtons(true/false)
		window.RadiusCare.developer.enableResendButtons = (val: boolean) => {
			set_enableResendButtons(val);
		};
	}, [set_enableResendButtons]);

	return (
		//eslint-disable-next-line react/jsx-pascal-case
		<AppSettings_Context.Provider value={value}>{props.children}</AppSettings_Context.Provider>
	);
}

export { AppSettings_Context as AppSettingsContext, Context_AppSettings_Provider as ContextAppSettingsProvider };

const useContextAppSettings = () => {
	return useContext(AppSettings_Context);
};

export { useContextAppSettings };
