import { Card, CardHeader, CardContent, Box } from "@mui/material";
import { palette } from "../../../../theme";
import { ModelsMedicationRangeCacheMonthlyAggregate } from "api";

// Import Swiper React components
import SwiperCore, { Pagination } from "swiper";
import { StandardLineChart } from "charts/line/StandardLineChart";
import { useX_getMedicationDetailsImpact } from "api_extension/useQueryX/cacheMedicationData/useX_getMedicationDetailsImpact";
import displayConverter from "shared/utilities/displayConverter";
import { MedicationTypeApiRequest } from "../MedicationDetailsMain";
SwiperCore.use([Pagination]);

type MedicationDetailsImpactProps = {
	readonly apiRequest: MedicationTypeApiRequest;
	readonly rangeAggregateItem?: ModelsMedicationRangeCacheMonthlyAggregate;
};

function MedicationDetailsImpact(props: MedicationDetailsImpactProps) {
	let apiResult = useX_getMedicationDetailsImpact(props.apiRequest, null);
	const data = apiResult.data?.operationStatus?.data;
	return (
		<Box>
			<Box pt={2} pb={2}>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Monthly Spend vs Average Spend"></CardHeader>
					<CardContent sx={{ "& .recharts-responsive-container": { minHeight: "20rem" } }}>
						{data?.billingGraph?.graph ? (
							<StandardLineChart
								data={data.billingGraph.graph?.map((x) => {
									return {
										value: x.value as string | number,
										value2: x.value2 as string | number,
										name: x.name as string,
									};
								})}
								yKeys={[
									{
										dataKey: "value",
										color: palette.lightBlue.main,
										name: props.rangeAggregateItem?.name ?? "Prescription Name",
									},
									{
										dataKey: "value2",
										color: palette.primary.dark,
										name: "Radius Care Avg",
									},
								]}
								legend
								legendVerticalAlign="top"
								displayYAxis
								legendStyle={{ paddingBottom: "1rem" }}
								tooltipFormat={(value: string) => displayConverter.toUSD(Number(value))}
								yAxisTickFormat={(value: string) => displayConverter.toUSD(Number(value))}
							/>
						) : null}
					</CardContent>
				</Card>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Monthly Fills vs Average Fills"></CardHeader>
					<CardContent sx={{ "& .recharts-responsive-container": { minHeight: "20rem" } }}>
						{data?.fillsGraph?.graph ? (
							<StandardLineChart
								data={data?.fillsGraph.graph?.map((x) => {
									return {
										value: x.value as string | number,
										value2: x.value2 as string | number,
										name: x.name as string,
									};
								})}
								yKeys={[
									{
										dataKey: "value",
										color: palette.lightBlue.main,
										name: props.rangeAggregateItem?.name ?? "Prescription Name",
									},
									{
										dataKey: "value2",
										color: palette.primary.dark,
										name: "Radius Care Avg",
									},
								]}
								legend
								legendVerticalAlign="top"
								displayYAxis
								legendStyle={{ paddingBottom: "1rem" }}
								tooltipFormat={(value: string) => value + (value === "1" ? " fill" : " fills")}
								yAxisTickFormat={(value: string) => value + " fills"}
							/>
						) : null}
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
}

export { MedicationDetailsImpact };
