import { Card, CardHeader, CardContent, Box } from "@mui/material";
import { palette } from "../../../../theme";

// Import Swiper React components
import SwiperCore, { Pagination } from "swiper";
import { StandardLineChart } from "charts/line/StandardLineChart";
import { useX_getProductServiceDetailsImpact } from "api_extension/useQueryX/cacheProductServiceData/useX_getProductServiceDetailsImpact";
import { ProductServiceCacheProductServiceMonthlyAggregate } from "api";
import displayConverter from "shared/utilities/displayConverter";
import { ProductServiceTypeApiRequest } from "../ProductServiceDetailsMain";
SwiperCore.use([Pagination]);

type ProductServiceDetailsImpactProps = {
	readonly apiRequest: ProductServiceTypeApiRequest;
	readonly rangeAggregateItem?: ProductServiceCacheProductServiceMonthlyAggregate;
};

const ProceduresGraphTooltip = (props: any) => {
	let tooltipContent = null;

	if (props.active && props.payload && props.payload.length) {
		tooltipContent = (
			<div className="custom-tooltip" style={{ backgroundColor: "rgba(255, 255, 255, 0.75)", border: "1px solid lightgray", padding: 10 }}>
				{props.payload.map((section: any, index: number) => (
					<p key={section.name} className="desc">
						<span style={{ color: index === 0 ? palette.lightBlue.main : "black" }}>
							{section.name.length > 75 ? section.name.substring(0, 75) + "..." : section.name}: {section.value} procedures
						</span>
					</p>
				))}
			</div>
		);
	}

	return tooltipContent;
};

const BillingGraphTooltip = (props: any) => {
	let tooltipContent = null;

	if (props.active && props.payload && props.payload.length) {
		tooltipContent = (
			<div className="custom-tooltip" style={{ backgroundColor: "rgba(255, 255, 255, 0.75)", border: "1px solid lightgray", padding: 10 }}>
				{props.payload.map((section: any, index: number) => (
					<p key={section.name} className="desc">
						<span style={{ color: index === 0 ? palette.lightBlue.main : "black" }}>
							{section.name.length > 75 ? section.name.substring(0, 75) + "..." : section.name}: {displayConverter.toUSD(section.value)}
						</span>
					</p>
				))}
			</div>
		);
	}

	return tooltipContent;
};

function ProductServiceDetailsImpact(props: ProductServiceDetailsImpactProps) {
	console.log("Dates updated", props.apiRequest);
	let apiResult = useX_getProductServiceDetailsImpact(props.apiRequest, null);
	const data = apiResult.data?.operationStatus?.data;

	return (
		<Box>
			<Box pt={2} pb={2}>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Monthly Spend vs Average Spend"></CardHeader>
					<CardContent sx={{ "& .recharts-responsive-container": { minHeight: "20rem" } }}>
						{data?.billingGraph?.graph ? (
							<StandardLineChart
								data={data.billingGraph.graph?.map((x) => {
									return {
										value: x.value as string | number,
										value2: x.value2 as string | number,
										name: x.name as string,
									};
								})}
								yKeys={[
									{
										dataKey: "value",
										color: palette.lightBlue.main,
										name: props.rangeAggregateItem?.name ?? "Procedure Name",
									},
									{
										dataKey: "value2",
										color: palette.primary.dark,
										name: "Radius Care Avg",
									},
								]}
								legend
								legendVerticalAlign="top"
								displayYAxis
								legendStyle={{ paddingBottom: "1rem" }}
								tooltipFormat={(value: string) => displayConverter.toUSD(Number(value))}
								yAxisTickFormat={(value: string) => displayConverter.toUSD(Number(value))}
								customTooltip={<BillingGraphTooltip />}
							/>
						) : null}
					</CardContent>
				</Card>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Monthly Procedures vs Average Procedures"></CardHeader>
					<CardContent sx={{ "& .recharts-responsive-container": { minHeight: "20rem" } }}>
						{data?.proceduresGraph?.graph ? (
							<StandardLineChart
								data={data?.proceduresGraph.graph?.map((x) => {
									return {
										value: x.value as string | number,
										value2: x.value2 as string | number,
										name: x.name as string,
									};
								})}
								yKeys={[
									{
										dataKey: "value",
										color: palette.lightBlue.main,
										name: props.rangeAggregateItem?.name ?? "Procedure Name",
									},
									{
										dataKey: "value2",
										color: palette.primary.dark,
										name: "Radius Care Avg",
									},
								]}
								legend
								legendVerticalAlign="top"
								displayYAxis
								legendStyle={{ paddingBottom: "1rem" }}
								tooltipFormat={(value: string, label: string) => {
									return [value + (Number(value) === 1 ? " procedure" : " procedures"), "test\ntestess22\brtest"];
								}}
								yAxisTickFormat={(value: string) => value + " procs"}
								customTooltip={<ProceduresGraphTooltip />}
							/>
						) : null}
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
}

export { ProductServiceDetailsImpact };
