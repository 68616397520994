import { Box } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import ValueTile from "../../../shared/components/ValueTile";
import displayConverter from "../../../shared/utilities/displayConverter";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { getRevenueServicesPrescriptions } from "api";
import { AdminGetRevenueServicesPrescriptionsResult } from "api";
import { useRunFilterQuery } from "../../../DataProvider/hooks/useRunFilterQuery";
import { Loader } from "shared/components/Loaders/Loader";

SwiperCore.use([Pagination]);

const columns: GridColumns = [
	{
		field: "name",
		headerName: "Name",
		headerAlign: "left",
		align: "left",
		flex: 1,
	},
	{
		field: "medicationCode",
		headerName: "Code",
		headerAlign: "left",
		align: "left",
		flex: 0.25,
	},
	{
		field: "totalPrescriptions",
		headerName: "Total Prescriptions",
		headerAlign: "right",
		align: "right",
		flex: 0.25,
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.totalPrescriptions),
	},
	{
		field: "averageCost",
		headerName: "Average Cost",
		headerAlign: "right",
		align: "right",
		flex: 0.25,
		valueGetter: (params) => displayConverter.toUSD(params.row.averageCost),
	},
	{
		field: "totalBilling",
		headerName: "Total Billing",
		headerAlign: "right",
		align: "right",
		flex: 0.25,
		valueGetter: (params) => displayConverter.toUSD(params.row.totalBilling),
	},
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Prescriptions"} {...props} />;
}

interface Filters {
	searchQuery?: string;
}

function PanelRevenuePrescriptions() {
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("prescriptions", columns);

	function onSearchEntered(searchString: string) {
		if (query.searchQuery === searchString) {
			return null;
		}
		updateSearchQuery({ searchQuery: searchString });
	}

	const { data } = useRunFilterQuery<AdminGetRevenueServicesPrescriptionsResult>(getRevenueServicesPrescriptions, {
		...query,
	});

	return (
		<Box>
			{data ? (
				<Box
					sx={{
						"& .swiper-container": {
							pb: { xs: 4, md: 0 },
						},
						"& .swiper-pagination-bullets": {
							bottom: "0",
						},
					}}
				>
					<Swiper
						spaceBetween={16}
						slidesPerView={1}
						onSlideChange={() => console.log("slide change")}
						onSwiper={(swiper) => console.log(swiper)}
						pagination={{
							clickable: true,
						}}
						breakpoints={{
							"700": {
								slidesPerView: 1,
							},
							"1200": {
								slidesPerView: 3,
							},
						}}
					>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(data?.prescriptionsModel?.totalPrescriptions)}
								title="Total prescriptions"
								minHeight="7rem"
							/>
						</SwiperSlide>{" "}
						<SwiperSlide>
							<ValueTile value={displayConverter.toUSD(data?.prescriptionsModel?.totalBilling)} title="Total Billing" minHeight="7rem" />
						</SwiperSlide>{" "}
						<SwiperSlide>
							<ValueTile value={displayConverter.toUSD(data?.prescriptionsModel?.averageCost)} title="Avg Prescription Cost" minHeight="7rem" />
						</SwiperSlide>{" "}
					</Swiper>
				</Box>
			) : (
				<Loader />
			)}
			{data ? (
				<Box sx={{ display: "flex", height: "100%" }}>
					<Box
						sx={{
							flexGrow: 1,
						}}
					>
						{data?.prescriptionsModel?.prescriptions ? (
							<DataGridPro
								{...grid}
								columns={columns}
								autoHeight
								getRowId={(row) => row.medicationCode}
								rows={data?.prescriptionsModel?.prescriptions}
								rowCount={data?.prescriptionsModel.totalPrescriptionTypes}
								components={{ Toolbar: Toolbar }}
								componentsProps={{
									toolbar: {
										onSearch: (searchString: string) => {
											onSearchEntered(searchString);
										},
										initialSearch: query.searchQuery ?? "",
									},
								}}
								sx={{ pt: 2 }}
							/>
						) : null}
					</Box>
				</Box>
			) : (
				<Loader />
			)}
		</Box>
	);
}

export default PanelRevenuePrescriptions;
