import { Grid } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";

interface Props {}

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Referral Patterns (NPI to Out of Network)"} {...props} />;
}

const columns: GridColumns = [
	{
		field: "initialVisit",
		headerName: "Initial Visit",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "details",
		headerName: "",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "diagnosis",
		headerName: "Diagnosis",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "treatingDoctor",
		headerName: "Treating Doctor",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
	{
		field: "numberInstance",
		headerName: "Number Instance",
		flex: 1,
		headerAlign: "left",
		align: "left",
	},
];

type Referral = {
	id: number;
	initialVisit: string;
	diagnosis: string;
	treatingDoctor: string;
	numberInstance: number;
};

const referraldata: Array<Referral> = [
	{
		id: 1,
		initialVisit: "Dr. Mark Smith (NPI)",
		diagnosis: "1293.001",
		treatingDoctor: "Dr. Mary Ray (NPI)",
		numberInstance: 14,
	},
	{
		id: 2,
		initialVisit: "Dr. Jay Vida (NPI)",
		diagnosis: "1233.001",
		treatingDoctor: "Dr. Mary Ray (NPI)",
		numberInstance: 15,
	},
	{
		id: 3,
		initialVisit: "Dr. William Beegle (NPI)",
		diagnosis: "4203.001",
		treatingDoctor: "Dr. Mary Ray (NPI)",
		numberInstance: 17,
	},
	{
		id: 4,
		initialVisit: "Dr. Mark Smith (NPI)",
		diagnosis: "1236.001",
		treatingDoctor: "Dr. Mary Ray (NPI)",
		numberInstance: 16,
	},
	{
		id: 5,
		initialVisit: "Dr. William Beegle (NPI)",
		diagnosis: "5233.001",
		treatingDoctor: "Dr. Mary Ray (NPI)",
		numberInstance: 19,
	},
];

function PanelDoctorReferrals(props: Props) {
	return (
		<Box sx={{ width: "100%" }}>
			<Grid item xs={12}>
				<Box sx={{ display: "flex", height: "100%" }}>
					<Box
						sx={{
							flexGrow: 1,
						}}
					>
						<DataGridPro
							columns={columns}
							autoHeight
							rows={referraldata}
							components={{ Toolbar: Toolbar }}
							componentsProps={{
								toolbar: {
									onSearch: {},
								},
							}}
						/>
					</Box>
				</Box>
			</Grid>
		</Box>
	);
}

export default PanelDoctorReferrals;
