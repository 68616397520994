import {
  Box,
  Stack,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import * as CSS from "csstype";
import LinearProgress from "@mui/material/LinearProgress";
import HelpPopover from "shared/alerts/HelpPopover";

interface MembersConversionTileProps {
  title: string;
  percent?: string;
  color: CSS.Property.Color | undefined;
  backgroundColor?: CSS.Property.Color | undefined;
  primaryValue?: string;
  description?: string;
}

function MembersConversionTile(props: MembersConversionTileProps) {
  return (
    <Card
      elevation={0}
      sx={{ backgroundColor: props.backgroundColor || "gray.main" }}
    >
      <CardContent sx={{ position: "relative" }}>
        <Box sx={{ position: "absolute", right: "1rem" }}>
          <HelpPopover
            text={props.description}
            color={"gray.dark"}
            verticalAnchor={"bottom"}
            horizontalAnchor={0}
          />
        </Box>
        <Stack direction="row">
          <Avatar
            sx={{
              fontSize: "1rem",
              color: props.color,
              borderColor: props.color,
            }}
          >
            {props.percent}
          </Avatar>
          <Box sx={{ textAlign: "left", pl: 1 }}>
            <Typography
              variant="h1"
              sx={{ color: props.color, fontWeight: 400 }}
            >
              {props.primaryValue}
            </Typography>
            <Typography
              component="span"
              sx={{
                textTransform: "uppercase",
                fontWeight: 700,
                color: "gray.dark",
              }}
            >
              {props.title}
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            pt: 1,

            "& .MuiLinearProgress-root": {
              height: "1rem",
              borderRadius: "1rem",
              backgroundColor: "gray.light",

              "& .MuiLinearProgress-bar": {
                backgroundColor: props.color,
              },
            },
          }}
        >
          <LinearProgress variant="determinate" value={50} />
        </Box>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          sx={{
            pt: 2,
            width: "100%",
            fontWeight: 700,
            textTransform: "uppercase",
            color: "primary.main",
          }}
        >
          <Button color="secondary">Details</Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default MembersConversionTile;
