import React from "react";
import { Box, Grid, Card, CardHeader, CardContent } from "@mui/material";
import { FacilitiesOverviewList } from "./OverviewPage/FacilitiesOverviewList";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`revenue-services-tabpanel-${index}`}
			aria-labelledby={`revenue-services-tab-${index}`}
			sx={{ height: "100%" }}
			{...other}
		>
			{value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
		</Box>
	);
}

function FacilitiesOverview() {
	const [value] = React.useState(0);
	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader
							sx={{
								"& .MuiCardHeader-action": {
									width: "100%",
								},
							}}
						></CardHeader>
						<CardContent>
							<TabPanel value={value} index={0}>
								<FacilitiesOverviewList />
							</TabPanel>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export default FacilitiesOverview;
