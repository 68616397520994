import React from "react";
import { Box, Grid } from "@mui/material";
import ValueTile from "../../../../shared/components/ValueTile";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import MembersSummaryTile from "./MembersSummaryTile";

SwiperCore.use([Pagination]);

function PanelMembersRecapture() {
	return (
		<Box>
			<Box
				sx={{
					"& .swiper-container": {
						pb: { xs: 4, md: 0 },
					},
					"& .swiper-pagination-bullets": {
						bottom: "0",
					},
				}}
			>
				<Swiper
					spaceBetween={16}
					slidesPerView={1}
					onSlideChange={() => console.log("slide change")}
					onSwiper={(swiper) => console.log(swiper)}
					pagination={{
						clickable: true,
					}}
					breakpoints={{
						"700": {
							slidesPerView: 2,
						},
						"1200": {
							slidesPerView: 3,
						},
					}}
				>
					<SwiperSlide>
						<ValueTile value="$1,290,000" title="Lost Billing Curent Period" minHeight="7rem" />
					</SwiperSlide>

					<SwiperSlide>
						<ValueTile value="$1,000,000" title="Lost Billing Last Period" minHeight="7rem" />
					</SwiperSlide>

					<SwiperSlide>
						<ValueTile value="+10%" title="Lost Billing Period Change" minHeight="7rem" />
					</SwiperSlide>
				</Swiper>
			</Box>
			<Grid container spacing={2} pt={2}>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile
						title={"Visit Billables"}
						primaryValue="1,070,667"
						color={"green.main"}
						description={"Visits with out of network doctors"}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile title={"Procedure Billables"} primaryValue="$35.64" color={"green.main"} description={"Procedure billings"} />
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile title={"Precription Billables"} primaryValue="$1,170,000" color={"green.main"} description={"Medications prescribed"} />
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile
						title={"No Follow Up Visit"}
						primaryValue="73%"
						color={"green.main"}
						description={"Percentage of people without follow up"}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile title={"Imaging"} primaryValue="38%" color={"green.main"} description={"Had imaging study outside of network"} />
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile
						title={"Post Acute Care"}
						primaryValue="1,070,667"
						color={"green.main"}
						description={"ost Acute Services out of Network"}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile
						title={"Occupational Health"}
						primaryValue="$45.00"
						color={"green.main"}
						description={"Occupational Health out of Network"}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile title={"Rehabilitation"} primaryValue="$2,450,000" color={"green.main"} description={"Rehabilitation out of Network"} />
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile title={"Sleep Center"} primaryValue="64%" color={"green.main"} description={"Sleep Studies out of Network"} />
				</Grid>
				<Grid item xs={12} md={6} lg={3}>
					<MembersSummaryTile title={"Dialysis"} primaryValue="45%" color={"green.main"} description={"Dialysis services out of Network"} />
				</Grid>
			</Grid>
		</Box>
	);
}

export default PanelMembersRecapture;
