import { Button } from "@mui/material";
import { Link } from "react-router-dom";

type ViewEntityDetailButtonProps = {
	itemType: UserFollowedItemTypeNames;
	code: string;
	forceCacheItemDetails?: boolean;
};

const ViewEntityDetailButton = ({ itemType, code, forceCacheItemDetails }: ViewEntityDetailButtonProps) => {
	return (
		<Link to={`/itemDetails?code=${code}&itemType=${itemType}${forceCacheItemDetails === true ? "&forceCacheItemDetails=true" : ""}`}>
			<Button size="small" color="secondary">
				Details
			</Button>
		</Link>
	);
};

export { ViewEntityDetailButton };
