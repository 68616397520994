import { Box } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import ValueTile from "../../../shared/components/ValueTile";
import displayConverter from "../../../shared/utilities/displayConverter";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { getRevenueServicesProcedures } from "api";
import { AdminGetRevenueServicesProceduresResult } from "api";
import { useRunFilterQuery } from "../../../DataProvider/hooks/useRunFilterQuery";
import { Loader } from "shared/components/Loaders/Loader";

SwiperCore.use([Pagination]);

const columns: GridColumns = [
	{
		field: "procedureName",
		headerName: "Procedure Name",
		headerAlign: "left",
		align: "left",
		flex: 1,
	},
	{
		field: "cptCode",
		headerName: "CPT Code",
		headerAlign: "left",
		align: "left",
		flex: 1,
	},
	{
		field: "numberOfProcedures",
		headerName: "Total Procedures",
		headerAlign: "right",
		align: "right",
		flex: 0.5,
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.numberOfProcedures),
	},
	{
		field: "totalBilling",
		headerName: "Total Billing",
		headerAlign: "right",
		align: "right",
		flex: 0.5,
		valueGetter: (params) => displayConverter.toUSD(Number(params.row.totalBilling)),
	},
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Procedures List"} {...props} />;
}

interface Filters {
	searchQuery?: string;
}

function PanelRevenueProcedures() {
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("Procedures", columns);

	function onSearchEntered(searchString: string) {
		if (query.searchQuery === searchString) {
			return null;
		}
		updateSearchQuery({ searchQuery: searchString });
	}

	const { data } = useRunFilterQuery<AdminGetRevenueServicesProceduresResult>(getRevenueServicesProcedures, {
		...query,
	});

	return (
		<Box>
			{data ? (
				<Box
					sx={{
						"& .swiper-container": {
							pb: { xs: 4, md: 0 },
						},
						"& .swiper-pagination-bullets": {
							bottom: "0",
						},
					}}
				>
					<Swiper
						spaceBetween={16}
						slidesPerView={1}
						onSlideChange={() => console.log("slide change")}
						onSwiper={(swiper) => console.log(swiper)}
						pagination={{
							clickable: true,
						}}
						breakpoints={{
							"700": {
								slidesPerView: 1,
							},
							"1200": {
								slidesPerView: 3,
							},
						}}
					>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(data?.proceduresModel?.totalProcedures)}
								title="Total procedures"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.toUSD(Number(data?.proceduresModel?.totalBillAmount))} title="Total Billing" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.toUSD(data?.proceduresModel?.averageCost)} title="Avg Procedure Cost" minHeight="7rem" />
						</SwiperSlide>
						{/* <SwiperSlide>
            <Card
              elevation={0}
              sx={{
                backgroundColor: "gray.main",
                minHeight: "7rem",
              }}
            >
              <CardContent>
                {fakeData.data?.chart_data ? (
                  <StandardLineChart
                    yKeys={[{ dataKey: "tm" }]}
                    data={fakeData.data.chart_data}
                    responsiveHeight={80}
                  />
                ) : null}
              </CardContent>
            </Card>
          </SwiperSlide> */}
					</Swiper>
				</Box>
			) : (
				<Loader />
			)}
			{data ? (
				<Box sx={{ display: "flex", height: "100%" }}>
					<Box
						sx={{
							flexGrow: 1,
						}}
					>
						{data?.proceduresModel?.procedures ? (
							<DataGridPro
								{...grid}
								getRowId={(row) => row.cptCode}
								columns={columns}
								autoHeight
								rows={data?.proceduresModel?.procedures}
								rowCount={data?.proceduresModel.totalProcedureTypes}
								components={{ Toolbar: Toolbar }}
								componentsProps={{
									toolbar: {
										onSearch: (searchString: string) => {
											onSearchEntered(searchString);
										},
										initialSearch: query.searchQuery ?? "",
									},
								}}
								sx={{ pt: 2 }}
							/>
						) : null}
					</Box>
				</Box>
			) : (
				<Loader />
			)}
		</Box>
	);
}

export default PanelRevenueProcedures;
