import React, { useMemo } from "react";
import { Box, Card, CardContent, Tabs } from "@mui/material";
import { TabsUtilities } from "shared/components/Tabs/TabsUtilities";
import { DoctorDetailsOverview } from "./DoctorDetailsOverview";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { RequestModelsCacheMonthlyQueryRequest } from "api";
import { useX_getCacheMonthlyDetail } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyDetail";
import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { DoctorDetailsServices } from "./DoctorDetailsServices";

const tabs = ["Overview", "Rendered Services"];

const { TabControl, TabPanel } = TabsUtilities.create_TabRenderFunctions("DoctorDetailsMain");
function DoctorDetailsMain({ npi }: { npi: string }) {
	const [value, setValue] = React.useState(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const { values: pfValues } = usePrimaryFilters();
	const queryRequest: RequestModelsCacheMonthlyQueryRequest | null = useMemo(() => {
		if (npi) {
			return {
				jobIds: [...pfValues.Members],
				codes: [npi],
				itemType: "PROVIDER",
				includeLookup: true,
			};
		}
		return null;
	}, [pfValues.Members, npi]);

	const detailsResult = useX_getCacheMonthlyDetail(queryRequest);

	return (
		<Box sx={{ width: "100%" }}>
			<Card elevation={4}>
				<CardContent
					sx={{
						pt: 0,
						padding: 2,
					}}
				>
					<QueryStatusDisplay queryResult={detailsResult}>
						{detailsResult.data?.response ? (
							<>
								<Box sx={{ pb: 2, pt: 1 }}>
									<Tabs
										value={value}
										onChange={handleChange}
										aria-label="Doctor Details Tabs"
										variant="fullWidth"
										sx={{
											width: "100%",
										}}
									>
										{tabs.map((label, value) => {
											return <TabControl key={label} label={label} value={value} />;
										})}
									</Tabs>
								</Box>
								<TabPanel value={value} index={0}>
									{npi !== null ? <DoctorDetailsOverview npi={npi} detailsData={detailsResult.data.response} /> : null}
								</TabPanel>
								<TabPanel value={value} index={1}>
									{npi !== null ? <DoctorDetailsServices npi={npi} detailsData={detailsResult.data.response} /> : null}
								</TabPanel>
							</>
						) : null}
					</QueryStatusDisplay>
				</CardContent>
			</Card>
		</Box>
	);
}

export { DoctorDetailsMain };
