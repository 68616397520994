import { Grid, Card, CardHeader, CardContent } from "@mui/material";
import { StandardPieChart } from "../charts/pie/StandardPieChart";
import StandardBarChart from "../charts/bar/StandardBarChart";
import { DiagnosisNotificationDetails } from "./hooks/useDiagnosisNotificationDetails";
import { palette } from "../theme";
import ParametersItem from "./components/ParametersItem";

type PanelDiagnosisNotificationOverviewProps = {
  data?: DiagnosisNotificationDetails;
};
function PanelDiagnosisNotificationOverview(
  props: PanelDiagnosisNotificationOverviewProps
) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={5} lg={4}>
        <Card
          elevation={0}
          sx={{ backgroundColor: "gray.main", height: "100%" }}
        >
          <CardHeader title="Parameters"></CardHeader>
          <CardContent>
            <ParametersItem
              statLabel="Type"
              statValue="Recommendation"
              statValueColor={palette.purple.main}
            />
            <ParametersItem
              statLabel="Delivery Method"
              statValue="Alert, Email, Text"
              statValueColor={palette.purple.main}
            />
            <ParametersItem
              statLabel="Diagnosis"
              statValue="COPD"
              statValueColor={palette.purple.main}
            />
            <ParametersItem
              statLabel="Stage"
              statValue="Diagnosed"
              statValueColor={palette.purple.main}
            />
            <ParametersItem
              statLabel="Risk Score"
              statValue="Serious, No Visit"
              statValueColor={palette.purple.main}
            />
            <ParametersItem
              statLabel="Message"
              statValue="Promoting highly rated doctors in your area"
              statValueColor={palette.purple.main}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
          <CardHeader title="Gender"></CardHeader>
          <CardContent>
            {props.data?.gender_data ? (
              <StandardPieChart
                data={props.data?.gender_data}
                colors={[palette.green.main, palette.purple.main]}
                showLegend
                innerRadius={32}
                responsiveHeight={300}
              />
            ) : null}
          </CardContent>
        </Card>

        <Card elevation={0} sx={{ backgroundColor: "gray.main" }}>
          <CardHeader title="Age"></CardHeader>
          <CardContent>
            {props.data?.age_data ? (
              <StandardBarChart
                data={props.data.age_data}
                dataKey="name"
                responsiveHeight={300}
                bar_definitions={[
                  {
                    dataKey: "female",
                    name: "Female",
                    fill: palette.purple.main,
                    legendType: "circle",
                  },
                  {
                    dataKey: "male",
                    name: "Male",
                    fill: palette.green.main,
                    legendType: "circle",
                  },
                ]}
              />
            ) : null}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default PanelDiagnosisNotificationOverview;
