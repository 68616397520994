import React from "react";
import { Box, Grid, Card, CardHeader, CardContent, Tab, Tabs } from "@mui/material";
import PanelRevenueProcedures from "./components/PanelRevenueProcedures";
import PanelRevenueAppointments from "./components/PanelRevenueAppointments";
import PanelRevenuePrescriptions from "./components/PanelRevenuePrescriptions";
interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`revenue-services-tabpanel-${index}`}
			aria-labelledby={`revenue-services-tab-${index}`}
			sx={{ height: "100%" }}
			{...other}
		>
			{value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
		</Box>
	);
}

function a11yProps(index: number) {
	return {
		id: `revenue-services-tab-${index}`,
		"aria-controls": `revenue-services-tabpanel-${index}`,
	};
}

function RevenueServices() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader
							sx={{
								"& .MuiCardHeader-action": {
									width: "100%",
								},
							}}
							action={
								<Tabs
									value={value}
									onChange={handleChange}
									aria-label="Revenue Service Tabs"
									variant="scrollable"
									scrollButtons={false}
									sx={{
										width: "100%",
										"& .MuiTab-root": {
											flex: { xs: "none", md: "1" },
											whiteSpace: "nowrap",
											maxWidth: "100%",
										},
									}}
								>
									<Tab label="Visits" {...a11yProps(1)} />
									<Tab label="Procedures" {...a11yProps(0)} />

									<Tab label="Prescriptions" {...a11yProps(2)} />
								</Tabs>
							}
						></CardHeader>
						<CardContent>
							<TabPanel value={value} index={0}>
								<PanelRevenueAppointments />
							</TabPanel>
							<TabPanel value={value} index={1}>
								<PanelRevenueProcedures />
							</TabPanel>

							<TabPanel value={value} index={2}>
								<PanelRevenuePrescriptions />
							</TabPanel>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export default RevenueServices;
