import { useFilterQuery } from "DataProvider/hooks/useRunFilterQuery";
import { CacheMonthlyDataGetCacheMonthlyTotalsQuery, RequestModelsCacheMonthlyQueryRequest, getCacheMonthlyTotals } from "api";
import { useQuery } from "react-query";

const useX_getCacheMonthlyTotals = (request_: RequestModelsCacheMonthlyQueryRequest | null, itemTypes?: string[]) => {
	let r = { ...useFilterQuery(), ...request_ };
	let cacheMonthlyRequest: RequestModelsCacheMonthlyQueryRequest = {
		dateRangeRequest: {
			startDate: r.startDate,
			endDate: r.endDate,
		},
		jobIds: r.memberTypes,
		codes: r.codes,
		nestedCodes: r.nestedCodes,
		paginationRequest: r.paginationRequest,
		itemType: r.itemType,
		includeLookup: r.includeLookup,
	};

	let request: CacheMonthlyDataGetCacheMonthlyTotalsQuery = {
		queryRequest: cacheMonthlyRequest,
		itemTypes: itemTypes,
	};

	let cacheKeys = ["getCacheMonthlyTotals", "getCacheMonthlyTotals_" + JSON.stringify(request)];
	return useQuery(cacheKeys, getCacheMonthlyTotals.bind(null, request), {
		cacheTime: Infinity,
		staleTime: Infinity,
	});
};

export { useX_getCacheMonthlyTotals };
