import { Box } from "@mui/material";
import { GridColumns, GridEnrichedColDef } from "@mui/x-data-grid-pro";
import { ModelsLookUpDictionaries } from "api";
import displayConverter from "shared/utilities/displayConverter";
import { DataGridColumnsOverRide, DataGridStyles } from "../DataGridColumns";
import { RowDisplayTooltipForEntityType } from "shared/components/DisplayElements/DisplayForType/RowDisplayTooltipForEntityType";

const ColumnsConstruct_CacheNestedItems = (type: CacheMonthlyTypes, lookupDictionaries?: ModelsLookUpDictionaries) => {
	let columns: GridEnrichedColDef[] = GRIDCOLUMNS_CACHENESTEDITEMS(type, lookupDictionaries);

	if (type === "DIAGNOSIS") {
		columns = DataGridColumnsOverRide(columns, [{ field: "code", headerName: "Diagnosis" }]);
	} else if (type === "MEDICATION") {
		columns = DataGridColumnsOverRide(columns, [{ field: "code", headerName: "Prescription" }]);
	} else if (type === "PRODUCTSERVICE") {
		columns = DataGridColumnsOverRide(columns, [{ field: "code", headerName: "Procedure" }]);
	}
	return columns;
};

const GRIDCOLUMNS_CACHENESTEDITEMS = (type: CacheMonthlyTypes, lookupDictionaries?: ModelsLookUpDictionaries): GridColumns => {
	const showEobCounts = ["PATIENT_EOB"].includes(type) ? false : true;
	const showCosts = ["PATIENT_EOB"].includes(type) ? false : true;
	console.debug({ type, showEobCounts });
	let columns: GridColumns = [
		{
			field: "code",
			headerName: "Item",
			flex: 1,
			headerAlign: "left",
			minWidth: 300,
			align: "left",
			sortable: false,
			disableColumnMenu: true,
			renderCell: (params) => {
				let code = params.row.code;
				if (lookupDictionaries) {
					// let lookupItem = GetLookupItemForCode(code, type, lookupDictionaries);
					return (
						<DataGridStyles.ScrollableCellContainer irony={false}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",
									width: "100%",
								}}
							>
								<RowDisplayTooltipForEntityType
									itemType={type}
									codeCount={{ code: code, cost: params.row.cost, count: params.row.count }}
									lookupDictionaries={lookupDictionaries}
									showEobCounts={showEobCounts}
									showCosts={showCosts}
									useSeparater={false}
								/>
							</Box>
						</DataGridStyles.ScrollableCellContainer>
					);
				}
				return null;
			},
		},
		{
			field: "count",
			headerName: "Count",
			flex: 1,
			headerAlign: "left",
			align: "left",
			sortable: true,
			// disableColumnMenu: true,
			renderCell: (params) => {
				return displayConverter.insertThousandsCommas(params.value);
			},
		},
		{
			field: "cost",
			headerName: "Cost",
			flex: 1,
			headerAlign: "left",
			align: "left",
			renderCell: (params) => {
				return displayConverter.toUSD(params.value);
			},
		},
		{
			field: "avgcost",
			headerName: "AVG Cost",
			flex: 1,
			headerAlign: "left",
			align: "left",
			renderCell: (params) => {
				return displayConverter.toUSD(params.value);
			},
		},
	];
	return columns.filter((x) => x !== null) as GridColumns;
};

export { GRIDCOLUMNS_CACHENESTEDITEMS, ColumnsConstruct_CacheNestedItems };
