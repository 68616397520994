import { Box, Button, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { AdminGetAllDiagnosesResult, ModelsConditionRecord, getAllDiagnosis } from "api";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";
import { useRunFilterQuery } from "../../DataProvider/hooks/useRunFilterQuery";
import displayConverter from "shared/utilities/displayConverter";
import { useNavigate } from "react-router-dom";
import { useDiagnosisFilters } from "DataProvider/contexts/Context_DiagnosisFilters_Provider";
import SimpleTooltipColumn from "shared/components/grid/GridColumn/SimpleTooltipColumn";

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Diagnosis List"} {...props} />;
}

interface Filters {
	searchQuery?: string;
}

function TopNewDiagnosisPanel() {
	const navigate = useNavigate();
	const diagnosisFilters = useDiagnosisFilters();
	const columns: GridColumns = [
		{
			field: "rank",
			headerName: "Rank",
			headerAlign: "left",
			align: "left",
			sortable: false,
			minWidth: 40,
			flex: 0.4,
		},
		{
			field: "diagnosis",
			headerName: "Name",
			flex: 2.1,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (value) => {
				let x = value.row;
				return (
					<SimpleTooltipColumn
						code={x.code}
						name={x.title}
						tooltipTitle={
							<Box>
								<Typography>{displayConverter.periodizeIcdCode(x.code)}</Typography>
								<Typography>{x.codeName}</Typography>
							</Box>
						}
						followItemType="DIAGNOSIS"
					/>
				);
			},
		},
		{
			field: "totalUsers",
			headerName: "Total Diagnoses",
			flex: 0.8,
			headerAlign: "left",
			align: "left",
			valueFormatter(row) {
				return displayConverter.insertThousandsCommas(row.value?.toString());
			},
			sortable: false,
			minWidth: 80,
		},
		{
			field: "totalSpend",
			headerName: "Total Spend",
			flex: 1.4,
			headerAlign: "left",
			align: "left",
			valueGetter: (params) => displayConverter.toUSD(Number(params.value.toString())),
			sortable: false,
			minWidth: 40,
		},
		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			width: 128,
			sortable: false,
			renderCell: ({ row }) => (
				<Button
					onClick={() => {
						let subRange: ModelsConditionRecord = {
							id: row.subLevelRange.range,
							range: row.subLevelRange.range,
							name: row.subLevelRange.description,
							parentRange: row.subLevelRange.parent_range,
							simpleRange: row.subLevelRange.simple_range,
						};
						let update: any = {};
						update[row.topLevelRange.range as string] = subRange;

						let icdUpdate: any = {};
						let icdValue: any = {};
						icdValue.range = row.code;
						icdValue.code = row.code;
						icdValue.description = row.codeName;
						icdValue.title = row.title;
						icdUpdate[row.topLevelRange.range as string] = icdValue;
						diagnosisFilters.setValues({ SubRangeByCondition: update, ICD10CodesByCondition: icdUpdate });

						navigate(`/diagnosis/details/${row.topLevelRange.range}`);
					}}
					color="secondary"
				>
					Details
				</Button>
			),
		},
	];
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("doctors", columns);
	function onSearchEntered(searchString: string) {
		if (query.searchQuery === searchString) {
			return null;
		}
		updateSearchQuery({ searchQuery: searchString });
	}
	const { data } = useRunFilterQuery<AdminGetAllDiagnosesResult>(getAllDiagnosis, {
		...query,
	});

	return (
		<Box>
			<Box sx={{ display: "flex", height: "100%" }}>
				<Box
					sx={{
						flexGrow: 1,
						"& .MuiDataGrid-footerContainer": { display: "none" },
						"& .MuiDataGrid-toolbarContainer": { display: "none" },
					}}
				>
					{data?.items ? (
						<DataGridPro
							{...grid}
							getRowId={(row) => row.rank}
							rowsPerPageOptions={[10]}
							rowCount={10}
							columns={columns}
							autoHeight
							rows={data?.items}
							components={{ Toolbar: Toolbar }}
							componentsProps={{
								toolbar: {
									onSearch: (searchString: string) => {
										onSearchEntered(searchString);
									},
									initialSearch: query.searchQuery ?? "",
								},
							}}
						/>
					) : null}
				</Box>
			</Box>
		</Box>
	);
}
export default TopNewDiagnosisPanel;
