import { Box } from "@mui/material";

import { FacilitiesOverviewHeaderTiles } from "./FacilitiesOverviewHeaderTiles";
import { CacheMonthlyListDisplay } from "sections/followed_items/CacheMonthlyListDisplay";
import { DataGridColumns } from "shared/utilities/DataGridColumns/DataGridColumns";
import { useState } from "react";
import { DoctorSelect } from "sections/item_details/doctor/DoctorSelect";
import { RequestModelsCacheMonthlyQueryRequest } from "api";
import { useX_getCacheMonthlyTotals } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyTotals";

const columns_cmd = DataGridColumns.CacheMonthly("ORGANIZATION");

function FacilitiesOverviewList() {
	const request: RequestModelsCacheMonthlyQueryRequest = { itemType: "ORGANIZATION" };
	const data = useX_getCacheMonthlyTotals(request, ["FACILITY", "PATIENT_EOB", "MEDICATION", "PRODUCTSERVICE"]).data?.response;
	let [selected, setSelected] = useState<string | null>(null);
	return (
		<Box>
			<FacilitiesOverviewHeaderTiles overview_data={data ?? null} />
			<CacheMonthlyListDisplay
				itemType={"ORGANIZATION"}
				titleText="Facilities List"
				columns={columns_cmd}
				useSortBarSelect={true}
				searchBar={<DoctorSelect enumType={2} styles={{ width: 30 }} setSelectValue={setSelected} />}
				query={selected}
			/>
		</Box>
	);
}

export { FacilitiesOverviewList };
