import React from "react";
import { Box, Stack, Typography, Button, Card, CardContent } from "@mui/material";
import { MemberNotification } from "../sections/diagnosis/hooks/diagnosisDetails/useDiagnosisDetailsMemberNotifications";
import displayConverter from "../shared/utilities/displayConverter";
import FullWidthDialog from "shared/Dialog/FullWidthDialog";
import DiagnosisNotificationDetails from "./DiagnosisNotificationDetails";

type DiagnosisNotificationSummaryProps = {
	memberNotification: MemberNotification;
};

function DiagnosisNotificationSummary({ memberNotification }: DiagnosisNotificationSummaryProps) {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Box sx={{ py: 2, borderBottom: "1px solid", borderColor: "gray.dark" }}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 2 }}>
				<Box>
					<Typography variant="h4">{memberNotification.title}</Typography>
					<Typography
						component="span"
						variant="subtitle2"
						sx={{
							textTransform: "uppercase",
							fontWeight: 700,
							color: "gray.dark",
						}}
					>
						Date:
						{
							" " +
								memberNotification.date.split("/").splice(1, 2).join("/") +
								"/" +
								memberNotification.date.split("/")[0] /*todo - temporary for display of demo data*/
						}
					</Typography>
				</Box>
				<Box>
					<Button onClick={handleOpen} color="secondary">
						Details
					</Button>
				</Box>
			</Stack>

			<Stack direction="row">
				<Card
					elevation={0}
					sx={{
						backgroundColor: "gray.main",
						borderRadius: "1rem",
						flex: "1",
						mr: 0.5,
					}}
				>
					<CardContent sx={{ textAlign: "center" }}>
						<Typography variant="h4" sx={{ color: "lightBlue.main", fontWeight: 400 }}>
							{displayConverter.insertThousandsCommas(memberNotification.recipients)}
						</Typography>
						<Typography
							component="span"
							variant="subtitle2"
							sx={{
								textTransform: "uppercase",
								fontWeight: 700,
								color: "gray.dark",
							}}
						>
							Recipients
						</Typography>
					</CardContent>
				</Card>
				<Card
					elevation={0}
					sx={{
						backgroundColor: "gray.main",
						borderRadius: "1rem",
						flex: "1",
						ml: 0.5,
					}}
				>
					<CardContent sx={{ textAlign: "center" }}>
						<Typography variant="h4" sx={{ color: "secondary.main", fontWeight: 400 }}>
							{displayConverter.toPercent(memberNotification.success_rate)}
						</Typography>
						<Typography
							component="span"
							variant="subtitle2"
							sx={{
								textTransform: "uppercase",
								fontWeight: 700,
								color: "gray.dark",
							}}
						>
							Success Rate
						</Typography>
					</CardContent>
				</Card>
			</Stack>
			<FullWidthDialog
				title={"Notification Detail"}
				open={open}
				handleClose={handleClose}
				component={<DiagnosisNotificationDetails notificationID={memberNotification.notificationID} />}
			/>
		</Box>
	);
}

export default DiagnosisNotificationSummary;
