import { Box } from "@mui/material";
import { GridColumns, GridEnrichedColDef } from "@mui/x-data-grid-pro";
import { ModelsCodeCountLookup, ModelsLookUpDictionaries, ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts } from "api";
import displayConverter from "shared/utilities/displayConverter";
import { DataGridColumnsDelete, DataGridStyles } from "../DataGridColumns";

import { DateConverter } from "shared/utilities/DateConverter";

import { RowDisplayTooltipForEntityType } from "shared/components/DisplayElements/DisplayForType/RowDisplayTooltipForEntityType";
import { GetLookupItemForCode } from "shared/components/DisplayElements/DisplayForType/RowDisplayForEntityType";

const ColumnConstruct_CacheMonthlyDetails = (type: CacheMonthlyTypes, lookupDictionaries?: ModelsLookUpDictionaries) => {
	let columns: GridEnrichedColDef[] = GRIDCOLUMNS_CACHEMONTHLYDETAILS(type, lookupDictionaries);

	columns = DataGridColumnsDelete(columns, ["productservice_counts.count", "medication_counts.count", "diagnosis_counts.count"]);

	if (type === "DIAGNOSIS_RANGE") {
		columns = DataGridColumnsDelete(columns, ["provider"]);
		columns = DataGridColumnsDelete(columns, ["organization"]);
	}

	if (type === "ORGANIZATION") {
		columns = DataGridColumnsDelete(columns, ["organization"]);
	}

	if (type === "PROVIDER") {
		columns = DataGridColumnsDelete(columns, ["provider"]);
	}

	return columns;
};

const GRIDCOLUMNS_CACHEMONTHLYDETAILS = (type: CacheMonthlyTypes, lookupDictionaries?: ModelsLookUpDictionaries): GridColumns => {
	const showEobCounts = ["PATIENT_EOB"].includes(type) ? false : true;
	const showCosts = ["PATIENT_EOB"].includes(type) ? false : true;

	let columns: GridColumns = [
		{
			field: "periodDate",
			headerName: "Date",
			flex: 1,
			headerAlign: "left",
			align: "left",
			renderCell: (value) => {
				let x = value.row as ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts;
				return (
					<Box display={"flex"} flexDirection={"column"} style={{ padding: "2rem 0px" }}>
						{x.periodDate ? DateConverter.stringDateForAPI(x.periodDate) : null}
					</Box>
				);
			},
		},
		showEobCounts
			? {
					field: "eobCount",
					headerName: "EOB Count",
					flex: 0.5,
					headerAlign: "left",
					align: "left",
					valueFormatter: (params) => displayConverter.insertThousandsCommas(params?.value ? Number(params?.value) : 0),
			  }
			: (null as CIM),
		{
			field: "totalAmount",
			headerName: "Cost",
			flex: 0.5,
			headerAlign: "left",
			align: "left",
			valueFormatter: (params) => displayConverter.toUSD(params?.value ? Number(params?.value) : 0),
		},
		{
			field: "diagnosis",
			headerName: "Diagnosis",
			flex: 2.1,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (value) => {
				let x = value.row as ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts;
				if (!x.diagnosis?.codeCounts) {
					return <></>;
				}
				return (
					<DataGridStyles.ScrollableCellContainer>
						{x.diagnosis.codeCounts.map((d, x, arr) => {
							let lookUpItem = d.code && lookupDictionaries ? GetLookupItemForCode(d.code, "DIAGNOSIS", lookupDictionaries) : undefined;
							return (
								<RowDisplayTooltipForEntityType
									key={d.code}
									itemType={"DIAGNOSIS"}
									codeCount={d}
									lookUpItem={lookUpItem}
									lookupDictionaries={lookupDictionaries}
									showEobCounts={showEobCounts}
									showCosts={showCosts}
									useSeparater={true}
									first={x === 0}
									last={x === arr.length - 1}
								/>
							);
						})}
					</DataGridStyles.ScrollableCellContainer>
				);
			},
		},
		{
			field: "medication",
			headerName: "Medications",
			flex: 2.1,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (value) => {
				let x = value.row as ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts;
				if (!x.medication?.codeCounts) {
					return <></>;
				}
				return (
					<DataGridStyles.ScrollableCellContainer>
						{x.medication?.codeCounts?.map((d, x, arr) => {
							let lookUpItem = d.code && lookupDictionaries ? GetLookupItemForCode(d.code, "MEDICATION", lookupDictionaries) : undefined;
							return (
								<RowDisplayTooltipForEntityType
									key={d.code}
									itemType={"MEDICATION"}
									codeCount={d}
									lookUpItem={lookUpItem}
									lookupDictionaries={lookupDictionaries}
									showEobCounts={showEobCounts}
									showCosts={showCosts}
									useSeparater={true}
									first={x === 0}
									last={x === arr.length - 1}
								/>
							);
						})}
					</DataGridStyles.ScrollableCellContainer>
				);
			},
		},
		{
			field: "productservice",
			headerName: "Procedures",
			flex: 2.1,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (value) => {
				let x = value.row as ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts;
				if (!x.productservice?.codeCounts) {
					return <></>;
				}
				return (
					<DataGridStyles.ScrollableCellContainer>
						{x.productservice?.codeCounts?.map((d, x, arr) => {
							return (
								<RowDisplayTooltipForEntityType
									key={d.code}
									itemType={"PRODUCTSERVICE"}
									codeCount={d}
									lookupDictionaries={lookupDictionaries}
									showEobCounts={showEobCounts}
									showCosts={showCosts}
									useSeparater={true}
									first={x === 0}
									last={x === arr.length - 1}
								/>
							);
						})}
					</DataGridStyles.ScrollableCellContainer>
				);
			},
		},
		{
			field: "provider",
			headerName: "Provider",
			flex: 2.1,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (value) => {
				let x = value.row as ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts;
				if (x.provider?.codeCounts) {
					return (
						<DataGridStyles.ScrollableCellContainer>
							{x.provider?.codeCounts?.map((d: ModelsCodeCountLookup, x, arr) => {
								let lookUpItem = d.code && lookupDictionaries ? GetLookupItemForCode(d.code, "PROVIDER", lookupDictionaries) : undefined;
								if (lookUpItem?.provider?.entityTypeCode === "2" && d.code && lookupDictionaries) {
									lookUpItem = GetLookupItemForCode(d.code, "ORGANIZATION", lookupDictionaries);
								}
								const itemType: CacheMonthlyTypes = lookUpItem?.provider?.entityTypeCode === "1" ? "PROVIDER" : "ORGANIZATION";
								return (
									<RowDisplayTooltipForEntityType
										key={d.code}
										itemType={itemType}
										codeCount={d}
										lookUpItem={lookUpItem}
										lookupDictionaries={lookupDictionaries}
										showEobCounts={showEobCounts}
										showCosts={showCosts}
										useSeparater={true}
										first={x === 0}
										last={x === arr.length - 1}
									/>
								);
							})}
						</DataGridStyles.ScrollableCellContainer>
					);
				}
			},
		},
		{
			field: "organization",
			headerName: "Organization",
			flex: 2.1,
			headerAlign: "left",
			align: "left",
			sortable: false,
			renderCell: (value) => {
				let x = value.row as ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts;
				if (!x.facility?.codeCounts) {
					return <></>;
				}
				return (
					<DataGridStyles.ScrollableCellContainer>
						{x.facility?.codeCounts?.map((d: ModelsCodeCountLookup, x, arr) => {
							let lookUpItem = d.code && lookupDictionaries ? GetLookupItemForCode(d.code, "ORGANIZATION", lookupDictionaries) : undefined;
							return (
								<RowDisplayTooltipForEntityType
									key={d.code}
									itemType={"ORGANIZATION"}
									codeCount={d}
									lookUpItem={lookUpItem}
									lookupDictionaries={lookupDictionaries}
									showEobCounts={showEobCounts}
									showCosts={showCosts}
									useSeparater={true}
									first={x === 0}
									last={x === arr.length - 1}
								/>
							);
						})}
					</DataGridStyles.ScrollableCellContainer>
				);
			},
		},
	];

	return columns.filter((x) => x !== null) as GridColumns;
};

export { GRIDCOLUMNS_CACHEMONTHLYDETAILS, ColumnConstruct_CacheMonthlyDetails };
