import React from "react";
import { Box, Grid } from "@mui/material";
import MembersConversionTile from "./MembersConversionTile";

function PanelMembersConversions() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Laboratory"
            primaryValue="569,160"
            color="green.main"
            description="Laboratory"
            percent="80%"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Medication Delivery"
            primaryValue="678,100"
            color="green.main"
            description="Medication Delivery"
            percent="65%"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Preventative Care"
            primaryValue="567,456"
            color="orange.main"
            description="Preventative Care"
            percent="35%"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="No Follow Up Visits"
            primaryValue="678,921"
            color="red.main"
            description="No Follow Up Visits"
            percent="15%"
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Occupational Health"
            primaryValue="789,456"
            color="green.main"
            description="Occupational Health"
            percent="80%"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Post Acute Care"
            primaryValue="789,234"
            color="green.main"
            description="Post Acute Care"
            percent="65%"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Home Care"
            primaryValue="678,345"
            color="orange.main"
            description="Home Care"
            percent="35%"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Sleep CentersHealth"
            primaryValue="678,934"
            color="red.main"
            description="Sleep CentersHealth"
            percent="15%"
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Behavioral Health"
            primaryValue="678,456"
            color="green.main"
            description="Behavioral Health"
            percent="80%"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Dialysis"
            primaryValue="976,567"
            color="green.main"
            description="Dialysis"
            percent="65%"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Post Acute Care"
            primaryValue="535,120"
            color="orange.main"
            description="Post Acute Care"
            percent="35%"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MembersConversionTile
            title="Imaging"
            primaryValue="789,456"
            color="red.main"
            description="Imaging"
            percent="15%"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PanelMembersConversions;
