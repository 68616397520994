import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import {
	AdminCalculateAndCachePatientRiskModelScoresResult,
	calculateAndCachePatientRiskModelScores,
	createRiskModelScoresOverview,
	deletePatientRiskModelScores,
	getRiskModelScoresCacheInfo,
} from "api";
import { useContext, useEffect, useMemo, useState } from "react";
import { Loader } from "shared/components/Loaders/Loader";

const calcAndCache: {
	add_buildCacheResponse: (bcr: RiskModelCacheTools_BuildCacheResponse) => void;
	set_iterationStart: React.Dispatch<React.SetStateAction<number>>;
	call: (riskModel: string, jobId: string, iteration: number) => Promise<AdminCalculateAndCachePatientRiskModelScoresResult | "INTERUPT">;
	_interupt: boolean;
} = {
	call: async (riskModel: string, jobId: string, iteration: number): Promise<AdminCalculateAndCachePatientRiskModelScoresResult | "INTERUPT"> => {
		if (calcAndCache._interupt) {
			return "INTERUPT";
		}
		let x = await calculateAndCachePatientRiskModelScores({ riskModel, jobId, iteration });
		await setTimeout(() => {});
		return x;
	},
	_interupt: false,
	add_buildCacheResponse: (bcr: RiskModelCacheTools_BuildCacheResponse) => {},
	set_iterationStart: () => {},
};

type RiskModelCacheTools_BuildCacheResponse = { iter: number; response: { alreadyCached?: number; newlyCached?: number; processed?: number } };

const RiskModelCacheTools = () => {
	const pFilters = useContext(PrimaryFiltersContext);
	const [activeJobId, set_activeJobId] = useState<string | null>(null);

	const activeRiskModel = useMemo(() => {
		return pFilters.values.RiskModel;
	}, [pFilters.values.RiskModel]);

	const [requestInProgress, set_requestInProgress] = useState<string | null>(null);

	const [cacheInfo, set_cacheInfo] = useState<CIM>(null);
	const [triggerUpdateCacheInfo, set_triggerUpdateCacheInfo] = useState<number>(0);

	const [iterationStart, set_iterationStart] = useState<number>(0);

	const [interupt, set_interupt] = useState<boolean>(false);

	useEffect(() => {
		calcAndCache._interupt = interupt;
	}, [interupt]);

	useEffect(() => {
		(async () => {
			if (!activeJobId || !activeRiskModel) {
				return null;
			}
			set_cacheInfo("INPROGRESS");
			let x = await getRiskModelScoresCacheInfo({ riskModel: activeRiskModel, jobId: activeJobId ?? undefined });
			set_cacheInfo(x);
		})();
	}, [activeJobId, triggerUpdateCacheInfo, activeRiskModel]);

	/*
██████  ███████ ██      ███████ ████████ ███████      ██████  █████   ██████ ██   ██ ███████ 
██   ██ ██      ██      ██         ██    ██          ██      ██   ██ ██      ██   ██ ██      
██   ██ █████   ██      █████      ██    █████       ██      ███████ ██      ███████ █████   
██   ██ ██      ██      ██         ██    ██          ██      ██   ██ ██      ██   ██ ██      
██████  ███████ ███████ ███████    ██    ███████      ██████ ██   ██  ██████ ██   ██ ███████                                                                         
	*/

	const deleteCacheForJobId = useMemo(() => {
		return async (_activeJobId: string | null) => {
			if (!requestInProgress) {
				if (_activeJobId === activeJobId) {
					set_requestInProgress(activeJobId);
					alert("delete " + activeJobId);
					let r = await deletePatientRiskModelScores({ riskModel: activeRiskModel, jobId: activeJobId ?? undefined });
					console.debug(r);
					set_requestInProgress(null);
				} else {
					alert("jobId mismatch");
				}
			} else {
				alert("There is a request already in progress");
			}
			//		deleteCachePatientData({ memberType: activeJobId ?? undefined });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeJobId, requestInProgress, set_requestInProgress]);

	const [buildCacheResponses, set_buildCacheResponses] = useState<RiskModelCacheTools_BuildCacheResponse[]>([]);

	useEffect(() => {
		calcAndCache.add_buildCacheResponse = (bcr: RiskModelCacheTools_BuildCacheResponse) => {
			return set_buildCacheResponses([...buildCacheResponses, bcr]);
		};
	}, [buildCacheResponses, set_buildCacheResponses]);

	useEffect(() => {
		calcAndCache.set_iterationStart = set_iterationStart;
	}, [set_iterationStart]);

	const run_calculateAndCachePatientRiskModelScores = useMemo(() => {
		return async () => {
			set_requestInProgress(activeJobId);
			set_interupt(false);
			if (!activeJobId) {
				alert("Must select JobId");
				return;
			}
			set_buildCacheResponses([]);
			let green = true;
			let i: number = iterationStart;
			alert(i);
			let x = 0;
			while (green) {
				let r = await calcAndCache.call(activeRiskModel, activeJobId, i);
				console.log(i, r);

				if (r === "INTERUPT") {
					green = false;
					break;
				}
				if (r.result?.metrics) {
					let pp = r.result?.metrics?.patientsProcessed;
					let ac = r.result?.metrics.alreadyCached;
					let nc = r.result?.metrics.newlyCached;
					calcAndCache.add_buildCacheResponse({ iter: i, response: { processed: pp, alreadyCached: ac, newlyCached: nc } });
					if (pp === 0) {
						green = false;
					}
				} else {
					calcAndCache.add_buildCacheResponse({ iter: i, response: { processed: -404, alreadyCached: -404, newlyCached: -404 } });
				}
				i++;
				x++;
				calcAndCache.set_iterationStart(i);
				if (x > 100) {
					green = false;
				}
			}
			set_requestInProgress(null);
		};
	}, [activeRiskModel, activeJobId, iterationStart]);

	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
                                                  
*/
	if (!pFilters?.values || !pFilters?.values?.Members) {
		return <Loader />;
	}
	return (
		<div>
			<h1>Risk Model Cache Tools</h1>
			<p>
				Risk Model: <b>{activeRiskModel}</b>
			</p>
			<div>
				{pFilters?.values?.Members.map((x) => {
					return (
						<button key={x} onClick={set_activeJobId.bind(null, x)}>
							{x}
						</button>
					);
				})}
			</div>
			<p>
				<b>ActiveJobId</b>: {activeJobId}
			</p>

			<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<button
						disabled={!activeJobId || !!requestInProgress}
						onClick={() => {
							run_calculateAndCachePatientRiskModelScores();
						}}
					>
						Build RiskModelCache
					</button>
					<input
						type="number"
						value={iterationStart}
						onChange={(c) => {
							let v = parseInt(c.target.value);
							if (isNaN(v)) {
								v = 0;
							}
							set_iterationStart(v);
						}}
					/>
				</div>
				<button
					style={{ marginLeft: "24px" }}
					disabled={!activeJobId || !!requestInProgress}
					onClick={() => {
						deleteCacheForJobId(activeJobId);
					}}
				>
					Delete Cache
				</button>
				<button
					style={{ marginLeft: "24px" }}
					disabled={!activeJobId || !!requestInProgress}
					onClick={() => {
						set_triggerUpdateCacheInfo(triggerUpdateCacheInfo + 1);
					}}
				>
					Refresh Status Info
				</button>
				<button
					style={{ marginLeft: "24px" }}
					onClick={() => {
						if (activeJobId && activeRiskModel) {
							createRiskModelScoresOverview({ jobId: activeJobId, riskModel: activeRiskModel });
						}
					}}
				>
					Create Overview Cache
				</button>
			</div>

			<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", maxWidth: "90vw", flexWrap: "wrap", padding: "20px" }}>
				{requestInProgress ? (
					<div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
						<Loader size="miniscule" />
						<button onClick={set_interupt.bind(null, true)}>Interupt ({interupt ? "T" : "F"})</button>
						&nbsp;&nbsp;&nbsp;&nbsp;
						{requestInProgress}
					</div>
				) : null}
			</div>
			{cacheInfo === "INPROGRESS" ? <Loader /> : <pre>{JSON.stringify(cacheInfo, null, 4)}</pre>}
			{buildCacheResponses.map((bcr) => {
				return (
					<div key={bcr.iter} style={{ border: "solid 2px blue", borderRadius: "4px" }}>
						<pre>{JSON.stringify(bcr)}</pre>
					</div>
				);
			})}
		</div>
	);
};

export { RiskModelCacheTools };
