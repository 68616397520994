import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import ValueTile from "../../../shared/components/ValueTile";
import displayConverter from "../../../shared/utilities/displayConverter";
import { CreateDataGridToolbar } from "shared/components/DataGridToolbar";

import { Swiper, SwiperSlide } from "swiper/react";
import { ModelsLookUpDictionaries, ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponse } from "api";
import { useMemo, useState } from "react";
import { palette } from "theme";
import { getBucketAggTotals, getBucketAndLookupItem } from "./DoctorDetailsOverview";
import { DataGridColumns } from "shared/utilities/DataGridColumns/DataGridColumns";

const lectern: { lookupDictionaries?: ModelsLookUpDictionaries } = { lookupDictionaries: undefined };

function DoctorDetailsServices({ npi, detailsData }: { npi: string; detailsData: ProvidersCacheMonthlyRepositoryRetrieveCacheMonthlyDetailsResponse }) {
	if (detailsData?.lookupDictionaries && lectern.lookupDictionaries !== detailsData?.lookupDictionaries) {
		lectern.lookupDictionaries = detailsData.lookupDictionaries;
	}

	const [bucketAgg, _doctor] = useMemo(() => {
		return getBucketAndLookupItem(npi, detailsData);
	}, [detailsData, npi]);

	if (_doctor) {
		// dont throw error for unused doctor
		if (2 + 2 === 5) {
			console.log("leaving doctor to use later here is fine");
		}
	}

	const bucketAggTotals = useMemo(() => {
		return getBucketAggTotals(bucketAgg);
	}, [bucketAgg]);

	const [activeCategory, set_activeCategory] = useState<"DIAGNOSIS" | "MEDICATION" | "PRODUCTSERVICE">("DIAGNOSIS");

	const tableData = useMemo(() => {
		if (!bucketAgg) {
			return null;
		}
		let countsProperty: "diagnosis" | "medication" | "productservice";
		switch (activeCategory) {
			case "DIAGNOSIS":
				countsProperty = "diagnosis";
				break;
			case "MEDICATION":
				countsProperty = "medication";
				break;
			case "PRODUCTSERVICE":
				countsProperty = "productservice";
				break;
		}
		let rowAccumulator = new Map<string, { code: string; count: number; cost: number; avgcost?: number }>();
		bucketAgg.forEach((b) => {
			b[countsProperty]?.codeCounts?.forEach((cc) => {
				if (cc.code) {
					if (rowAccumulator.has(cc.code)) {
						let x = rowAccumulator.get(cc.code);
						if (x) {
							x.count += cc.count ?? 0;
							x.cost += cc.cost ?? 0;
						}
					} else {
						rowAccumulator.set(cc.code, { code: cc.code, count: cc.count ?? 0, cost: cc.cost ?? 0 });
					}
				}
			});
		});

		let td = Array.from(rowAccumulator.values());
		td.forEach((x) => {
			x.avgcost = x.cost / x.count;
		});

		return td;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bucketAgg, activeCategory, detailsData.lookupDictionaries]);

	const dataTable = useMemo(() => {
		if (tableData) {
			return (
				<DataGridPro
					columns={DataGridColumns.CacheNestedItems(activeCategory, lectern.lookupDictionaries)}
					getRowId={(r) => r.code}
					autoHeight
					rows={tableData}
					rowsPerPageOptions={[10]}
					components={{ Toolbar: CreateDataGridToolbar("Services List") }}
					sx={{ pt: 2 }}
				/>
			);
		}
		return null;
	}, [activeCategory, tableData]);
	return (
		<Box>
			<Box
				sx={{
					"& .swiper-container": {
						pb: { xs: 4, xl: 0 },
					},
					"& .swiper-pagination-bullets": {
						bottom: "0",
					},
				}}
			>
				<Swiper
					spaceBetween={16}
					slidesPerView={1}
					pagination={{
						clickable: true,
					}}
					breakpoints={{
						"700": {
							slidesPerView: 3,
						},
						"1200": {
							slidesPerView: 3,
						},
					}}
				>
					<SwiperSlide>
						<ValueTile
							value={displayConverter.insertThousandsCommas(bucketAggTotals.diagnosisCount)}
							title="Diagnosis"
							minHeight="7rem"
							onClick={() => {
								set_activeCategory("DIAGNOSIS");
							}}
							active={activeCategory === "DIAGNOSIS"}
							primaryColor={palette.lightBlue.main}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<ValueTile
							value={displayConverter.insertThousandsCommas(bucketAggTotals.medicationCount)}
							title="Prescriptions"
							minHeight="7rem"
							onClick={() => {
								set_activeCategory("MEDICATION");
							}}
							active={activeCategory === "MEDICATION"}
							primaryColor={palette.green.main}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<ValueTile
							value={displayConverter.insertThousandsCommas(bucketAggTotals.productserviceCount)}
							title="Procedures"
							minHeight="7rem"
							onClick={() => {
								set_activeCategory("PRODUCTSERVICE");
							}}
							active={activeCategory === "PRODUCTSERVICE"}
							primaryColor={palette.orange.main}
						/>
					</SwiperSlide>
				</Swiper>
			</Box>
			<Box sx={{ display: "flex", height: "100%" }}>
				<Box
					sx={{
						flexGrow: 1,
					}}
				>
					{dataTable}
				</Box>
			</Box>
		</Box>
	);
}

export { DoctorDetailsServices };
/*





















































*/
// const columns: GridColumns = [
// 	{
// 		field: "patientId",
// 		headerName: "Member ID",
// 		flex: 1,
// 		headerAlign: "left",
// 		align: "left",
// 	},

// 	{
// 		field: "age",
// 		headerName: "Age",
// 		headerAlign: "left",
// 		align: "left",
// 	},

// 	{
// 		field: "gender",
// 		headerName: "Gender",
// 		flex: 1,
// 		headerAlign: "left",
// 		align: "left",
// 	},

// 	{
// 		field: "serviceType",
// 		headerName: "Service",
// 		flex: 1,
// 		headerAlign: "left",
// 		align: "left",
// 	},

// 	{
// 		field: "formattedDate",
// 		headerName: "Date",
// 		flex: 1,
// 		headerAlign: "left",
// 		align: "left",
// 	},
// 	{
// 		field: "paymentAmount",
// 		headerName: "Total Billing",
// 		flex: 1,
// 		headerAlign: "right",
// 		align: "right",
// 		valueGetter: (params) => `$${params.row.paymentAmount}`,
// 	},
// ];
