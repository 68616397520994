import { Card, CardHeader, CardContent, Box } from "@mui/material";
import { palette } from "../../../../theme";
import { ModelsDiagnosisRangeCacheMonthlyAggregate } from "api";

// Import Swiper React components
import SwiperCore, { Pagination } from "swiper";
import { useX_getDiagnosisImpact } from "api_extension/useQueryX/cacheDiagnosisData/useX_getDiagnosisDetailsImpact";
import { DiagnosisTypeApiRequest } from "../DiagnosisDetailsMain";
import displayConverter from "shared/utilities/displayConverter";
import { StandardLineChart } from "charts/line/StandardLineChart";
SwiperCore.use([Pagination]);

type DiagnosisDetailsImpactProps = {
	readonly apiRequest: DiagnosisTypeApiRequest;
	readonly rangeAggregateItem?: ModelsDiagnosisRangeCacheMonthlyAggregate;
};

function DiagnosisDetailsImpact(props: DiagnosisDetailsImpactProps) {
	let apiResult = useX_getDiagnosisImpact(props.apiRequest);
	const data = apiResult.data?.operationStatus?.data;
	return (
		<Box>
			<Box pt={2} pb={2}>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Monthly Spend vs Average Spend"></CardHeader>
					<CardContent sx={{ "& .recharts-responsive-container": { minHeight: "20rem" } }}>
						{data?.billingGraph?.graph ? (
							<StandardLineChart
								data={data.billingGraph.graph?.map((x) => {
									return {
										value: x.value as string | number,
										value2: x.value2 as string | number,
										name: x.name as string,
									};
								})}
								yKeys={[
									{
										dataKey: "value",
										color: palette.lightBlue.main,
										name: props.rangeAggregateItem?.icd10Range?.title ?? props.rangeAggregateItem?.range ?? "Diagnosis Name",
									},
									{
										dataKey: "value2",
										color: palette.primary.dark,
										name: "Radius Care Avg",
									},
								]}
								legend
								legendVerticalAlign="top"
								displayYAxis
								margin={{ top: 10, right: 50, left: 45, bottom: 10 }}
								legendStyle={{ paddingBottom: "1rem" }}
								tooltipFormat={(value: string) => displayConverter.toUSD(Number(value))}
								yAxisTickFormat={(value: string) => displayConverter.toUSD(Number(value))}
							/>
						) : null}
					</CardContent>
				</Card>
				<Card elevation={0} sx={{ backgroundColor: "gray.main", mb: 1 }}>
					<CardHeader title="Monthly Diagnoses vs Average Diagnoses"></CardHeader>
					<CardContent sx={{ "& .recharts-responsive-container": { minHeight: "20rem" } }}>
						{data?.fillsGraph?.graph ? (
							<StandardLineChart
								data={data?.fillsGraph.graph?.map((x) => {
									return {
										value: x.value as string | number,
										value2: x.value2 as string | number,
										name: x.name as string,
									};
								})}
								yKeys={[
									{
										dataKey: "value",
										color: palette.lightBlue.main,
										name: props.rangeAggregateItem?.icd10Range?.title ?? props.rangeAggregateItem?.range ?? "Diagnosis Name",
									},
									{
										dataKey: "value2",
										color: palette.primary.dark,
										name: "Radius Care Avg",
									},
								]}
								legend
								legendVerticalAlign="top"
								displayYAxis
								margin={{ top: 10, right: 50, left: 45, bottom: 10 }}
								legendStyle={{ paddingBottom: "1rem" }}
								tooltipFormat={(value: string) => displayConverter.insertThousandsCommas(value) + (value === "1" ? " diagnosis" : " diagnoses")}
								yAxisTickFormat={(value: string) => displayConverter.insertThousandsCommas(value) + " diagnoses"}
							/>
						) : null}
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
}

export { DiagnosisDetailsImpact };
