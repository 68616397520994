import { Box, Rating, Tooltip, Typography } from "@mui/material";
import { UserFollowedItemButton } from "../../UserFollowedItemButton";

interface SimpleTooltipColumnProps {
	code?: string;
	followItemType?: UserFollowedItemTypeNames;
	name?: string;
	tooltipTitle?: React.ReactNode;
	rating?: number;
}

function SimpleTooltipColumn(props: Readonly<SimpleTooltipColumnProps>) {
	return (
		<Box key={props.code}>
			<Tooltip arrow placement="right" title={props.tooltipTitle}>
				<Box>
					<Typography component="div" display="block" variant={"body2"}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								width: "100%",
							}}
						>
							<Box>
								{props.code && props.followItemType ? <UserFollowedItemButton itemType={props.followItemType} code={props.code} /> : null}
							</Box>
							<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
								<Typography display="block" style={{ overflowWrap: "break-word", wordWrap: "break-word", whiteSpace: "normal" }}>
									{props.name}
								</Typography>
								{props.rating && (
									<Box sx={{ display: "flex", flexDirection: "row" }}>
										<Rating size="small" name="read-only" value={props.rating} readOnly />
									</Box>
								)}
							</Box>
						</Box>
					</Typography>
				</Box>
			</Tooltip>
		</Box>
	);
}

export default SimpleTooltipColumn;
