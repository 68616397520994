import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box, ButtonBase } from "@mui/material";
import { icons } from "theme";
import * as CSS from "csstype";
import Icon from "shared/Icon";

export type HelpPopoverProps = {
  text: string | undefined;
  color: CSS.Property.Color | undefined;
  verticalAnchor: number | "bottom" | "top" | "center";
  horizontalAnchor: number | "left" | "right" | "center";
  maxWidth?: string;
};

export default function HelpPopover(props: HelpPopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "help-popover" : undefined;

  return (
    <Box>
      <ButtonBase
        onClick={handleClick}
        sx={{
          backgroundColor: props.color,
          width: "1.5rem",
          height: "1.5rem",
          borderRadius: "50%",
          color: "white",
          fontSize: ".75rem",
        }}
      >
        <Icon fixedWidth icon={icons.info} />
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: props.verticalAnchor,
          horizontal: props.horizontalAnchor,
        }}
        PaperProps={{ style: { maxWidth: props.maxWidth ?? "100%" } }}
      >
        <Typography component={"div"} sx={{ p: 2, whiteSpace: "pre-wrap" }}>
          {props.text ?? "Help popover placeholder text"}
        </Typography>
      </Popover>
    </Box>
  );
}
