import React, { useContext, useMemo } from "react";
import { Box, Grid, Card, CardContent, CardHeader, Tabs, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { TabsUtilities } from "shared/components/Tabs/TabsUtilities";
import { PrimaryFiltersContext } from "DataProvider/contexts/Context_PrimaryFilters_Provider";
import DiagnosisNotificationList from "notifications/DiagnosisNotificationList";
import { MedicationDetailsConditions } from "./panels/MedicationDetailsConditions";
import { MedicationDetailsProcedures } from "./panels/MedicationDetailsProcedures";
import { useX_getMedicationAggregations } from "api_extension/useQueryX/cacheMedicationData/useX_getMedicationAggregations";
import { DateConverter } from "shared/utilities/DateConverter";
import { MedicationDetailsOverview } from "./panels/MedicationDetailsOverview";
import { MedicationDetailsMembers } from "./panels/MedicationDetailsMembers";
import { MedicationDetailsDoctors } from "./panels/MedicationDetailsDoctors";
import { useMemo_totalMembersInFilter } from "shared/filters/useMemo_TotalMembersInFilter";
import { MedicationDetailsImpact } from "./panels/MedicationDetailsImpact";
import { UserFollowedItemTitle } from "shared/components/UserFollowedItemTitle";
import { MedicationDetailsFacilities } from "./panels/MedicationDetailsFacilities";

interface MedicationTypeApiRequest extends DateRangeStrings {
	searchQuery?: string;
	codes: Array<string>;
	jobIds: Array<string>;
}

const { TabPanel, TabControl } = TabsUtilities.create_TabRenderFunctions("prescription-details");

function MedicationDetailsMain(props: { code?: string | null }) {
	const tabs = ["Overview", "Members", "Doctors", "Facilities", "Procedures", "Conditions", "Radius Impact"];
	const { values: primaryFilterValues } = useContext(PrimaryFiltersContext);

	useContext(PrimaryFiltersContext);
	const totalMembers = useMemo_totalMembersInFilter();
	let params = useParams() as { prescriptionId: string };
	const prescriptionID: string = props.code ? props.code : params.hasOwnProperty("prescriptionId") ? params["prescriptionId"] : "NA";
	const prescriptionTypeRequest: MedicationTypeApiRequest = useMemo(() => {
		return {
			startDate: DateConverter.stringDateForAPI_Nullable(primaryFilterValues.DateRangeCustomDates.start),
			endDate: DateConverter.stringDateForAPI_Nullable(primaryFilterValues.DateRangeCustomDates.end),
			jobIds: primaryFilterValues.Members,
			codes: [prescriptionID],
		};
	}, [primaryFilterValues.DateRangeCustomDates.end, primaryFilterValues.DateRangeCustomDates.start, prescriptionID, primaryFilterValues.Members]);
	const rangeAggregationResponse = useX_getMedicationAggregations([prescriptionID], prescriptionTypeRequest);

	const rangeAggregateItem = rangeAggregationResponse?.data?.response?.aggregateItems?.[0];

	const [activeTab, set_activeTab] = React.useState<number>(0);

	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardContent>
							{rangeAggregateItem && (
								<UserFollowedItemTitle
									tooltip={<Typography>{rangeAggregateItem.code}</Typography>}
									code={rangeAggregateItem.code}
									name={rangeAggregateItem.name}
									followedItemType={"MEDICATION"}
								/>
							)}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} lg={9}>
					<Card elevation={4}>
						<CardHeader
							sx={{
								"& .MuiCardHeader-action": {
									width: "100%",
								},
							}}
							action={
								<Tabs
									value={activeTab}
									onChange={(event: React.SyntheticEvent, newValue: number) => {
										set_activeTab(newValue);
									}}
									aria-label="Medication Details Tabs"
									variant="scrollable"
									scrollButtons={false}
									sx={{
										width: "100%",
										"& .MuiTab-root": {
											flex: { xs: "none", md: "1" },
											whiteSpace: "nowrap",
										},
									}}
								>
									{tabs.map((label, value) => {
										return <TabControl key={label} label={label} value={value} />;
									})}
								</Tabs>
							}
						></CardHeader>
						<CardContent
							sx={{
								pt: 0,
							}}
						>
							<TabPanel value={activeTab} index={0}>
								<MedicationDetailsOverview
									rangeAggregateItem={rangeAggregateItem}
									apiRequest={prescriptionTypeRequest}
									totalMembers={totalMembers}
								/>
							</TabPanel>
							<TabPanel value={activeTab} index={1}>
								<MedicationDetailsMembers
									apiRequest={prescriptionTypeRequest}
									rangeAggregateItem={rangeAggregateItem}
									totalMembers={totalMembers}
								/>
							</TabPanel>
							<TabPanel value={activeTab} index={2}>
								<MedicationDetailsDoctors apiRequest={prescriptionTypeRequest} rangeAggregateItem={rangeAggregateItem} />
							</TabPanel>
							<TabPanel value={activeTab} index={3}>
								<MedicationDetailsFacilities apiRequest={prescriptionTypeRequest} rangeAggregateItem={rangeAggregateItem} />
							</TabPanel>
							<TabPanel value={activeTab} index={4}>
								<MedicationDetailsProcedures apiRequest={prescriptionTypeRequest} rangeAggregateItem={rangeAggregateItem} />
							</TabPanel>
							<TabPanel value={activeTab} index={5}>
								<MedicationDetailsConditions apiRequest={prescriptionTypeRequest} rangeAggregateItem={rangeAggregateItem} />
							</TabPanel>
							<TabPanel value={activeTab} index={6}>
								<MedicationDetailsImpact apiRequest={prescriptionTypeRequest} rangeAggregateItem={rangeAggregateItem} />
							</TabPanel>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} lg={3}>
					<Card elevation={4}>
						<CardHeader title="Member Notification"></CardHeader>
						<CardContent>
							<DiagnosisNotificationList diagnosisID={prescriptionID} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}
export { MedicationDetailsMain };
export type { MedicationTypeApiRequest };
