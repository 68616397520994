import { Box, Card, CardHeader, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import {
	ModelsLookUpDictionaries,
	ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts,
	RequestModelsCacheMonthlyQueryRequest,
	RequestModelsCacheMonthlyQueryRequestNestedProperties,
} from "api";

import { useFilterQuery } from "../../../DataProvider/hooks/useRunFilterQuery";
import displayConverter from "shared/utilities/displayConverter";
import { useEffect, useMemo, useState } from "react";
import { MonthRangeSelection } from "shared/components/DateRangeFilters/MonthRangeSelection";

import { addFilterQueryToRequest, useX_getCacheMonthlyDetail } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyDetail";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { palette } from "theme";
import { DateConverter } from "shared/utilities/DateConverter";
import { QueryStatusDisplay } from "shared/components/Loaders/QueryStatusDisplay";
import { NestedCodeFilterSelection } from "shared/components/NestedCodeFilterSection/NestedCodeFilterSelection";
import { ErrorDisplay } from "shared/components/DisplayElements/ErrorDisplay";
import { StandardLineChartX } from "charts/line/StandardLineChart";
import { Loader } from "shared/components/Loaders/Loader";
import { DataGridColumns, DataGridStyles } from "shared/utilities/DataGridColumns/DataGridColumns";
import { getBucketAndLookupItem } from "../doctor/DoctorDetailsOverview";

var lectern: { lookupDictionaries?: ModelsLookUpDictionaries } = { lookupDictionaries: undefined };

function CacheItemDetails(props: { code: string; itemType: CacheMonthlyTypes; showDateSelect: boolean }) {
	let { code, itemType } = props;
	if (itemType === "PATIENT") {
		itemType = "PATIENT_EOB"; // We want the 'cache_patient_eob' table so set this to "PATIENT_EOB" for PatientRecords
	}

	const takeSize = 100;
	const [startDate, set_startDate] = useState<Date | null>(null);
	const [endDate, set_endDate] = useState<Date | null>(null);

	const [nestedCodeFilter, set_nestedCodeFilter] = useState<RequestModelsCacheMonthlyQueryRequestNestedProperties>({});
	const MonthRangeSelectInstance = useMemo(() => {
		return <MonthRangeSelection startDate={startDate} set_startDate={set_startDate} endDate={endDate} set_endDate={set_endDate} inheritGlobalFilter />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [page, set_page] = useState<number>(0);
	const [skipTake, set_skipTake] = useState<SimplePaginationRequest>({ skip: 0, take: takeSize });
	const [records, set_records] = useState<Array<ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts> | null>(null);

	useEffect(() => {
		set_page(0);
		set_skipTake({ skip: 0, take: takeSize });
		set_records(null);
	}, [startDate, endDate]);

	const { values: pfValues } = usePrimaryFilters();

	const queryRequest: RequestModelsCacheMonthlyQueryRequest | null = useMemo(() => {
		if (code && itemType) {
			return {
				// jobIds: [...pfValues.Members],
				codes: [code],
				nestedCodes: nestedCodeFilter,
				itemType: itemType,
				includeLookup: true,
				paginationRequest: { skip: skipTake.skip, take: skipTake.take },
			};
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pfValues.Members, itemType, code, skipTake.skip, skipTake.take, nestedCodeFilter]);

	const [trigger, set_trigger] = useState(0);

	const filterQuery = useFilterQuery();
	const detailsResult = useX_getCacheMonthlyDetail(addFilterQueryToRequest(queryRequest, filterQuery));

	if (detailsResult.data?.response?.lookupDictionaries && lectern.lookupDictionaries !== detailsResult.data?.response?.lookupDictionaries) {
		lectern.lookupDictionaries = detailsResult.data.response.lookupDictionaries;
	}

	useEffect(() => {
		if (detailsResult.data?.response) {
			let x = detailsResult.data?.response.bucketGroups?.[code];
			set_records(x ? [...x]?.reverse() : null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailsResult.data?.response]);

	const pageSize = 100;
	const columns = useMemo(() => {
		return DataGridColumns.CacheMonthlyDetails(itemType, lectern.lookupDictionaries);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemType, lectern.lookupDictionaries]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [bucketAgg, lookUpItem] = useMemo(() => {
		if (detailsResult?.data?.response) {
			return getBucketAndLookupItem(code, detailsResult.data.response);
		}
		return [null, null];
	}, [detailsResult.data?.response, code]);

	const multiCountChartData = useMemo(() => {
		if (bucketAgg) {
			return bucketAgg.map((x) => {
				return {
					name: x.periodDate ? x.periodDate.split("T")[0] : "--",
					end: x.periodEndDate ? x.periodEndDate.split("T")[0] : "--",
					eob: x.eobCount ?? 0,
					diagnosis: x.diagnosis?.totalCount,
					productservice: x.productservice?.totalCount,
					medication: x.medication?.totalCount,
				};
			});
		}
		return null;
	}, [bucketAgg]);

	const BillingGraphTooltip = (props: any) => {
		let tooltipContent = null;

		if (props.active && props.payload?.length) {
			const record = records?.find((x) => DateConverter.stringDateForAPI(x.periodDate ?? "") === props.payload[0]?.payload.name);
			const startDate = DateConverter.stringDateForAPI(record?.periodDate ?? "");
			const endDate = record?.periodEndDate ? DateConverter.stringDateForAPI(record?.periodEndDate) : null;
			tooltipContent = (
				<Box sx={{ backgroundColor: "rgba(255, 255, 255, 1)", border: "1px solid lightgray", padding: 1 }}>
					<Typography>Start Date: {startDate}</Typography>
					{endDate && <Typography>End Date: {endDate}</Typography>}
					<Typography style={{ color: palette.green.main }}>Cost: {displayConverter.toUSD(record?.totalAmount)}</Typography>
				</Box>
			);
		}

		return tooltipContent;
	};

	const ServicesGraphTooltip = (props: any) => {
		let tooltipContent = null;

		if (props.active && props.payload?.length) {
			const record = records?.find((x) => DateConverter.stringDateForAPI(x.periodDate ?? "") === props.payload[0]?.payload.name);
			if (!record) {
				console.log("payload not found", props.payload);
			}
			const startDate = DateConverter.stringDateForAPI(record?.periodDate ?? "");
			const endDate = record?.periodEndDate ? DateConverter.stringDateForAPI(record?.periodEndDate) : null;
			tooltipContent = (
				<Box sx={{ backgroundColor: "rgba(255, 255, 255, 1)", border: "1px solid lightgray", padding: 1, marginTop: "-4rem" }}>
					<Typography>Start Date: {startDate}</Typography>
					{endDate && <Typography>End Date: {endDate}</Typography>}
					<Typography sx={{ color: palette.purple.main }}>Visits: {displayConverter.insertThousandsCommas(record?.eobCount ?? 0)}</Typography>
					<Typography sx={{ color: palette.lightBlue.main }}>
						Diagnosis: {displayConverter.insertThousandsCommas(record?.diagnosis?.totalCount ?? 0)}
					</Typography>
					<Typography sx={{ color: palette.green.main }}>
						Procedures: {displayConverter.insertThousandsCommas(record?.productservice?.totalCount ?? 0)}
					</Typography>
					<Typography sx={{ color: palette.orange.main }}>
						Prescriptions: {displayConverter.insertThousandsCommas(record?.medication?.totalCount ?? 0)}
					</Typography>
				</Box>
			);
		}

		return tooltipContent;
	};

	return (
		<Box sx={{ width: "100%" }}>
			<NestedCodeFilterSelection
				set_nestedCodes={set_nestedCodeFilter}
				lookupDictionaries={lectern.lookupDictionaries}
				hideItems={itemType === "DIAGNOSIS_RANGE" ? { provider: true } : undefined}
			/>

			<button
				style={{ display: "none" }}
				onClick={() => {
					set_trigger(trigger + 1);
				}}
			>
				Call CacheDetails
			</button>
			<Box sx={{ pb: 2, pt: 1 }}>
				{props.showDateSelect !== false ? MonthRangeSelectInstance : null}

				<QueryStatusDisplay queryResult={detailsResult}>
					{records && !detailsResult.isLoading && !detailsResult.isFetching ? (
						<>
							<Card elevation={0} sx={{ backgroundColor: "gray.main", padding: "1rem", my: "1rem" }}>
								<CardHeader title="Cost"></CardHeader>
								<Box style={{ padding: "1rem 0rem" }}>
									<StandardLineChartX
										data={[...records].reverse()}
										dataNameKey="periodDate"
										dataNameKeyTransform={DateConverter.stringDateForAPI}
										yKeys={[{ dataKey: "totalAmount", color: palette.green.main, name: "Cost" }]}
										responsiveHeight={140}
										legend
										displayYAxis
										yAxisTickFormat={(v, i) => {
											return displayConverter.insertThousandsCommas(v);
										}}
										angleXLabels
										XAxisAnchor="end"
										customTooltip={<BillingGraphTooltip />}
									/>
								</Box>
							</Card>
							{multiCountChartData ? (
								<Card elevation={0} sx={{ backgroundColor: "gray.main" }}>
									<CardHeader title="Monthly Detail"></CardHeader>
									<Box style={{ padding: "1rem 0rem" }}>
										<StandardLineChartX
											data={multiCountChartData}
											dataNameKey="name"
											yKeys={[
												{
													dataKey: "eob",
													color: palette.purple.main,
													name: "Visits",
												},
												{
													dataKey: "diagnosis",
													color: palette.lightBlue.main,
													name: "Diagnosis",
												},
												{
													dataKey: "medication",
													color: palette.green.main,
													name: "Medication",
												},
												{
													dataKey: "productservice",
													color: palette.orange.main,
													name: "Procedure",
												},
											]}
											responsiveHeight={160}
											legend
											legendVerticalAlign="top"
											displayYAxis
											legendStyle={{ paddingBottom: "1rem" }}
											angleXLabels
											XAxisAnchor="end"
											customTooltip={<ServicesGraphTooltip />}
										/>
									</Box>
								</Card>
							) : null}
							{/* <Card elevation={0} sx={{ backgroundColor: "gray.main", padding: "1rem", my: "1rem" }}>
								<CardHeader title="Count"></CardHeader>
								<Box style={{ padding: "1rem 0rem" }}>
									<StandardLineChartX
										data={[...records].reverse()}
										dataNameKey="periodDate"
										dataNameKeyTransform={DateConverter.stringDateForAPI}
										yKeys={[{ dataKey: "eobCount", color: palette.lightBlue.main, name: "Count" }]}
										responsiveHeight={140}
										tooltipFormat={(value: number) => displayConverter.insertThousandsCommas(value)}
										legend
										displayYAxis
										yAxisTickFormat={(v, i) => {
											return displayConverter.insertThousandsCommas(v);
										}}
									/>
								</Box>
							</Card> */}
							<DataGridPro
								sx={DataGridStyles.standard_custom}
								getRowHeight={(value) => {
									let x = value.model as ProvidersCacheMonthlyRepositoryBucketNESTEDCodeCounts;
									const maxLength = Math.max(
										...[
											x.diagnosis?.codeCounts?.length ?? 1,
											x.medication?.codeCounts?.length ?? 1,
											x.productservice?.codeCounts?.length ? x.productservice.codeCounts.length * 2.5 : 1,
											x.provider?.codeCounts?.length ?? 1,
											x.organization?.codeCounts?.length ? x.organization.codeCounts.length * 1.5 : 1,
											2,
										].filter((x) => x)
									);
									let height = maxLength * 30;
									return Number.isNaN(height) ? 30 : height;
								}}
								columns={columns}
								autoHeight
								rows={records}
								rowCount={records.length}
								onPageChange={(p) => {
									if (pageSize * (p + 1) > records.length) {
										set_skipTake({ skip: records.length, take: takeSize });
									}
									set_page(p);
								}}
								getRowId={(x) => {
									return x.period ?? Date.now;
								}}
								page={page}
								pageSize={pageSize}
								rowsPerPageOptions={[100]}
								pagination
							/>
						</>
					) : detailsResult?.data?.response?.bucketGroups ? (
						<Loader />
					) : (
						<ErrorDisplay errorType={"NEUTRAL"} errorMessage={"No Data"} />
					)}
				</QueryStatusDisplay>
			</Box>
		</Box>
	);
}

export { CacheItemDetails };
