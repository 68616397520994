import React from "react";
import { Box, Grid, Card, CardHeader, CardContent } from "@mui/material";

import PanelDoctorReferrals from "./components/PanelDoctorReferrals";
import PanelDoctorCompLandscape from "./components/PanelDoctorCompLandscape";
import { DoctorOverviewList } from "./components/OverviewPage/DoctorOverviewList";
interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`revenue-services-tabpanel-${index}`}
			aria-labelledby={`revenue-services-tab-${index}`}
			sx={{ height: "100%" }}
			{...other}
		>
			{value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
		</Box>
	);
}

function DoctorsOverview() {
	const [value] = React.useState(0);
	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Card elevation={4}>
						<CardHeader
							sx={{
								"& .MuiCardHeader-action": {
									width: "100%",
								},
							}}
							// action={
							//   <Tabs
							//     value={value}
							//     onChange={handleChange}
							//     aria-label="Diagnosis Details Tabs"
							//     variant="fullWidth"
							//     sx={{
							//       width: "100%",
							//     }}
							//   >
							//     <Tab label="Overview" {...a11yProps(0)} />
							//     <Tab label="Referrals" {...a11yProps(1)} />
							//     <Tab label="Comp Landscape" {...a11yProps(2)} />
							//   </Tabs>
							// }
						></CardHeader>
						<CardContent>
							<TabPanel value={value} index={0}>
								<DoctorOverviewList />
							</TabPanel>
							<TabPanel value={value} index={1}>
								<PanelDoctorReferrals />
							</TabPanel>
							<TabPanel value={value} index={2}>
								<PanelDoctorCompLandscape />
							</TabPanel>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export default DoctorsOverview;
