import { LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer, Legend } from "recharts";
import { Box, LinearProgress } from "@mui/material";
import { palette } from "../../theme";
import * as CSS from "csstype";
import { HorizontalAlignmentType, VerticalAlignmentType } from "recharts/types/component/DefaultLegendContent";
import { CustomizedDot } from "./CustomizedDot";
import { useMemo } from "react";
import { Margin } from "recharts/types/util/types";

const ChartTooltipLabel = {
	display: "none",
};

type StandardLineChartXProps = Omit<StandardLineChartProps, "data"> & {
	dataNameKey: string;
	dataNameKeyTransform?: (x: any) => string;
	data: Array<{ [key: string]: any }>;
};

const StandardLineChartX = (props: StandardLineChartXProps) => {
	const propsX: StandardLineChartProps = useMemo(() => {
		return {
			...props,
			data: props.data.map((x) => {
				let item: CIM = { name: x[props.dataNameKey] };
				if (props.dataNameKeyTransform) {
					item.name = props.dataNameKeyTransform(item.name);
				}
				props.yKeys.forEach((yk) => {
					item[yk.dataKey] = x?.[yk.dataKey];
				});
				return item;
			}),
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, props.data, props.yKeys]);
	if (props.dataNameKey) {
		return <StandardLineChart {...propsX} />;
	}
	return null;
};

interface StandardLineChartProps {
	data: Array<{ name: string; [key: string]: any }>;
	xKey?: string;
	yKeys: Array<{ dataKey: string; color?: CSS.Property.Color; name?: string }>;
	legend?: boolean;
	legendAlign?: HorizontalAlignmentType;
	legendVerticalAlign?: VerticalAlignmentType;
	displayYAxis?: boolean;
	responsiveHeight?: number;
	legendStyle?: React.CSSProperties;
	tooltipFormat?: Function;
	customTooltip?: JSX.Element | null;
	yAxisTickFormat?: (value: any, index: number) => string;
	margin?: Margin;
	angleXLabels?: boolean;
	XAxisAnchor?: string;
}
const StandardLineChart = (props: StandardLineChartProps) => {
	return (
		<Box
			sx={{
				"& text.recharts-cartesian-axis-tick-value > tspan": {
					width: "80px !important",
					overflow: "visible",
				},
			}}
		>
			<ResponsiveContainer height={props.responsiveHeight} width="100%">
				{props.data.length > 0 ? (
					<LineChart data={props.data} margin={props.margin ?? { left: 20, right: 20, top: 5 }}>
						{props.yKeys.map((yk, x) => {
							return (
								<Line
									key={x}
									dataKey={yk.dataKey}
									stroke={yk.color ?? palette.primary.dark}
									dot={<CustomizedDot fill={yk.color ?? palette.purple.main} />}
									name={yk.name}
								/>
							);
						})}
						{props.legend ? (
							<Legend
								verticalAlign={props.legendVerticalAlign}
								align={props.legendAlign}
								wrapperStyle={props.legendStyle}
								iconType="circle"
								formatter={(value, entry, index) => <span style={{ color: palette.primary.dark }}>{value}</span>}
							/>
						) : null}

						<XAxis
							interval="preserveStartEnd"
							dataKey={"name"}
							angle={props.angleXLabels ? -15 : undefined}
							textAnchor={props.XAxisAnchor}
							height={props.angleXLabels ? 50 : undefined}
						/>
						{props.displayYAxis ? <YAxis tickFormatter={props.yAxisTickFormat} /> : null}

						{props.customTooltip ? (
							<Tooltip content={props.customTooltip} />
						) : (
							<Tooltip labelStyle={ChartTooltipLabel} formatter={props.tooltipFormat} />
						)}
					</LineChart>
				) : (
					<LinearProgress color={"inherit"} />
				)}
			</ResponsiveContainer>
		</Box>
	);
};
export { StandardLineChart, StandardLineChartX };
