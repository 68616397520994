import { Box, Rating, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import ValueTile from "../../../shared/components/ValueTile";
import displayConverter from "../../../shared/utilities/displayConverter";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { getRevenueServicesAppointments } from "api";
import { AdminGetRevenueServicesAppointmentsResult } from "api";
import { useRunFilterQuery } from "../../../DataProvider/hooks/useRunFilterQuery";
import { Loader } from "shared/components/Loaders/Loader";

SwiperCore.use([Pagination]);

const columns: GridColumns = [
	{
		field: "doctorName",
		headerName: "Doctor Name",
		flex: 1,
		minWidth: 260,
		headerAlign: "left",
		align: "left",
		renderCell: (params) => (
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Typography>{params.row.doctorName}</Typography>
				{params.row.rating && <Rating size="small" name="read-only" value={params.row.rating} readOnly />}
			</Box>
		),
	},
	{
		field: "specialty",
		headerName: "Specialty",
		flex: 1,
		minWidth: 280,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => (params.row.specialty.length > 0 && params.row.specialty[0] !== "" ? params.row.specialty : `-`),
	},
	{
		field: "totalAppointments",
		headerName: "Visits",
		flex: 0.5,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => displayConverter.insertThousandsCommas(params.row.totalAppointments),
	},
	{
		field: "totalBilling",
		headerName: "Total Billing",
		flex: 0.5,
		headerAlign: "left",
		align: "left",
		valueGetter: (params) => displayConverter.toUSD(Number(params.row.totalBilling)),
	},
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
	return <DataGridToolbar header={"Visits"} {...props} />;
}

interface Filters {
	searchQuery?: string;
}

function PanelRevenueAppointments() {
	const { grid, query, updateSearchQuery } = useDataGrid<Filters>("appointments", columns);

	function onSearchEntered(searchString: string) {
		if (query.searchQuery === searchString) {
			return null;
		}
		updateSearchQuery({ searchQuery: searchString });
	}

	const { data } = useRunFilterQuery<AdminGetRevenueServicesAppointmentsResult>(getRevenueServicesAppointments, {
		...query,
	});

	return (
		<Box>
			{data ? (
				<Box
					sx={{
						"& .swiper-container": {
							pb: { xs: 4, md: 0 },
						},
						"& .swiper-pagination-bullets": {
							bottom: "0",
						},
					}}
				>
					<Swiper
						spaceBetween={16}
						slidesPerView={1}
						onSlideChange={() => console.log("slide change")}
						onSwiper={(swiper) => console.log(swiper)}
						pagination={{
							clickable: true,
						}}
						breakpoints={{
							"700": {
								slidesPerView: 1,
							},
							"1200": {
								slidesPerView: 3,
							},
						}}
					>
						<SwiperSlide>
							<ValueTile
								value={displayConverter.insertThousandsCommas(data?.appointmentsModel?.totalAppointments)}
								title="Total Visits"
								minHeight="7rem"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.toUSD(Number(data?.appointmentsModel?.totalBilling))} title="Total Billing" minHeight="7rem" />
						</SwiperSlide>
						<SwiperSlide>
							<ValueTile value={displayConverter.toUSD(data?.appointmentsModel?.averageCost)} title="Avg Visit Cost" minHeight="7rem" />
						</SwiperSlide>{" "}
						{/* <SwiperSlide>
						<Card
						elevation={0}
						sx={{
							backgroundColor: "gray.main",
							minHeight: "7rem",
						}}
						>
						<CardContent sx={{ flex: "1" }}>
							{fakeData.data?.chart_data ? (
							<StandardLineChart
								yKeys={[{ dataKey: "tm" }]}
								data={fakeData.data.chart_data}
								responsiveHeight={80}
							/>
							) : null}
						</CardContent>
						</Card>
					</SwiperSlide> */}
					</Swiper>
				</Box>
			) : (
				<Loader />
			)}
			{data ? (
				<Box sx={{ display: "flex", height: "100%" }}>
					<Box
						sx={{
							flexGrow: 1,
						}}
					>
						{data?.appointmentsModel?.appointments ? (
							<DataGridPro
								{...grid}
								columns={columns}
								autoHeight
								getRowId={(row) => row.doctorNpi}
								rows={data?.appointmentsModel?.appointments}
								rowCount={data?.appointmentsModel.providerCount}
								components={{ Toolbar: Toolbar }}
								componentsProps={{
									toolbar: {
										onSearch: (searchString: string) => {
											onSearchEntered(searchString);
										},
										initialSearch: query.searchQuery ?? "",
									},
								}}
								sx={{ pt: 2 }}
							/>
						) : null}
					</Box>
				</Box>
			) : (
				<Loader />
			)}
		</Box>
	);
}

export default PanelRevenueAppointments;
