import { Box, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import displayConverter from "../../../../shared/utilities/displayConverter";
import ValueTile from "../../../../shared/components/ValueTile";
import { DataGridToolbar, DataGridToolbarProps } from "shared/components/DataGridToolbar";
import useDataGrid from "shared/components/grid/useDataGrid";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { Loader } from "shared/components/Loaders/Loader";
import { ProductServiceTypeApiRequest } from "../ProductServiceDetailsMain";
import { ProductServiceCacheProductServiceMonthlyAggregate, RequestModelsCacheMonthlyQueryRequest } from "api";
import { useMemo } from "react";
import { usePrimaryFilters } from "DataProvider/hooks/useContextPrimaryFilters";
import { useX_getCacheMonthlyDetailCombined } from "api_extension/useQueryX/cacheMonthly/useX_getCacheMonthlyDetailCombined";
import { ViewEntityDetailButton } from "shared/components/ViewEntityDetailButton";
import SimpleTooltipColumn from "shared/components/grid/GridColumn/SimpleTooltipColumn";

SwiperCore.use([Pagination]);

function Toolbar(props: Readonly<Pick<DataGridToolbarProps, "onAdd">>) {
	return <DataGridToolbar header={"Conditions List"} enableExport={false} {...props} />;
}

interface Filters {
	tableQuery?: string;
}

type ProductServiceDetailsConditionsProps = {
	readonly apiRequest: ProductServiceTypeApiRequest;
	readonly rangeAggregateItem?: ProductServiceCacheProductServiceMonthlyAggregate;
};
function ProductServiceDetailsConditions(props: ProductServiceDetailsConditionsProps) {
	const columns: GridColumns = [
		{
			field: "description",
			headerName: "Condition Name",
			headerAlign: "left",
			align: "left",
			flex: 2,
			renderCell: (params) => {
				let originalCode = params.row.code;
				let formattedCode = params.row.code;
				if (originalCode.length > 3) {
					formattedCode = originalCode.slice(0, 3) + "." + originalCode.slice(3);
				}
				return (
					<SimpleTooltipColumn
						followItemType="DIAGNOSIS"
						code={originalCode}
						name={params.row.name}
						tooltipTitle={<Typography>ICD: {formattedCode}</Typography>}
					/>
				);
			},
		},
		{
			field: "count",
			headerName: "Total Diagnoses",
			headerAlign: "right",
			align: "right",
			flex: 0.5,
			renderCell: (params) => displayConverter.insertThousandsCommas(params.row.count),
		},
		{
			field: "cost",
			headerName: "Total Spend",
			headerAlign: "right",
			align: "right",
			flex: 0.5,
			renderCell: (params) => displayConverter.toUSD(Number(params.row.cost)),
		},
		{
			field: "Actions",
			headerName: "",
			headerAlign: "right",
			align: "right",
			width: 128,
			sortable: false,
			disableColumnMenu: true,

			renderCell: ({ row }) => {
				return <ViewEntityDetailButton itemType="DIAGNOSIS" code={row.code} />;
			},
		},
	];

	const { grid } = useDataGrid<Filters>("services", columns);
	const { apiRequest } = props;

	const { values: pfValues } = usePrimaryFilters();
	const queryRequest: RequestModelsCacheMonthlyQueryRequest | null = useMemo(() => {
		return {
			jobIds: [...pfValues.Members],
			codes: apiRequest.codes,
			itemType: "PRODUCTSERVICE",
			includeLookup: true,
			paginationRequest: { skip: 0, take: 100, maxNestedSize: 100 },
		};
	}, [pfValues.Members, apiRequest.codes]);

	const apiResult = useX_getCacheMonthlyDetailCombined(queryRequest);
	const data = useMemo(() => {
		if (!apiResult.data?.response?.bucketGroups) return null;
		return apiResult.data?.response?.bucketGroups[apiRequest.codes[0]][0];
	}, [apiRequest.codes, apiResult.data?.response?.bucketGroups]);
	const averageCost = useMemo(() => {
		if (data?.diagnosis?.totalCost && data?.diagnosis?.totalCount) {
			return data.diagnosis.totalCost / data.diagnosis.totalCount;
		}
		return null;
	}, [data]);

	return (
		<Box>
			{data ? (
				<>
					<Box
						sx={{
							"& .swiper-container": {
								pb: { xs: 4, md: 0 },
							},
							"& .swiper-pagination-bullets": {
								bottom: "0",
							},
						}}
					>
						<Swiper
							spaceBetween={16}
							slidesPerView={1}
							pagination={{
								clickable: true,
							}}
							breakpoints={{
								"700": {
									slidesPerView: 4,
								},
								"1200": {
									slidesPerView: 4,
								},
							}}
						>
							<SwiperSlide>
								<ValueTile
									value={displayConverter.insertThousandsCommas(data.diagnosis?.uniqueCount)}
									title="Total Conditions"
									minHeight="7rem"
								/>
							</SwiperSlide>

							<SwiperSlide>
								<ValueTile
									value={displayConverter.insertThousandsCommas(data.diagnosis?.totalCount)}
									title="Total Diagnoses"
									minHeight="7rem"
								/>
							</SwiperSlide>
							<SwiperSlide>
								<ValueTile value={displayConverter.toUSD(data.diagnosis?.totalCost)} title="Total Spend" minHeight="7rem" />
							</SwiperSlide>
							<SwiperSlide>
								<ValueTile value={displayConverter.toUSD(averageCost)} title="Average Spend" minHeight="7rem" />
							</SwiperSlide>
						</Swiper>
					</Box>
					<Box sx={{ display: "flex", height: "100%" }}>
						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							{data ? (
								<DataGridPro
									{...grid}
									columns={columns}
									autoHeight
									getRowId={(row) => row.code}
									rowCount={data.diagnosis?.codeCounts?.length ?? 0}
									rows={data.diagnosis?.codeCounts ?? []}
									components={{ Toolbar: Toolbar }}
									paginationMode="client"
									initialState={{
										sorting: {
											sortModel: [{ field: "cost", sort: "desc" }],
										},
									}}
									sx={{ pt: 2 }}
								/>
							) : null}
						</Box>
					</Box>
				</>
			) : (
				<Loader />
			)}
		</Box>
	);
}

export { ProductServiceDetailsConditions };
